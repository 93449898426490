<template>
  <div class="min-vw-100 position-fixed" style="top:56px;left:0;background:rgba(0,0,0,0.7);z-index:100000; height:calc(100vh - 56px); overflow:scroll">
    <div class="w-100 position-fixed" style="top:0; left:0;z-index:-1; height:calc(100vh - 56px)" @click="$store.state.seller.detailsTrx = []"></div>
    <div class="m-3 card">
      <div class="p-3">
        <table>
          <thead>
            <tr>
              <th>CS</th>
              <th class="cursor-pointer" @click="orderBy = 'CREATED'">Tgl</th>
              <th class="text-center"><i class="icon-globe"></i></th>
              <th class="cursor-pointer" @click="orderBy = 'NAMA'">Nama</th>
              <th class="cursor-pointer" @click="orderBy = 'BANK'">Rekening</th>
              <th class="cursor-pointer" @click="orderBy = 'NOMINAL'">Pulsa</th>
              <th class="cursor-pointer" @click="orderBy = 'NOMOR'">Nomor</th>
              <th class="cursor-pointer" @click="orderBy = 'CHIP'">Chip</th>
              <th class="cursor-pointer" @click="orderBy = 'RATE'">Rate</th>
              <th class="cursor-pointer" @click="orderBy = 'FEE'">Fee</th>
              <th>Cash Out</th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="(comp, index) in $store.state.seller.detailsTrx" :key="index" @dblclick="$store.state.seller.editComplete = comp">
              <td>{{comp.admin}}</td>
              <td>
                <div class="font-weight-bold">{{fbDateToString(comp.created.seconds*1000, 'HH:mm')}}</div>
                <div class="text-small">{{fbDateToString(comp.created.seconds*1000, 'DD MMM YYYY')}}</div>
              </td>
              <td>
                <img class="img-avatar" :src="getProviderIcon(comp.provider)" width="36px">
              </td>
              <td>{{comp.bank.atasnama}}</td>
              <td class="d-flex">
                <img :src="bankIconURL(comp.bank.bank)" height="32px" :alt="comp.bank.bank" class="mr-3">
                <div>
                  <div>{{comp.bank.bank}}</div>
                  <div class="text-small">{{comp.bank.norek}}</div>
                </div>
              </td>
              <td>{{currency(comp.nominal)}}</td>
              <td>{{comp.nomor}}</td>
              <td><span class="font-weight-bold">({{comp.nomor_server.shortcut}})</span> {{comp.nomor_server.value}}</td>
              <td>{{comp.rate}}</td>
              <td><span v-if="comp.fee > 0">{{comp.fee}}</span></td>
              <td>{{currency(comp.nominal * comp.rate)}}</td>
            </tr>
            <tr>
              <td colspan="5" class="text-right">Total Pulsa Masuk</td>
              <td clas="font-weight-bold">{{currency(sumValuation($store.state.seller.detailsTrx))}}</td>
            </tr>
            <tr>
              <td colspan="5" class="text-right">Tertulis di List</td>
              <td>{{$store.state.seller.sumOnList}}</td>
            </tr>
            <tr>
              <td colspan="5"></td>
              <td><button class="btn btn-outline-primary text-small" @click="validasiSumCS($store.state.seller.detailsTrx)">Validasi</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="$store.state.seller.editComplete != null">
      <EditComplete />
      <div class="w-100 h-100 position-fixed" style="top:0; left:0;background:rgba(0,0,0,0.7)" @click="emptyData()"></div>
    </div>
  </div>
</template>

<script>
import EditComplete from '@/components/seller/EditComplete'
export default {
  name: 'detail-trx',
  components: {
    EditComplete
  },
  data(){
    return {
    }
  },
  methods:{
    emptyData(){
      if(this.$store.state.seller.savingEdit == false){
        this.$store.state.seller.editComplete = null
      }
    },
    sumValuation(items){
      var sum = 0;
      items.forEach((item) => {
        sum+=item.nominal
      })
      return sum;
    },
    even: function(arr) {
      return arr.sort((a, b) => {
        if(this.sorting == 'ASC'){
          switch (this.orderBy) {
            case 'CREATED':
              return b.created - a.created;
          
            case 'NAMA':
              return ('' + a.bank.atasnama).localeCompare(b.bank.atasnama);
          
            case 'NOMINAL':
              return b.nominal - a.nominal
          
            case 'BANK':
              return ('' + a.bank.bank).localeCompare(b.bank.bank);
          
            case 'NOMOR':
              return b.nomor - a.nomor
          
            case 'CHIP':
              return b.nomor_server.shortcut - a.nomor_server.shortcut
          
            case 'RATE':
              return b.rate - a.rate
          
            case 'FEE':
              return b.fee - a.fee
          
            default:
              return b.created - a.created;
          }
        }
      });
    },
  }
}
</script>