<template>
    <div>
        <div v-for="user in suspendedList" :key="user.id" class="d-inline-block rounded p-2 m-1 cursor-pointer align-top" style="width:calc(33.3% - .5rem); background: #f5f5f5">
            <div class="d-flex">
                <div class="mr-3"><img :src="user.photoUrl" class="rounded-circle" width="56px"></div>
                <div>
                    <div class="text-small text-secondary">{{user.email}}</div>
                    <div v-if="user.bank != null">
                        <div v-if="user.bank[0] != null">
                            <span class="d-block font-weight-bold">{{user.bank[0].atasnama}}</span>
                            <span class="d-block text-small">{{user.bank[0].norek}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button v-if="!newSuspend" class="btn btn-primary mt-4 py-2 w-100" @click="newSuspend = true">
            <font-awesome-icon icon="plus-circle" class="mr-3" />
            <span>Suspend User</span>
        </button>
        <div v-else>
            <div class="form-group">
                <label for="suspendValue">Nomor eWallet/HP/Email</label>
                <input v-model="suspendValue" id="suspendValue" class="form-control" />
            </div>

            <table class="table table-striped" v-if="suspendData.length > 0">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">uid</th>
                    <th scope="col">email</th>
                    <th>action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="usr in suspendData" :key="usr.uid">
                        <th scope="row"></th>
                        <td>{{usr.uid}}</td>
                        <td>{{usr.email}}</td>
                        <td><button @click="suspendUser(usr)" class="btn btn-outline-primary">Ban</button></td>
                    </tr>
                </tbody>
            </table>

            <div class="d-flex">
                <button class="btn btn-outline-secondary mt-4 py-2 w-100 mr-1" @click="newSuspend = false">
                    <font-awesome-icon icon="arrow-left" class="mr-3" />
                    <span>Batal</span>
                </button>
                <button class="btn btn-primary mt-4 py-2 w-100 ml-1" @click="checkSuspendData(suspendValue)">
                    <font-awesome-icon icon="plus-circle" class="mr-3" />
                    <span>Periksa Data</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {db, fv} from '@/main'

export default {
    name:'suspended-component',
    data(){
        return {
            newSuspend: false,
            suspendValue: '',
            suspendData: [],
        }
    },
    methods: {
        async checkSuspendData(value){
            this.suspendData = [];
            let type = 'phoneNumber'
            if(value.includes('@')) type = 'email'

            var snap;

            switch (type) {
                case 'email':
                    snap = await db.collection('users').where('email', '==', value).get()
                    snap.docs.forEach((snp) => {
                        this.suspendData.push(snp.data())
                    })
                    break;
                case 'phoneNumber': {
                        snap = await db.collection('converts').where('nomor', '==', value).get()
                        let uids = []
                        snap.docs.forEach((snp) => {
                            if(!uids.includes(snp.data().uid)) uids.push(snp.data().uid)
                        })

                        uids.forEach(async (uid) => {
                            var usr = await db.collection('users').doc(uid).get()
                            this.suspendData.push(usr.data())
                        })
                        
                    break;
                    }
            }
        },
        async suspendUser(user){
            console.log(user)
            db.collection('users').doc(user.uid).update({
                'ban': {
                    'status': 'suspended',
                    'updated_at': fv.serverTimestamp()
                }
            })
        },
    },
    firestore(){
        return {
            suspendedList: db.collection('users').where('ban.status', '==', 'suspended'),
        }
    }
}
</script>