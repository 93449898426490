import {db} from '@/main'
import axios from 'axios'

const sellerBloc = {
  methods: {
    showLogTrxs(logTime, numberLog, shift, sumOnList){
      this.$store.state.seller.loading = true
      this.$store.state.seller.sumOnList = sumOnList
      this.$store.state.seller.numberLog = numberLog
      var theCS
      switch (shift) {
        case 'satupagi': case 'satusiang':
          theCS = 'cs1@bypulsa.com'
          break;

      case 'duapagi': case 'duasiang':
        theCS = 'cs2@bypulsa.com'
        break;

      case 'tigapagi': case 'tigasiang':
        theCS = 'cs3@bypulsa.com'
        break;

      case 'empatpagi': case 'empatsiang':
        theCS = 'cs4@bypulsa.com'
        break;

      case 'limapagi': case 'limasiang':
        theCS = 'cs5@bypulsa.com'
        break;
      }
      this.$store.state.seller.shift = shift

      db.collection('converts').where('cs', '==', theCS).where('identifier', '==', logTime).where('nomor_server.value', '==', numberLog.value).get().then((snaps) => {
        snaps.docs.forEach((doc) => {
          this.$store.state.seller.detailsTrx.push(doc.data())
        })
      }).then(() => {
        this.$store.state.seller.loading = false
      })
    },
    async validasiSumCS(trxs){
      var sum = 0
      var identifier = this.$store.state.seller.logTime
      var shift = this.$store.state.seller.shift
      var cs = ''
      var chip = {}
    
      for (const trx of trxs) {
        if(trx.admin != '') cs = trx.admin
        chip = trx.nomor_server
        sum += trx.nominal
        console.log(trx)
      }
      

      this.$store.state.seller.loading = true
      var snap = await db.collection('cs_logs').where('identifier', '==', identifier).where('shift', '==', shift).where('cs', '==', cs).where('nomor', '==', chip.value).get()
      if(snap.docs.length > 0){
        console.log(snap.docs.length)
        snap.docs.forEach((sn) => {console.log(sn.data())})
        await db.collection('cs_logs').doc(snap.docs[0].data().id).update({
          'nominal': sum
        })
        alert('Done!, reload panel u/ refresh')
      } else {
        alert('data gak ditemuin, kontak developer')
      }
      this.$store.state.seller.loading = false
    },
    async clearDuplicateChip(provider, identifier){
      this.$store.state.seller.loading = true
      var data = {
        'provider': provider,
        'identifier': identifier
      };
      var config = {
        method: 'post',
        //url: 'https://us-central1-bypulsa-convert-pulsa.cloudfunctions.net/clearDuplicateChip',
        url: 'http://localhost:5001/bypulsa-convert-pulsa/us-central1/clearDuplicateChip',
        headers: {'Content-Type': 'application/json'},
        data: data
      };

      await axios(config).then(() => {
        this.$store.state.seller.loading = false
      })
      .catch(function (error) {
          console.log(error);
      });
    }
  }
}

export default sellerBloc