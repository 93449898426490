<template>
    <table>
    <thead>
        <tr class="text-center">
            <th><i class="icon-globe"></i></th>
            <th class="text-center">ID</th>
            <th class="text-center">Nomor</th>
            <th class="text-center">Awal</th>
            <th class="text-center">Omsetmu</th>
            <th class="text-center">Estimasi</th>
            <th class="text-center">Checker</th>
            <th style="width:160px"></th>
        </tr>
    </thead>
        <tbody>
        <tr v-for="(tsel, index) in $props.list" :key='index'>
            <td class="text-center align-middle" style="width:36px">
                <div class="avatar">
                <img class="img-avatar" :src="icon" width="36px">
                </div>
            </td>
            <td class="align-middle text-center" style="width:36px" @click="printValue(tsel.id)">
                {{tsel.shortcut}}
            </td>
            <td class="align-middle text-center" style="width:130px">
                {{tsel.value}}
            </td>
            <td class="align-middle text-center">
                {{currency(tsel.nominal)}}
            </td>
            <td class="align-middle text-center">{{getTheNomorSum(tsel)}}</td>
            <td class="align-middle text-center">{{currency(getSingleSum(tsel))}}</td>
            <td class="align-middle text-center">
                <div v-if="getChecker(tsel) != null">{{currency(getChecker(tsel))}}</div>
                <div v-else>-</div>
            </td>
            <td>
                <button v-if="!copiedData" class="btn btn-outline-primary" @click="copyNomor(tsel)"><font-awesome-icon icon="copy" :style="{marginRight: '12px'}" /> Nomor</button>
                <button v-if="copiedData && dataToCopy == tsel" class="btn btn-success"><font-awesome-icon icon="copy" :style="{marginRight: '12px'}" />Copied!</button>
            </td>
        </tr>
        </tbody>

        <!-- hidden element -->
        <input class="position-fixed" style="top:-100px" type="text" :id="'copyServerPhone_'+$props.provider" :value="stringToCopy">
    </table>
</template>

<script>
import moment from 'moment'
import {db} from '@/main'

export default {
    props: {
        list: Array,
        provider: String
    },
    data(){
        return {
            icon: null,
            stringToCopy: '',
            dataToCopy: null,
            copiedData: false,
            loading: false,
            selectedDate: new Date(),
            logYesterday: null,
            logTime: null,
            newAmount: null,
            saving: false,
            editValue: '',
            numberYesterday: [],
            numberToday: [],
            csLogs: []
        }
    },
    async mounted(){
        this.shift = await this.getShift()
        this.viewData()
        this.icon = this.getProviderIcon(this.$props.provider)
    },
    methods: {
        printValue(val){console.log(val)},
        copyNomor(nomor){
            this.dataToCopy = nomor
            this.stringToCopy = nomor.value.replace(/-/g, '')

            setTimeout(() => {
                this.copyThem()
            }, 250)
        },
        copyThem(){
            var copyText = document.getElementById("copyServerPhone_"+this.$props.provider);
            copyText.select();
            copyText.setSelectionRange(0, 99999)
            document.execCommand("copy");
            this.copiedData = true
            setTimeout(() => {
                this.copiedData = false
            }, 3000)
        },
        async viewData(){
            this.loading = true
            this.numberYesterday = []
            this.numberToday = []
            this.csLogs = []

            if(this.selectedDate != null){
                var yesterdayData = new Date(new Date(this.selectedDate).setDate(this.selectedDate.getDate() - 1))
                this.logTime = 'log_'+moment(this.selectedDate).format('DD_MMM_yyyy')
                this.logYesterday = 'log_'+moment(yesterdayData).format('DD_MMM_yyyy')

                await db.collection('numbers').where('identifier', '==', this.logYesterday).orderBy('order').get().then((docs) => {
                docs.docs.forEach(el => {
                    this.numberYesterday.push(el.data())
                });
                })

                await db.collection('numbers').where('identifier', '==', this.logTime).orderBy('order').get().then((docs) => {
                docs.docs.forEach(el => {
                    this.numberToday.push(el.data())
                });
                })

                await db.collection('cs_logs').where('identifier', '==', this.logTime).get().then((docs) => {
                docs.docs.forEach(el => {
                    this.csLogs.push(el.data())
                });
                })

                this.loading = false
            }
        },
        getSingleSum(val){
            var data = []
            var sumUp = 0
            this.csLogs.forEach(element => {
                if(element.nomor == val.value && element.identifier == this.logTime){
                data.push(element)
                }
            });

            data.forEach(element => {
                if(element.shift.toUpperCase() != 'SELL'){
                sumUp += element.nominal
                } else {
                sumUp -= element.nominal
                }
            });

            return (sumUp + val.nominal);
        },
        getChecker(val){
            var data
            this.numberToday.forEach(el => {
                if(val.value == el.value){
                data = el.nominal
                }
            })

            return data;
        },
        getCheckerTime(val){
            var data
            this.numberToday.forEach(el => {
                if(val.value == el.value){
                data = el.updated_at
                }
            })

            return data;
        },
        getTheNomorSum(val){
            var data
            this.csLogs.forEach(element => {
                if(element.nomor == val.value && element.identifier == this.logTime && element.shift.toUpperCase() == this.shift.toUpperCase()){
                data = this.currency(element.nominal)
                }
            });

            return data;
        },
    },
}
</script>