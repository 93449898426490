<template>
    <div class="container">
        <div class="row" style="margin-bottom:58px">
            <div class="col-12" style="margin-top:16px">
                <table>
                    <thead style="border: white 1px solid">
                        <tr>
                            <th rowspan="2">Created</th>
                            <th colspan="4">Items</th>
                            <th rowspan="2">Debit</th>
                            <th rowspan="2">Credit</th>
                            <th rowspan="2">CS</th>
                        </tr>
                        <tr>
                            <th>Name</th>
                            <th>Qty</th>
                            <th>Cost</th>
                            <th>Discount</th>
                        </tr>
                    </thead>
                    <tbody> 
                        <tr v-for="(fnc, i) in finances" :key="i">
                            <td>
                                <div>{{fbDateToString(fnc.created_at.seconds * 1000, 'LLL')}}</div>
                                <div class="small text-muted">{{fbDateToString(fnc.created_at.seconds * 1000, 'dddd')}}</div>
                            </td>
                            <td>
                                <div v-for="(item, idx) in fnc.items" :key="'item'+idx">
                                    {{item.name}}
                                </div>
                            </td>
                            <td>
                                <div class="text-center" v-for="(item, idx) in fnc.items" :key="'qty'+idx">
                                    {{currency(item.qty)}}
                                </div>
                            </td>
                            <td>
                                <div class="text-right" v-for="(item, idx) in fnc.items" :key="'cost'+idx">
                                    {{currency(item.cost)}}
                                </div>
                            </td>
                            <td>
                                <div v-for="(item, idx) in fnc.items" :key="'cost'+idx">
                                    <div v-if="item.discount > 0" class="text-center"><span class="badge badge-success">{{item.discount}}%</span></div>
                                    <div v-else class="text-center">-</div>
                                </div>
                            </td>
                            <td>
                                <span v-if="fnc.type == 'debit'">{{currency(sumItems(fnc.items))}}</span>
                            </td>
                            <td>
                                <span v-if="fnc.type == 'credit'">{{currency(sumItems(fnc.items))}}</span>
                            </td>
                            <td>
                                <div>{{fnc.cs}}</div>
                                <div class="xsmall text-secondary">{{fnc.id}}</div>
                            </td>
                        </tr>
                        <tr><td colspan="8" style="height:72px"></td></tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="position-fixed card" v-if="addNew" style="left:50%; top:15%; transform:translate(-50%, 0);z-index:9999">
            <div class="card-body d-flex">
                <div class="pr-5">
                    <div class="form-group">
                        <label class="small"><b class="text-success">Tanggal</b> Transaksi</label>
                        <Datepicker input-class="form-control" v-model="newData.created_at" />
                    </div>
                </div>
                <div>
                    <div class="form-group">
                        <label class="small d-block"><b class="text-success">Jenis</b> Transaksi</label>
                        <div class="switcher">
                            <button class="btn" :class="newData.type == 'debit' ? 'btn-success' : ''" @click="newData.type = 'debit'">( + ) Debit</button>
                            <button class="btn" :class="newData.type == 'credit' ? 'btn-danger' : ''" @click="newData.type = 'credit'">( - ) Credit</button>
                        </div>
                    </div>
                </div>
            </div>
            
            <div v-for="(item, index) in newData.items" :key="index" class="d-flex px-3 align-items-end">
                <div class="form-group mr-2" style="flex: 1">
                    <span class="form-control font-weight-bold input-muted">{{item.name}}</span>
                </div>
                <div class="form-group mr-2 text-center" style="width: 64px">
                    <span class="form-control font-weight-bold input-muted">{{currency(item.qty)}}</span>
                </div>
                <div class="form-group mr-3 text-right" style="width: 120px">
                    <span class="form-control font-weight-bold input-muted">{{currency(item.cost)}}</span>
                </div>
            </div>
            <div v-if="newData.items.length > 0" class="text-right" style="padding-right:2rem">
                <button v-if="!loading" @click="saveData" class="btn btn-success"><font-awesome-icon icon="save" /> Simpan</button>
                <button v-else class="btn btn-secondary"><font-awesome-icon icon="save" /> Menyimpan...</button>
            </div>
            
            <div v-if="newData.type != null" class="text-right d-flex p-3 align-items-end mt-5">
                <div class="form-group mr-2" style="flex: 1">
                    <label class="small" for="name"><b class="text-success">Nama</b></label>
                    <input class="form-control" type="text" name="name" v-model="item.name" :disabled="loading">
                </div>
                <div class="form-group mr-2" style="width: 64px">
                    <label class="small" for="qty"><b class="text-success">Jumlah</b></label>
                    <input class="form-control text-center" type="number" name="qty" v-model.number="item.qty" :disabled="loading">
                </div>
                <div class="form-group mr-3" style="width: 120px">
                    <label class="small" for="cost"><b class="text-success">Nilai (@)</b></label>
                    <input class="form-control" type="number" name="cost" v-model.number="item.cost" :disabled="loading">
                </div>
                <div class="form-group mr-3" style="width: 56px">
                    <label class="small" for="discount"><b class="text-success">Discount (%)</b></label>
                    <input class="form-control text-center" type="number" name="discount" v-model.number="item.discount" :disabled="loading">
                </div>
                <button v-if="!loading" style="height:38px" @click="addToChart" class="btn btn-primary mb-3"><font-awesome-icon icon="save" /></button>
            </div>
        </div>
        <div v-if="addNew" class="position-fixed w-100 h-100" style="background:rgba(0,0,0,0.75); top:0; left:0" @click="!loading ? addNew = false : ''"></div>

        <div v-if="!addNew" class="position-fixed" style="right: 36px; bottom: 72px">
            <button @click="addNew = true" class="btn btn-primary" style="border-radius:100px; padding: 18px 22px"><font-awesome-icon icon="plus-circle" /></button>
        </div>
    </div>
</template>


<script>
import {db} from '@/main'
import firebase from 'firebase/app'
import 'firebase/auth'
import axios from 'axios'
import Datepicker from 'vuejs-datepicker';

export default {
    components: {
        Datepicker
    },
    data(){
        return {
            newData: {
                type: 'credit',
                created_at: new Date(),
                items: [],
            },
            item: {
                name: null,
                qty: null,
                cost: null,
                discount: null
            },
            loading: false,
            addNew: false
        }
    },
    methods:{
        addToChart(){
            this.newData.items.push(this.item)
            
            this.item = {
                name: null,
                qty: null,
                cost: null,
                discount: null
            }
        },
        async saveData(){
            this.loading = true

            var shift = await this.getShift()
            
            var data = {
                'shift': shift,
                'uid': firebase.auth().currentUser.uid,
                'cs': this.user.cs,
                'items': this.newData.items,
                'type': this.newData.type,
                'created_at': this.newData.created_at
            };
            var config = {
                method: 'post',
                url: 'https://us-central1-bypulsa-convert-pulsa.cloudfunctions.net/financeLog',
                headers: {
                'Content-Type': 'application/json'
                },
                data : data
            };

            axios(config).then(() => {
                this.loading = false
                this.addNew = false
                
                this.newData = {
                    type: 'credit',
                    created_at: new Date(),
                    items: []
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        sumItems(items){
            var sum = 0
            items.forEach(el => {
                sum += el.cost * el.qty - el.cost * el.qty * el.discount / 100
            });
            return sum
        }
    },
    computed: {
        finances: function(){
            return this.rawFinances.filter(tmp => {
                if(this.user.type == 'HOLDER'){
                    return true
                } else {
                    return tmp.cs == this.user.cs
                }
            })
        }
    },
    firestore(){
        return {
            rawFinances: db.collection('finances').orderBy('created_at', 'asc'),
            user: db.collection('users').doc(firebase.auth().currentUser.uid)
        }
    }
}
</script>