<template>
    <div>
        <FinanceNav />
        <FinanceOverlay />
        <Sidebar />

        <div class="mainholder p-2" style="margin-top:51px;">
            <Finboard v-if="$store.state.navigation == 'finboard'" />
            <Logbook v-if="$store.state.navigation == 'logbook'" />
            <CSValidity v-if="$store.state.navigation == 'cs-validity'" />
            <Banking v-if="$store.state.navigation == 'banking'" />
        </div>
        <transition name="fade">
            <Schedule v-if="$store.state.settings.schedule" />
        </transition>
        <transition name="fade">
            <ScheduleV2 v-if="$store.state.settings.schedulev2" />
        </transition>
    </div>
</template>

<script>
import FinanceNav from '@/components/navs/FinanceNav.vue'
import FinanceOverlay from '@/components/overlay_bar/FinanceOverlay'
import Sidebar from '@/components/finance/sidebar.finance'

import Finboard from '@/views/finance/Finboard.vue'
import Logbook from '@/views/finance/Logbook.vue'
import CSValidity from '@/views/finance/csvalidity.finance.vue'
import Schedule from '@/components/Schedule'
import ScheduleV2 from '@/components/ScheduleV2'

import Banking from "@/components/banking/bank.list.vue"

export default {
    components: {
        FinanceNav, FinanceOverlay, Sidebar,
        Finboard, Logbook, CSValidity, Schedule, ScheduleV2,
        Banking
    },
    created(){
        this.$store.state.navigation = 'finboard'
    }
}
</script>