<template>
    <div class="sideholder">
      <div class="settings">
        <div class="setting-item" :class="{'active' : set.route == $store.state.navigation}" v-for="(set, index) in settingList" :key="index" @click="$store.state.navigation = set.route">{{set.title}}</div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'finance-sidebar',
  data(){
    return {
      settingList: [
        {
          title: 'Finboard',
          route: 'finboard'
        },
        {
          title: 'Logbook',
          route: 'logbook'
        },
        {
          title: 'CS Validity',
          route: 'cs-validity'
        },
        {
          title: 'Bank',
          route: 'banking'
        }
      ]
    }
  }
}
</script>