<template>
  <div class="min-vw-100 min-vh-100 position-fixed" style="height:calc(100vh);top:0;left:0;background:rgba(0,0,0,0.7);z-index:99999;overflow:scroll;font-size:.85rem">
    <div class="w-100 h-100 position-fixed" style="top:0; left:0;z-index:-1" @click="$store.state.chips = !$store.state.chips"></div>
    <div class="w-100 bg-white mt-5 p-2" style="min-height: calc(100vh - 51px)">
      <div class="d-flex justify-content-between position-fixed bg-white w-100 p-2" style="top:0;left:0;height:51px;z-index:99">
        <ul class="nav nav-pills" id="pills-tab" role="tablist">
          <li class="nav-item" v-for="pr in providers" :key="'tab_'+pr">
            <span @click="tab = pr" class="nav-link" :class="{'active' : tab == pr}">{{pr}}</span>
          </li>
        </ul>
        <div class="d-flex">
        <button class="btn btn-outline-secondary text-small mr-3" @click="cleanDup()">Clean Duplicate</button>
          <Datepicker input-class="form-control" v-model="$store.state.seller.listChipsDate" />
          <button class="btn btn-outline-primary ml-3" @click="$store.commit('changeChipsDate')">Show</button>
        </div>
        <div><button class="btn btn-danger" @click="$store.state.chips = !$store.state.chips">X</button></div>
      </div>
      <div class="tab-content">
        <div v-for="pr in providers" :key="'list_'+pr" class="tab-pane fade" :class="{'show active' : tab == pr}" role="tabpanel" aria-labelledby="pills-home-tab">
          <list-chips :provider=pr />
        </div>
      </div>
    </div>

    <transition name="fade">
      <DetailTrx v-if="$store.state.seller.detailsTrx.length > 0" />
    </transition>
  </div>
</template>

<script>
import moment from 'moment'
import Datepicker from 'vuejs-datepicker';
import DetailTrx from '@/components/seller/DetailTrx'
import ListChips from '../../components/seller/ListChips.vue'
import codes from '@/helpers/data/codes.js'

export default {
  components: {
    Datepicker, DetailTrx, ListChips
  },
  data: () => {
    return {
      providers: ['TELKOMSEL', 'AXIS', 'THREE', 'INDOSAT'],
      tab: 'TELKOMSEL',
      codes: codes
    }
  },
  methods: {
    async cleanDup(){
      var identifier = 'log_'+moment(this.$store.state.seller.listChipsDate).subtract(1, "days").format('DD_MMM_yyyy')
      await this.clearDuplicateChip(this.tab, identifier)
      this.$store.commit('changeChipsDate')
    }
  }
}
</script>