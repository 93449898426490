<template>
    <div class="container">
        <div class="row" style="margin-bottom:58px">
            <div class="col-12" style="margin-top:16px">
                <table>
                    <thead>
                        <tr>
                            <th>Bank</th>
                            <th class="text-right">Awal Shift</th>
                            <th class="text-right">Akhir Shift</th>
                            <th class="text-right">Catatan</th>
                            <th class="text-right">Saat Ini</th>
                        </tr>
                    </thead>
                    <tbody> 
                        <tr v-for="(bank, i) in todayFiances" :key="i">
                            <td><span v-html="getBankInfo(bank)"></span></td>
                            <td>
                                <div class="text-right">
                                    <div>
                                        <label class="small" for="beginning">Saldo <b class="text-success">Awal</b> Shift</label>
                                        <div @click="log(bank)" class="font-weight-bold" style="font-size:1.25rem">Rp {{currency(bank.beginning)}}</div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="text-right" v-if="bank.ending != null">
                                    <div>
                                        <label class="small" for="ending">Saldo <b class="text-danger">Akhir</b> Shift</label>
                                        <div class="font-weight-bold" style="font-size:1.25rem">Rp {{currency(bank.ending)}}</div>
                                    </div>
                                </div>
                                <div class="text-right" v-else>
                                    <div class="form-group">
                                        <label class="small" for="beginning">Saldo <b class="text-danger">Akhir</b> Shift</label>
                                        <input class="form-control" type="number" name="ending" placeholder="(e.g 20000000)" @keypress.enter="endingRecord($event, bank)" :disabled="loading">
                                    </div>
                                    <span class="small bg-info text-white">*langsung tekan enter aja buat nyimpen</span>
                                </div>
                            </td>
                            <td class="d-flex flex-column justify-content-end">
                                <div v-if="bank.notes != null">{{bank.notes}}</div>
                                <div v-else>
                                    <textarea class="d-block form-control" @keyup="notedIt($event, bank)" id="notes" cols="30" rows="3" placeholder="misal. +10.000.000 dari BCA 1778211933 (Tomas)" :disabled="loading"></textarea>
                                    <button class="d-block my-2 btn btn-success" @click="updateNotes(bank)">Simpan</button>
                                </div>
                            </td>
                            <td class="text-right">
                                <!--
                                <label class="small" for="beginning">Saldo <b class="text-primary">Saat ini</b></label>
                                <div v-for="sb in serverBanks" :key="sb.id">
                                    <div class="font-weight-bold" style="font-size:1.25rem" v-if="sb.id == bank.server_bank_id">Rp {{currency(sb.balance)}}</div>
                                </div>
                                -->
                                <div v-if="bank.ending != null">
                                    <label class="small">Pengeluaran</label>
                                    <div class="font-weight-bold" style="font-size:1.25rem">Rp {{currency(bank.remittance)}}</div>
                                </div>
                            </td>
                        </tr>
                        <tr><td colspan="5" style="height:72px"></td></tr>
                    </tbody>
                </table>
            </div>
        </div>
        
        <InputBankStatement />

        <div v-if="!$store.state.finance.inputBankStatement" class="position-fixed" style="right: 36px; bottom: 72px">
            <button @click="$store.state.finance.inputBankStatement = true" class="btn btn-primary" style="border-radius:100px; padding: 18px 22px"><font-awesome-icon icon="plus-circle" /></button>
        </div>
    </div>
</template>


<script>
import {db} from '@/main'
import firebase from 'firebase/app'
import 'firebase/auth'
import axios from 'axios'
import moment from 'moment'
import InputBankStatement from '@/components/finance/inputbankstatement'

export default {
    components: {
        InputBankStatement
    },
    data(){
        return {
            loading: false,
            beginning: null,
            ending: '',
            notes: '',
            tempData: {
                id: null,
                bank: null,
                holder: null,
                account_number: null,
                created_at: null
            }
        }
    },
    methods:{
        getBankInfo(bank){
            var bankInfo
            for (const bnk of this.serverBanks) {
                if(bnk.id == bank.server_bank_id) bankInfo = bnk
            }
            return `
                <div class="d-flex">
                    <img src="${this.bankIconURL(bankInfo.bank)}" height="72px" />
                    <div class="ml-3">
                        <div>${bankInfo.holder}</div>
                        <div class="font-weight-bold">${bankInfo.account_number}</div>
                        <div>Rp ${this.currency(bankInfo.balance)}</div>
                    </div>
                </div>
            `
        },
        notedIt(e, financedaily){
            this.notes = e.target.value,
            this.tempData = financedaily
        },

        async updateNotes(financedaily){
            this.loading = true
            var data = {
                'notes': this.notes,
                'financedaily': financedaily,
                'updateType': 'notes'
            };
            var config = {
                method: 'post',
                url: 'https://us-central1-bypulsa-convert-pulsa.cloudfunctions.net/financeStoreDaily',
                headers: {
                'Content-Type': 'application/json'
                },
                data : data
            };

            axios(config).then(() => {
                this.emptyData()
            })
            .catch(function (error) {
                console.log(error);
            });
        },

        async endingRecord(e, financedaily){
            this.loading = true
            var data = {
                'ending': parseInt(e.target.value),
                'financedaily': financedaily,
                'cs': this.user.cs,
                'updateType': 'ending'
            };
            var config = {
                method: 'post',
                url: 'https://us-central1-bypulsa-convert-pulsa.cloudfunctions.net/financeStoreDaily',
                headers: {
                'Content-Type': 'application/json'
                },
                data : data
            };

            axios(config).then(() => {
                this.emptyData()
            })
            .catch(function (error) {
                console.log(error);
            });
        },

        emptyData(){
            this.loading = false
            this.$store.state.finance.inputBankStatement = false
            this.beginning = null
            this.ending = ''
            this.notes = ''
            this.tempData = {
                id: null,
                bank: null,
                holder: null,
                account_number: null,
                created_at: null,
                updated_at: null
            }
        }
    },
    computed: {
        todayFiances: function(){
            return this.financesDaily.filter(fnc => {
                return fnc.identifier == 'FNC'+moment(new Date).format('DDMMMyyyy') && fnc.cs == this.user.cs
            })
        }
    },
    firestore(){
        return {
            serverBanks: db.collection('server-banks').orderBy('bank').orderBy('holder').orderBy('account_number'),
            financesDaily: db.collection('finances-daily').orderBy('created_at'),
            user: db.collection('users').doc(firebase.auth().currentUser.uid)
        }
    }
}
</script>