import moment from 'moment'
import firebase from 'firebase/app'
import 'firebase/auth'
import {db} from '@/main'
import $ from 'jquery'

const csMixin = {
  methods: {
    showChats(){
      this.$store.state.csMenuDisplay = 'otw'
      this.$store.state.settings.newtrx = false
      this.$store.state.chips = false
    },
    printTrx(trx){
      console.log(trx.id)
    },
    copyToClipboard(id) {
      var $temp = $("<textarea>");
      $temp.val(id);
      console.log($temp.val())
      $("body").append($temp);
      $temp.trigger('select');
      document.execCommand("copy");
      $temp.remove();
    },
    async getShift(providedTime, providedCS){
      var csIndex = ''
      var time = ''
      var cs = firebase.auth().currentUser.email
      if(providedCS != null){
        cs = providedCS
      }
      switch (cs) {
        case 'cs1@bypulsa.com': case 'finance1@bypulsa.com':
            csIndex = 'satu'
          break;
        case 'cs2@bypulsa.com':
            csIndex = 'dua'
          break;
        
        case 'cs3@bypulsa.com':
            csIndex = 'tiga'
          break;
        case 'cs4@bypulsa.com':
            csIndex = 'empat'
          break;
          case 'cs5@bypulsa.com':
              csIndex = 'lima'
            break;
      }

      var now = moment();
      if(providedTime != null){
        now = moment.unix(providedTime.seconds)
      }

      //var user = await db.collection('users').doc(auth().currentUser.uid).get()
      //var last_signin = moment.unix(user.data().last_signin.seconds)

      var hourToCheck = (now.day() !== 0)?15:15;
      var dateToCheck = now.hour(hourToCheck).minute(0);
      if(moment().isAfter(dateToCheck)){ //last_signin > now.hour(15)
        time = 'siang'
      } else { time = 'pagi' }

      return csIndex+time
    },
    csUsername(){
      switch (firebase.auth().currentUser.email) {
        case 'cs1@bypulsa.com':
          return 'cs1'
        case 'cs2@bypulsa.com':
          return 'cs2'
        case 'cs3@bypulsa.com':
          return 'cs3'
        case 'cs4@bypulsa.com':
          return 'cs4'
        case 'cs5@bypulsa.com':
          return 'cs5'
      
        default:
          return '';
      }
    },
    async stopShift(){
      db.collection('users').doc(firebase.auth().currentUser.uid).update({cs: null}).then(async () => {
        this.$store.state.popMenu = false
        var cs = await this.csUsername()
        db.collection('cs').doc(cs).update({admin: null})
      })
    },
    getTheBank(bbnk){
      if(bbnk == null) return
      var theBank
      this.$store.state.banks.forEach((bnk) => {
        bnk.keywords.forEach((key) => {
          if(key.toUpperCase().includes(bbnk.toUpperCase())) theBank = bnk
        })
      })

      if(theBank == null) theBank = {
        name:bbnk,
        type:'bank',
        fee_side: 'server',
        fee: 2500
      }
      return theBank
    }
  }
}


export default csMixin