<template>
  <div style="font-size:0.9rem">
    <Navigation />
    <Sidebar :realtimeTrxs=transactions />
    <rightbar />
    <transition name='fade'>
      <CSPicker v-if="user.cs == null" />
    </transition>
    <div v-if="!$store.state.settings.newtrx" class="position-fixed" style="z-index:99999;top:8px;right:78px">
      <div v-if="!editing" @click="$store.state.settings.newtrx = true" class="edgeaction btn-success">New</div>
    </div>
    <div v-if="activeTrx != null" class="position-fixed" style="z-index:99999;top:8px;right:8px">
      <div v-if="!editing" @click="editMode(activeTrx, '')" class="edgeaction btn-primary">Edit</div>
      <div v-else @click="editMode(activeTrx, 'save')" class="edgeaction btn-success">Simpan</div>
    </div>
    
    <div v-if="activeTrx != null" class="maintrx mt-5" style="transition-duration:300ms" :style="$store.state.cs.listNumbers ? 'margin-right:250px' :''">
      <div class="d-none">{{getEmailByUID(activeTrx.uid)}}</div>
      <div class="row">
        <ChatBox v-if="activeTrx.media == 'APP'" :transaction="activeTrx" />
        <div class="col-md-5 col-sm-12 mb-5">
          <div class="card">
            <div class="card-body">
              <input v-if="activeTrx.nomor_server.value == '' && !savingNumber" class="pick-server-numb" contenteditable="true" data-tab="6"
                v-model="serverNumber"
                @keypress.enter="saveNumber(activeTrx)"
                @keydown.up="numberDown()" 
                @keydown.down="numberUp()" 
                placeholder="eg. ax9"
              />
              
              <div class="d-flex justify-content-center" v-else-if="savingNumber">
                  <flower-spinner
                    :animation-duration="2500"
                    :size="70"
                    color="#ff1d5e"
                  />
              </div>
              <div v-else><span v-if="$store.state.numberLists.length > 0" class="font-weight-bold">({{activeTrx.nomor_server.shortcut}})</span> {{activeTrx.nomor_server.value}} ({{currency(activeTrx.nomor_server.nominal)}})</div>
              <div v-if="activeTrx.nomor_server.value == '' || savingNumber">
                <div class="predefined-template _3SvgF" v-for="tmp in predefNumber" :key="tmp.key">
                  <div @mouseover="setIndexOnHover(tmp)" style="cursor:pointer" class="DuUXI" :class="{'active-template' : predefNumber[activeNumber] == tmp}" @click="saveNumber(activeTrx)" >
                    <span class="font-weight-bold">({{tmp.shortcut}})</span> {{tmp.value}} <span class="font-weight-bold">({{currency(tmp.nominal)}})</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mt-1">
            <div class="card-body">
              <table>
                <tr>
                  <td>Nomor</td>
                  <td class="font-weight-bold d-flex align-items-center">
                    <img :src="getProviderIcon(activeTrx.provider)" width="28px">
                    <span>{{activeTrx.nomor}}</span>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="text-center font-weight-bold">
                    <div class="d-flex justify-content-between align-middle">
                      <!-- <span style="margin: auto 0; color:green">{{currency(activeTrx.nominal)}} x {{activeTrx.rate}} - {{currency(activeTrx.fee)}}<span v-html="cbFormat(cashback(activeTrx))"></span> = {{currency((activeTrx.nominal * activeTrx.rate - activeTrx.fee + cashback(activeTrx)).toFixed(0))}}</span> -->
                      <span v-html="getCalculation(activeTrx)"></span>
                      <div v-if="!editing">
                        <button v-if="!sendCalculation" class="btn btn-primary py-1 pr-3" @click="sendCalculated(activeTrx)"><font-awesome-icon icon="paper-plane" style="color: 'white'" /></button>
                        <button v-else class="btn btn-success py-1 pr-3"><font-awesome-icon icon="check" style="color: 'white'" /></button>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-if="editing">
                  <td>Nominal</td>
                  <td class="font-weight-bold"><input type="number" v-model.number="activeTrx.nominal" :disabled="!editing"></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="card mt-1">
            <div class="card-body">
                  <table>
                    <tr>
                      <td style="width:100px;">Bank</td>
                      <td class="font-weight-bold d-flex align-items-center">
                        <div class="mr-3">
                          <img :src="bankIconURL(activeTrx.bank.bank)" width="32px" :alt="activeTrx.bank.bank">
                        </div>
                        <div>{{activeTrx.bank.bank}}</div>
                      </td>
                    </tr>
                    <tr>
                      <td @click="printTrx(activeTrx)">Nomor</td>
                      <td class="font-weight-bold">{{eWalletCode(activeTrx.bank.bank)}}{{activeTrx.bank.norek}}</td>
                    </tr>
                    <tr>
                      <td>Nama</td>
                      <td class="font-weight-bold">{{activeTrx.bank.atasnama}}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td class="font-weight-bold">{{customer.email}}</td>
                    </tr>
                  </table>
                  <div class="position-absolute" style="top:0;right:0">
                    <button v-if="!copiedRek" class="btn btn-primary py-2 px-4" @click="setCopyAble(activeTrx)"><font-awesome-icon icon="copy" style="color: 'white'" /></button>
                    <button v-else class="btn btn-success py-2 px-4" ><font-awesome-icon icon="check" style="color: 'white'" /></button>
                  </div>
            </div>
          </div>
          <div class="card mt-1" v-if="activeTrx.nomor_server.value != null && activeTrx.status != 'settlement'">
              <transition name="swipeleft">
                <div v-if="activeTrx.id != null && !editing" style="right:16px;bottom:16px">
                  <div class="card shadow">
                    <div class="card-header bg-primary text-white">Transaksi valid?</div>
                    <div class="card-body" style="font-size:14px">
                      <div class="d-flex justify-content-between">
                        <button @click="simpanData(activeTrx)" class="btn btn-success">Valid</button>
                        <button @click="sembunyikanTrx()" class="btn btn-danger">Tidak</button>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
          </div>
        </div>
      </div>
      <input style="position:fixed;top:-100px" type="text" id="noRek" name="noRek" :value="'Bank: '+activeTrx.bank.bank+'Rekening: '+activeTrx.bank.norek">
    </div>


      <div v-if="newChat.length > 0" :style="$store.state.cs.listNumbers ? 'right:274px':''" class="position-fixed" style="bottom:64px; right:24px; z-index:99999; cursor: pointer" @click="showChats()">
        <div class="chat-icon"><font-awesome-icon size="lg" icon="comment" color="white" /></div>
        <div class="position-absolute text-white text-small" style="top:0px; right:0px; background:#E94057; padding: 4px 10px; border-radius:50%"><span class="font-weight-bold">{{newChat.length }}</span></div>
      </div>

      <NewTransaction :submited="editing = false" v-if="$store.state.settings.newtrx" />
      <Kalkulator v-if="$store.state.settings.kalkulator" />
      <QuickReplay v-if="$store.state.settings.quickreplay" />
      <HistoryListCS v-if="$store.state.csMenuDisplay == 'LIST'" />
      <StatOverlay />

      <div v-if="$store.state.cs.imageViewer != null" class="w-100 h-100 position-fixed text-center" style="z-index: 9998;top:0; left:0;background:rgba(0,0,0,0.7); padding-top:10vh" @click="$store.state.cs.imageViewer = null">
        <img :src="$store.state.cs.imageViewer" style="max-height:80vh; max-width:80vw">
      </div>

      <LoadingOverlay v-if="saving" />

      <Hashtag :trans=$store.state.badge.trx v-if="$store.state.hashtag && $store.state.badge.trx.id != null" />
      <HashtagSetup v-if="$store.state.settings.hashtags"  />
      <Schedule v-if="$store.state.settings.schedule" />
      <ScheduleV2 v-if="$store.state.settings.schedulev2" />
  </div>
</template>

<script>
import { FlowerSpinner } from 'epic-spinners'
import { db, dbSV } from '../main'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import moment from 'moment'
import axios from 'axios'

//components
import Navigation from '../components/Navigation'
import Sidebar from '../components/dashboard/Sidebar'
import NewTransaction from '../components/NewTransaction'
import ChatBox from '../components/Chatbox'
import CSPicker from '@/components/CSPicker'
import StatOverlay from '@/components/StatOverlay'
import HistoryListCS from '@/components/cs/Complete'
import QuickReplay from '@/components/cs/QuickReplay'
import HashtagSetup from '@/components/cs/HashtagSetup'
import Hashtag from '@/components/Hashtag'
import Schedule from '@/components/Schedule'
import ScheduleV2 from '@/components/ScheduleV2'
import LoadingOverlay from '@/components/Loading'
import Kalkulator from '@/components/Kalkulator'
import Rightbar from '../components/cs/Rightbar.vue'

export default {
  components: {
    Navigation,
    NewTransaction,
    ChatBox,
    FlowerSpinner,
    Sidebar,
    CSPicker,
    StatOverlay,
    HistoryListCS,
    QuickReplay,
    Hashtag, HashtagSetup, Schedule, ScheduleV2, LoadingOverlay, Kalkulator,
    Rightbar
  },
  data: () => {
    return {
      customer: {email:''},
      savingNumber: false,
      serverNumber: '',
      activeNumber: 0,
      chat: firebase.database().ref('chatrooms/'),
      custombank: '',
      editing: false,
      saving: false,
      copiedRek: false,
      sendCalculation: false,
      logTime: 'log_'+moment(new Date).format('DD_MMM_yyyy'),
      logYesterday: '',
      month: moment(new Date).format('MMM_yyyy')
    }
  },
  created(){
    var yesterdayData = new Date(new Date().setDate(new Date().getDate() - 1))
    this.logYesterday = 'log_'+moment(yesterdayData).format('DD_MMM_yyyy')
    this.getNumbers()
  },
  mounted(){
    // setInterval(() => {
    //   this.expiredChecker(this.transactions)
    // }, 10000);
  },
  methods: {
    async getEmailByUID(uid){
      const data = await db.collection('users').doc(uid).get()
      this.customer = data.data()
    },
    cashback(trx){
      var cb = 0;

      if(trx.coupon != null) {
        if(trx.coupon.id != null){
          cb = trx.rate * trx.nominal * trx.coupon['value'];
          if(cb > trx.coupon['maxValue']) cb = trx.coupon['maxValue'];
        }
      }
      return cb
    },
    cbFormat(value){
      var resp = ''
      if(value > 0) resp = ` + <span style="color:blue">${this.currency(value)}</span>`
      return resp
    },
    getCalculation(trx){
      const theBank = this.getTheBank(trx.bank.bank)
      if(theBank == null) return

      //if eWallet, the fee wont be calculated from the service
      const fee = theBank.fee_side == 'server' ? theBank.fee : 0
      const notes = theBank.fee_side == 'server' ? `` : `<br/><span class='bg-danger text-white'>Fee langsung dari ${theBank.name}</span>`

      var theCalculation = trx.nominal * trx.rate - fee + this.cashback(trx)
      var cashback = this.cbFormat(this.cashback(trx));
      const calcReturn = `<span style="margin: auto 0; color:green">${this.currency(trx.nominal)} x ${trx.rate} - ${this.currency(fee)} <span>${cashback}</span> = ${this.currency(theCalculation)}</span>`


      return calcReturn+notes
    },
    setIndexOnHover(data){
      this.predefNumber.forEach((def, index) => {
        if(def == data){
          this.activeNumber = index
        }
      });
    },
    async sendCalculated(trx){
      if(!this.sendCalculation){
        this.sendCalculation = true
        const theBank = this.getTheBank(trx.bank.bank)
        if(theBank == null) return

        //if eWallet, the fee wont be calculated from the service
        const fee = theBank.fee_side == 'server' ? theBank.fee : 0
        const theCalculation = trx.nominal * trx.rate - fee

        var calc = `${this.currency(trx.nominal)} x ${trx.rate} - ${this.currency(fee)} = Rp ${this.currency(theCalculation)}`

        let newData = firebase.database().ref('chatrooms/'+trx.id+'/chats').push()
        await newData.set({ type: 'newmsg', user: firebase.auth().currentUser.email, message: calc, sendDate: dbSV.TIMESTAMP })

        await db.collection('converts').doc(trx.id).update({
          chat: {'cs': 'read', 'customer': 'unread'},
          on_app: true //visible to app cs
        })
        
        setTimeout(() => { this.sendCalculation = false }, 5000)
      }
    },
    async editMode(trx, mode){
      this.editing = true
      if(mode == 'save'){
        this.saving = true
        await db.collection('converts').doc(trx.id).update({ 'nominal': trx.nominal })
        let chatRef = firebase.database().ref('chatrooms/'+trx.id)
        await chatRef.update({ 'nominal': trx.nominal })
        this.editing = false
        this.saving = false
      }
    },
    identifyBank(){
      this.$store.state.activeTransaction.fee = this.getBankFee(this.$store.state.activeTransaction.bank.bank)
    },
    tickingCD(){
      this.copiedRek = true
      setTimeout(() => {this.copiedRek = false}, 2000)
    },
    async saveNumber(trx){
      this.savingNumber = true

      var data = {
        'id': trx.id,
        'nomor_server': this.predefNumber[this.activeNumber]
      };
      var config = {
        method: 'post',
        url: 'https://us-central1-bypulsa-convert-pulsa.cloudfunctions.net/updateChip',
        headers: {'Content-Type': 'application/json'},
        data : data,
      };

      var api = await axios(config);
      console.log(api)
      if(api.status == 200){
        let chatRef = firebase.database().ref('chatrooms/'+data.id)
        await chatRef.update(data)

        let newData = chatRef.child('/chats').push();
        await newData.set({
            type: 'newmsg',
            user: firebase.auth().currentUser.email,
            message: 'Kirim pulsanya ke nomor ini ya kak, \n'+data.nomor_server.value,
            sendDate: dbSV.TIMESTAMP
        })
      }
      
      this.serverNumber = ''
      this.savingNumber = false
    },
    numberDown(){
      if(this.activeNumber != null && this.predefNumber.length){
        this.activeNumber -= 1
      }
    },
    numberUp(){
      if(this.activeNumber != null && this.predefNumber.length){
        this.activeNumber += 1
      }
    },
    async sembunyikanTrx(){
      await firebase.database().ref('chatrooms/'+this.$store.state.activeTransaction.id).update({
        'status': 'invalid_evidence'
      })
      db.collection('converts').doc(this.$store.state.activeTransaction.id)
      .update({
        'status' : 'invalid_evidence',
        'on_app': false
      }).then(() => {
        this.$store.state.activeTransaction.id = null;
      })
      
    },
    csRegex(email){
      return email.match(/^([^@]*)@/)[1]
    },
    async simpanData(trx){
      this.saving = true
      var data = trx
      data.shift = await this.getShift()

      var config = {
        method: 'post',
        url: 'https://us-central1-bypulsa-convert-pulsa.cloudfunctions.net/updateTransaction',
        headers: {
          'Content-Type': 'application/json'
        },
        data : data,
      };

      axios(config).then(() => {
        this.saving = false
        this.$store.state.activeTransaction.id = null;
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    setCopyAble(trx){
      const theBank = this.getTheBank(trx.bank.bank)
      if(theBank == null) return

      //if eWallet, the fee wont be calculated from the service
      const fee = theBank.fee_side == 'server' ? theBank.fee : 0
      const theCalculation = trx.nominal * trx.rate - fee

      var calc =  `${this.currency(trx.nominal)} x ${trx.rate} - ${this.currency(fee)} = Rp ${this.currency(theCalculation)}`
      var cBoard = 
      'Bank: '+trx.bank.bank+
      '\nNama: '+trx.bank.atasnama+
      '\nRekening: '+trx.bank.norek+
      '\n\n'+calc
      ;
      this.copyToClipboard(cBoard);
      this.tickingCD()
    },
    getNumbers(){
      db.collection('numbers').where('identifier', '==', this.logYesterday).orderBy('order').get().then((snaps) => {
        this.$store.state.numberLists = []
        snaps.docs.forEach(element => {
          this.$store.state.numberLists.push(element.data())
        });
      })
    }
  },
  computed: {
    newChat: function(){
      return this.transactions.filter(trx => {
        if(trx.chat != null){
          return trx.chat.cs == 'unread'
        }
      })
    },
    predefNumber: function(){
      return this.$store.state.numberLists.filter(tmp => {
        return this.serverNumber.length >= 3 && tmp.shortcut.includes(this.serverNumber.toUpperCase()) || this.serverNumber.length >= 3 && tmp.value.includes(this.serverNumber.toUpperCase())
      })
    },
    activeTrx: function(){
      var value
      this.transactions.filter(trx => {
        if(trx.id == this.$store.state.activeTransaction.id) value = trx
      })
      return value
    }
  },
  firestore () {
    return {
      transactions: db.collection('converts').where('cs', '==', firebase.auth().currentUser.email).where('media', '==', 'APP').orderBy('updated_at', 'desc').limit(800),
      user: db.collection('users').doc(firebase.auth().currentUser.uid)
    }
  }
}
</script>