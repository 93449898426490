<template> 
    <div class="min-vw-100 min-vh-100 position-fixed" style="top:0;left:0;background:rgba(0,0,0,0.7);z-index:99999">
        <div class="w-100 h-100 position-fixed" style="top:0; left:0;z-index:-1" @click="$store.state.settings.faq = !$store.state.settings.faq"></div>
        <div class="w-96 mx-auto mt-3 p-3 row" style='border-radius:8px; background:white'>
            <div class="col-md-4">
                <div style="max-height:70vh; overflow:scroll">
                    <div v-for="faq in faqs" :key="faq.id" class="rounded p-2 cursor-pointer list-faq" :class="activeFaq.id == faq.id ? 'active-faq':''" style="background:#f5f5f5" @click="setFaq(faq)">{{faq.question}}</div>
                </div>
                <button class="btn btn-primary mt-4 py-2 w-100" @click="createNew">
                    <font-awesome-icon icon="plus-circle" class="mr-3" />
                    <span>Tambah FAQ</span>
                </button>
            </div>
            <div class="col-md-8">
                <div v-if="activeFaq.id != ''" style="height:90vh">
                    <div class="d-flex">
                        <textarea class="form-control mb-2" v-model="activeFaq.question" rows="2"></textarea>
                        <!-- <div class="form-group ml-2">
                            <label for="category">Kategori</label>
                            <select name="category" id="category" class="form-control">
                                <option value="">Pilih.....</option>
                                <option value=""></option>
                            </select>
                        </div> -->
                    </div>
                    <vue-editor style="height:60vh" v-model="activeFaq.answer"></vue-editor>
                    <div class="d-flex justify-content-between" style="margin-top:6rem">
                        <button class="btn btn-danger" @click="deleteFaq(activeFaq)">Hapus</button>
                        <button class="btn btn-success" @click="updateFaq(activeFaq)">Simpan</button>
                    </div>
                </div>
                <div v-else-if="newFaq.status == true">
                    <div class="d-flex">
                        <textarea class="form-control mb-2" v-model="newFaq.question" rows="2"></textarea>
                    </div>
                    <vue-editor style="height:60vh" v-model="newFaq.answer"></vue-editor>
                    <div class="d-flex justify-content-end" style="margin-top:6rem">
                        <button class="btn btn-success" @click="storeFaq">Simpan</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { db } from '@/main'
import { VueEditor } from "vue2-editor";

export default {
    name: 'faq',
    components: {VueEditor},
    data: () => {
        return {
            loading: false,
            activeFaq: {id:''},
            newFaq: {
                status: false,
                question: '',
                answer: ''
            }
        }
    },
    methods: {
        createNew(){
            this.activeFaq = {id:''}
            this.newFaq.status = true
        },
        async storeFaq(){
            this.loading = true
            var ref = db.collection('faq').doc();
            var newFaq = {
                id: ref.id,
                views: 0,
                question: this.newFaq.question,
                answer: this.newFaq.answer
            }
            await ref.set(newFaq);
            this.loading = false
            this.newFaq.status = false
            this.newFaq.question = ''
            this.newFaq.answer = ''
        },
        setFaq(faq){
            this.activeFaq = faq
        },
        async updateFaq(faq){
            this.loading = true
            var update = {
                question: faq.question,
                answer: faq.answer
            }
            
            await db.collection('faq').doc(faq.id).update(update);
            this.loading = false
            this.activeFaq = {id:''}
        },
        async deleteFaq(faq){
            this.loading = true
            await db.collection('faq').doc(faq.id).delete();
            this.loading = false
            this.activeFaq = {id:''}
        }
    },
    firestore () {
        return {
            faqs: db.collection('faq')
        }
    }
}
</script>