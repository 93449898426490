<template>
  <div class="vh-100 vw-100 position-fixed" style="top:0;left:0; background:rgba(0,0,0,0.75);z-index:100000">
    <div class="login min-vh-100 row justify-content-center align-items-center">
      <div class="col-lg-7 col-md-10 col-xs-12 bg-white" style="border-radius:16px">
        <div class="row">
          <div class="col-6 color-primary p-5" style="border-bottom-left-radius:16px;border-top-left-radius:16px">
            <div class="row px-4 my-5">
              <div class="col-12">
                <div>
                  <h4 class="font-weight-bold text-white">Pa Kabar Guys!</h4>
                  <div style="width:60px; height:4px;background:white"></div>
                </div>
                <p class="text-white font-weight-light mt-4" style="font-size:14px;line-height:24px">Halo rekan byPulsa,<br> silahkan login dengan akun yang sudah disediakan. Apabila ada kendala, lansung hubungi SV atau Tim Developer <a class="text-white font-weight-bold font-italic" href="https://api.whatsapp.com/send?phone=6285229434855">di sini</a></p>
                <span class="btn text-white text-small" @click="logOut()">Logout</span>

                <div class="doughnut position-absolute" style="bottom:-60px;right:-110px"></div>
              </div>
            </div>
          </div>
          <div class="col-6 text-center p-5">
            <h4 class="font-weight-bold mb-5 mt-4">Sign In</h4>
              <div class="form-group mb-4 mx-5">
                <select class="form-control" :class="{'text-secondary': loading}" v-model="thecs" style="font-size:14px" name="list_cs" id="list_cs" :disabled="loading">
                  <option :value="null" disabled></option>
                  <option v-for="(team, i) in $store.state.teams" :value="team.name" :key="i">{{team.name}}</option>
                </select>
              </div>
              <button @click="mulaiCSin" class="btn text-white button-primary px-5 py-2 shadow align-middle" style="border-radius:50px" :disabled="loading || thecs == ''">
                <span v-if="loading" style="display:inline-block;transform:translateY(-3px)">
                  <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                  <span class="sr-only">Loading...</span>
                </span>
                <span v-if="!loading">Mulai</span>
                <span v-if="loading">Loading...</span>
              </button>
            <p class="mt-4">Jangan lupa, do'a dulu guys.</p>
            
            <div class="squarell position-absolute" style="top:20px;right:-50px"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {db} from '@/main'
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  data(){
    return {
      thecs: '',
      loading: false
    }
  },
  methods: {
    async mulaiCSin(){
      this.loading = true
      db.collection('users').doc(firebase.auth().currentUser.uid).update({
        cs: this.thecs,
        last_signin: new Date()
      })
    }
  }
}
</script>