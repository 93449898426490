<template>
  <div class="position-fixed w-100 p-2" style="bottom:0;left:0; background:rgba(255,255,255,0.75); box-shadow: rgb(0 0 0 / 8%) -1px 0rem 0.25rem !important;z-index:999">
    <div class="d-flex justify-content-between">
      <div><span class="text-small">byPulsa {{version}}</span></div>
      <div class="d-flex justify-content-end">
        <div v-for="(csstat, index) in cscounter" :key="index" style="font-size:12px" class="mr-3 d-flex justify-content-center ml-2 cursor-pointer" @dblclick="setCSStatus(csstat)">
          <span class="indicator d-flex justify-content-center" style="margin: auto 8px auto auto" :class="[csstat.status ? 'bg-on' : 'bg-off' ]"></span> {{csRegex(csstat.email)}} ({{csstat.client}})
        </div>
        <div v-if="!reseting" @click="resetCSCounter()" class="btn btn-primary badge ml-2">Reset</div>
        <div v-else class="btn btn-secondary badge ml-2">loading...</div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from '@/main'

export default {
  data(){
    return {
      reseting: false,
      version: process.env.VUE_APP_VERSION
    }
  },
  methods: {
    setCSStatus(cs){
      var csOn = 0
      for (const theCS of this.cscounter) {
        if(theCS.status) csOn++
      }

      if(csOn <= 2 && cs.status == true) {
        alert("Minimal 2 CS ON!")
        return
      }
      
      db.collection('cs').doc(cs.id).update({
        status: !cs.status
      })

    },
    csRegex(email){
      return email.match(/^([^@]*)@/)[1]
    },
    resetCSCounter(){
      var vm = this
      this.reseting = true
      
      let batch = db.batch();
      let cs1 = db.collection('cs').doc('cs1');
      batch.update(cs1, {client: 0});

      let cs2 = db.collection('cs').doc('cs2');
      batch.update(cs2, {client: 0});

      let cs3 = db.collection('cs').doc('cs3');
      batch.update(cs3, {client: 0});

      let cs4 = db.collection('cs').doc('cs4');
      batch.update(cs4, {client: 0});

      let cs5 = db.collection('cs').doc('cs5');
      batch.update(cs5, {client: 0});

      return batch.commit().then(function(){
        vm.reseting = false
      })
    },
  },
  firestore(){
    return {
      cscounter: db.collection('cs'),
    }
  }
}
</script>