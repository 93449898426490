<template>
    <div>
        <table>
            <thead>
                <tr>
                    <th>Bank (IDR {{currency(sumBalance)}})</th>
                    <th class="text-right">Action</th>
                </tr>
            </thead>
            <tbody> 
                <tr v-for="(bank, i) in serverBanks" :key="i">
                    <td>
                        <div class="d-flex">
                            <img :src="bankIconURL(bank.bank)" height="72px" />
                            <div class="ml-3">
                                <div>{{bank.holder}}</div>
                                <div class="font-weight-bold">{{bank.account_number}}</div>
                                <div>Rp {{currency(bank.balance)}}</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-right">

                    </td>
                </tr>
                <tr><td colspan="4" style="height:36px"></td></tr>
            </tbody>
        </table>

        <div v-if="addNew" class="position-fixed" style="left:50%; top:15%; transform:translate(-50%, 0);z-index:9999">
            <div class="card" style="border: none">
                <div class="card-header text-white bg-primary">
                    <span class="font-weight-bold">Tambah Rekening Baru</span>
                </div>
                <div v-if="!loading" class="card-body px-5 pb-5">
                    <div class="form-group">
                        <img :src="bankIconURL('BCA')" height="56px" class="btn" :class="[newBank.bank == 'BCA' ? 'btn-primary' : '']" @click="newBank.bank = 'BCA'" />
                        <img :src="bankIconURL('BRI')" height="56px" class="btn" :class="[newBank.bank == 'BRI' ? 'btn-primary' : '']" @click="newBank.bank = 'BRI'" />
                        <img :src="bankIconURL('LINKAJA')" height="56px" class="btn" :class="[newBank.bank == 'LINKAJA' ? 'btn-primary' : '']" @click="newBank.bank = 'LINKAJA'" />
                        <img :src="bankIconURL('BNI')" height="56px" class="btn" :class="[newBank.bank == 'BNI' ? 'btn-primary' : '']" @click="newBank.bank = 'BNI'" />
                        <img :src="bankIconURL('MANDIRI')" height="56px" class="btn" :class="[newBank.bank == 'MANDIRI' ? 'btn-primary' : '']" @click="newBank.bank = 'MANDIRI'" />
                    </div>
                    <div class="form-group">
                        <label for="holder">Atas Nama</label>
                        <input type="text" class="form-control" name="holder" v-model="newBank.holder">
                    </div>
                    <div class="form-group">
                        <label for="account-number">Nomor Rekening</label>
                        <input type="number" class="form-control" name="account-number" v-model="newBank.account_number">
                    </div>
                    <div class="form-group">
                        <label for="balance">Saldo</label>
                        <input type="number" class="form-control" name="balance" v-model.number="newBank.balance">
                    </div>
                    <div class="form-group text-right mt-5 mb-0">
                        <button v-if="!loading" @click="saveBank" class="btn btn-success"><font-awesome-icon icon="save" /> Simpan</button>
                    </div>
                </div>
                <div v-else class="card-body px-5 py-5 d-flex justify-content-center flex-column text-center font-weight-bold">
                    <div>
                        <semipolar-spinner
                            :animation-duration="2000"
                            :size="65"
                            style="margin:auto"
                            color="#ff1d5e"
                        />
                    <div class="mt-3">loading...</div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="addNew" class="position-fixed w-100 h-100" style="background:rgba(0,0,0,0.75); top:0; left:0" @click="!loading ? addNew = false : ''"></div>

        <div v-if="!addNew" class="position-fixed" style="right: 36px; bottom: 72px">
            <button @click="addNew = true" class="btn btn-primary" style="border-radius:100px; padding: 18px 22px"><font-awesome-icon icon="plus-circle" /></button>
        </div>
    </div>
</template>

<script>
import {db} from '@/main'
import { SemipolarSpinner  } from 'epic-spinners'

export default {
    components: {
        SemipolarSpinner
    },
    data(){
        return {
            addNew: false,
            loading: false,
            newBank: {
                bank: null,
                holder: null,
                account_number: null,
                balance: null,
                updated_at: null,
                created_at: null
            }
        }
    },
    methods: {
        saveBank(){
            if(this.newBank.bank == null || this.newBank.holder == null || this.newBank.account_number == null || this.newBank.balance == null){
                return
            }
            this.loading = true
            this.newBank.updated_at = new Date()
            this.newBank.created_at = new Date()
            var bankRef = db.collection('server-banks').doc()
            this.newBank.id = bankRef.id
            bankRef.set(this.newBank).then(() => {
                this.loading = false
                this.newBank = {
                    bank: null,
                    holder: null,
                    account_number: null,
                    balance: null,
                    updated_at: null,
                    created_at: null
                }
            })
        },
    },
    computed: {
        sumBalance: function(){
            var sum = 0
                this.serverBanks.forEach(fnc => {
                    sum += fnc.balance
                });
            return sum
        }
    },
    firestore(){
        return {
            serverBanks: db.collection('server-banks').orderBy('bank')
        }
    }    
}
</script>