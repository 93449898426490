<template>
  <div class="col-md-7 col-sm-12 mb-2" style="max-height:calc(100vh - 108px)">
    <div class="chat-box" style="background-color:rgba(0,0,0,.075);margin-bottom:64px">
      <div class="list-group" id="chatitems" style="list-style:none">
        <transition-group name="swipeup">
        <div class="chat-item _1RAno" v-for="(chat, idx) in chats" :key="chat.key">
          <div class="chat-status text-center" style="width:100%" v-if="chat.type==='join'||chat.type==='exit'">
            <span class="chat-content-center">{{chat.message}}</span>
          </div>
          <div v-else>
            <div class="_1RAno message-out _1ij5F _2tbXF _1zinb" v-if="chat.user === nickname">
              <span data-testid="tail-out" data-icon="tail-out" class="_3N37N"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 13" width="8" height="13"><path opacity=".13" d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"></path><path fill="currentColor" d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"></path></svg></span>
              <div class="_2XJpe _7M8i6">
                <span aria-label="You:"></span>
                <div class="_1dB-m" :id="'cs_chat_'+idx" @mouseover="hoveringChat(idx)" @mouseout="unHoveringChat(idx)">
                  <div class="delete-chat" @click="deleteChat(chat)"><font-awesome-icon icon="trash" color="white" /></div>
                  <span v-if="chat.type == 'newmsg'" style="white-space: pre-line" v-html="chat.message" />
                  <img v-else-if="chat.type == 'newimg'" :src="chat.attachment" width="200px" alt="" @click="zoomImage(chat.attachment)">
                  <span class="_3ysB9 _1BcQo"></span>
                </div>
                <div class="_1nOME">
                  <div class="_185ho" role="button">
                    <span class="_2JNr-" dir="auto">{{fbDateToString(chat.sendDate, 'HH:mm')}}</span>
                    <div class="_2v8yt">
                      <span data-testid="msg-dblcheck" aria-label=" Delivered " data-icon="msg-dblcheck" class="">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 15" width="14" height="13"><path fill="currentColor" d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"></path></svg>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="_1RAno message-in focusable-list-item" v-if="chat.user !== nickname">
              <span></span>
              <div class="message-in _1ij5F _2tbXF _1zinb"><span data-testid="tail-in" data-icon="tail-in" class="_3N37N"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 13" width="8" height="13"><path opacity=".13" fill="#0000000" d="M1.533 3.568L8 12.193V1H2.812C1.042 1 .474 2.156 1.533 3.568z"></path><path fill="currentColor" d="M1.533 2.568L8 11.193V0H2.812C1.042 0 .474 1.156 1.533 2.568z"></path></svg></span>
              <div class="_2XJpe _7M8i6">
                <div class="_1dB-m">
                  <div class="CWVX1 color-3 dV60t" role="">
                    <span dir="auto" class="_19038 _3cwQ7 _1VzZY">{{chat.user}}</span>
                  </div>
                  <div class="cln10 copyable-text" data-pre-plain-text="[13:37, 11/26/2020] Venti byPulsa: ">
                    <div class="_1wlJG" dir="ltr">
                      <span dir="ltr" class="_1VzZY selectable-text invisible-space copyable-text">
                        <span v-if="chat.type == 'newmsg'">{{chat.message}}</span>
                        <img v-else-if="chat.type == 'newimg'" :src="chat.attachment" width="200px" @click="zoomImage(chat.attachment)">
                      </span><span class="_3ysB9"></span></div>
                  </div>
                  <div class="_1nOME"><div class="_185ho"><span class="_2JNr-" dir="auto">{{fbDateToString(chat.sendDate, 'HH:mm')}}</span></div></div>
                  </div><span></span></div>
              </div>
            </div>
          </div>
        </div>
        </transition-group>
        <div v-if="uploadValue != null" class="chat-item _1RAno">
          <div class="chat-status text-center" style="width:100%">
            <div class="my-3">
              <fingerprint-spinner
                :animation-duration="1500"
                :size="48"
                color="#ff1d5e"
                style="margin:auto;"
              />
            </div>
            <span class="chat-content-center">mengupload gambar ({{uploadValue.toFixed(0)}}%)</span>
          </div>
        </div>
      </div>
    </div>
    
    <footer class="_2ig1U" style="position:absolute;bottom:0;left:0;margin:0 15px">
      <div>
        <div class="predefined-template _3SvgF" v-for="tmp in predefinedTemplates" :key="tmp.key">
          <div class="DuUXI" style="white-space: pre-line" :class="{'active-template' : predefinedTemplates[activeTemplate] == tmp}">{{tmp.text}}</div>
        </div>
      </div>
      <div class="_3SvgF _1mHgA copyable-area">
        <div class="DuUXI">
          <div class="_2HE1Z _1hRBM">
            <input @paste.prevent="fileChange" class="_1awRl copyable-text selectable-text" contenteditable="true" data-tab="6" dir="ltr" spellcheck="true" v-model="data.message" @keypress.enter="sendMessage()" @focus="focusTheChatBox()" @blur="readAll()" @keydown.up="templateDown()" @keydown.down="templateUp()" />
          </div>
        </div>
      <transition name="fade">
        <div v-if="data.message != ''" class="_3qpzV">
          <button class="_2Ujuu" @click="sendMessage()"><span data-testid="send" data-icon="send" class=""><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="currentColor" d="M1.101 21.757L23.8 12.028 1.101 2.3l.011 7.912 13.623 1.816-13.623 1.817-.011 7.912z"></path></svg></span></button>
        </div>
      </transition>
      <transition name="fade">
        <div v-if="data.message == ''" class="_3qpzV">
          <button class="_2Ujuu" @click="onPickFile()"><span data-testid="send" data-icon="send" class=""><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="currentColor" d="M1.816 15.556v.002c0 1.502.584 2.912 1.646 3.972s2.472 1.647 3.974 1.647a5.58 5.58 0 0 0 3.972-1.645l9.547-9.548c.769-.768 1.147-1.767 1.058-2.817-.079-.968-.548-1.927-1.319-2.698-1.594-1.592-4.068-1.711-5.517-.262l-7.916 7.915c-.881.881-.792 2.25.214 3.261.959.958 2.423 1.053 3.263.215l5.511-5.512c.28-.28.267-.722.053-.936l-.244-.244c-.191-.191-.567-.349-.957.04l-5.506 5.506c-.18.18-.635.127-.976-.214-.098-.097-.576-.613-.213-.973l7.915-7.917c.818-.817 2.267-.699 3.23.262.5.501.802 1.1.849 1.685.051.573-.156 1.111-.589 1.543l-9.547 9.549a3.97 3.97 0 0 1-2.829 1.171 3.975 3.975 0 0 1-2.83-1.173 3.973 3.973 0 0 1-1.172-2.828c0-1.071.415-2.076 1.172-2.83l7.209-7.211c.157-.157.264-.579.028-.814L11.5 4.36a.572.572 0 0 0-.834.018l-7.205 7.207a5.577 5.577 0 0 0-1.645 3.971z"></path></svg></span></button>
          <input
            type="file"
            style="display: none"
            ref="fileInput"
            accept="image/*"
            @change="onFilePicked"/>
        </div>
      </transition>
      </div>
    </footer>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/storage'
import {db, dbSV} from '@/main'
import axios from 'axios'
import { FingerprintSpinner } from 'epic-spinners'
const uuidv1 = require('uuid/v1');
import JQuery from 'jquery'
let $ = JQuery

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default {
  props: {
    transaction: Object
  },
  components: {
    FingerprintSpinner
  },
  watch: { 
    transaction: function(updated) {
      this.unListen()
      this.getChats(updated)
    }
  },
  data(){
    return {
      uploadValue: null,
      uploadImage: [],
      chatListener: null,
      nickname: firebase.auth().currentUser.email,
      chats: [],
      alreadyJoin: false,
      data: { type:'', nickname:'', message:'', attachment: '' },
      activeTemplate: 0,
    }
  },
  mounted(){
    this.getChats(this.$props.transaction)
  },
  methods: {
    async deleteChat(chat){
      await firebase.database().ref('chatrooms/'+this.$props.transaction.id+'/chats/'+chat.key).remove();
    },
    hoveringChat(idx){
        $("#cs_chat_"+idx+" .delete-chat").addClass('opacity-5')
    },
    unHoveringChat(idx){
        $("#cs_chat_"+idx+" .delete-chat").removeClass('opacity-5')
    },
    focusTheChatBox(){
      window.document.title = 'Dashboard - byPulsa';
      this.readAll();
    },
    parseChat(chat){
      if(chat.includes('[provider]')){
        return chat.replace('[provider]', `${this.$store.state.activeTransaction.provider}`)
      }
      if(chat.includes('[calculator]')){
        return chat.replace('[calculator]', `${this.currency(this.$store.state.activeTransaction.nominal)} x ${this.$store.state.activeTransaction.rate} - ${this.currency(this.$store.state.activeTransaction.fee)} = Rp ${this.currency(this.$store.state.activeTransaction.nominal*this.$store.state.activeTransaction.rate - this.$store.state.activeTransaction.fee)}`)
      }

      return chat
    },
    fileChange(event, callback){
      var items = event.clipboardData.items;

      if(items == undefined){
          if(typeof(callback) == "function"){
              callback(undefined);
              console.log('Undefined 2')
          }
      }
      for (var i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") == -1) continue;
          var blob = items[i].getAsFile();
          this.parseImage(blob)
          return
      }
      
      if(event.clipboardData.items[0].kind == 'string'){
        this.data.message = this.data.message + event.clipboardData.getData('text')
      }
    },
    async parseImage(file){
      var resized = await this.resizeMe(file)

      var namafile = uuidv1() + '.jpg';

      const storageRef = firebase.storage().ref('converts/server/' + namafile).putString(resized, 'data_url', {contentType:'image/jpg'});
      storageRef.on(`state_changed`,snapshot=>{
        this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error=>{console.log(error.message)},
        ()=>{this.uploadValue=null;
          storageRef.snapshot.ref.getDownloadURL().then((url)=>{
            let newData = firebase.database().ref('chatrooms/'+this.$props.transaction.id+'/chats').push();
              newData.set({
                  type: 'newimg',
                  user: this.nickname,
                  message: this.data.message,
                  attachment: url,
                  sendDate: dbSV.TIMESTAMP
              });
          })
      });
    },
    async resizeMe(img) {
      window.URL = window.URL || window.webkitURL;
      var blobURL = window.URL.createObjectURL(img);
      
      var image = new Image();
      image.src = blobURL;
      var res

      image.onload = async () => {
        var max_width = 1200; var max_height = 1200;
        var canvas = document.createElement('canvas');

        var width = image.width;
        var height = image.height;

        // calculate the width and height, constraining the proportions
        if (width > height) {
          if (width > max_width) {
            //height *= max_width / width;
            height = Math.round(height *= max_width / width);
            width = max_width;
          }
        } else {
          if (height > max_height) {
            //width *= max_height / height;
            width = Math.round(width *= max_height / height);
            height = max_height;
          }
        }
        
        // resize the canvas and draw the image data into it
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, width, height);
        
        res = canvas.toDataURL("image/jpeg",0.65); // get the data from canvas as 70% JPG (can be also PNG, etc.)
      }
      await delay(500)
      return res

    },
    zoomImage(img){
      this.$store.state.cs.imageViewer = img
    },
    getChats(trx){
      this.chats = []
      firebase.database().ref('chatrooms/'+trx.id).once('value').then((snapshot) => {
        if(snapshot.exists){
          if(!snapshot.val().joinCs){
            firebase.database().ref('chatrooms/'+trx.id).update({joinCs: true})
            let joinData = firebase.database().ref('chatrooms/'+trx.id+'/chats').push();
            joinData.set({
              type: 'join',
              user: this.nickname,
              message: this.nickname+' bergabung.',
              sendDate: dbSV.TIMESTAMP
            });
          }
          this.chatListener = firebase.database().ref('chatrooms/'+trx.id+'/chats');
          this.chatListener.on('value', (snapshot) => {
            this.chats = [];
            snapshot.forEach((doc) => {
              let item = doc.val()
              item.key = doc.key
              this.chats.push(item)
            });
          });
        }
      })
    },
    unListen(){
      if(this.chatListener != null){
        this.chatListener.off()
      }
    },
    readAll(){
      db.collection('converts').doc(this.$props.transaction.id).update({
        serverRead: true
      })
      let updateMeta = firebase.database().ref('chatrooms/'+this.$props.transaction.id+'/meta/csNewMessage');
      updateMeta.transaction(() => {
        return 0;
      })
    },
    scrollToEnd(){
      var container = document.querySelector(".chat-box");
      var scrollHeight = container.scrollHeight;
      container.scrollTop = scrollHeight + 132;
    },
    sendMessage(){
      if(this.data.message != ''){
        let newData = firebase.database().ref('chatrooms/'+this.$props.transaction.id+'/chats').push();
        if(this.predefinedTemplates.length > 0){
          newData.set({
              type: 'newmsg',
              user: this.nickname,
              message: this.parseChat(this.predefinedTemplates[this.activeTemplate].text),
              sendDate: dbSV.TIMESTAMP
          });
          this.pushNotification(this.nickname, this.predefinedTemplates[this.activeTemplate].text)
        } else {
          newData.set({
              type: 'newmsg',
              user: this.nickname,
              message: this.parseChat(this.data.message),
              sendDate: dbSV.TIMESTAMP
          });
          this.pushNotification(this.nickname, this.parseChat(this.data.message))
        }

        db.collection('converts').doc(this.$props.transaction.id).update({
          custRead: false
        })
          
        let updateMeta = firebase.database().ref('chatrooms/'+this.$props.transaction.id+'/meta/userNewMessage');
        updateMeta.transaction((current_value) => {
          return (current_value || 0) + 1;
        })

        this.data.message = '';
        this.activeTemplate = 0;
        this.scrollToEnd()
      }
    },
    pushNotification(nick, message){
      var vm = this
      var title = nick
      var pesan = message

      var data = db.collection('users').doc(vm.$store.state.activeTransaction.uid).collection('tokens').orderBy('createdAt', 'desc').limit(1)
      data.get().then(function(qSnap){
        qSnap.forEach(function(doc){
          if(doc.data()['token'] != null){
            var fcmheaders = {
              'Content-Type': 'application/json',
              'Authorization': 'key=AAAAqKvmdMQ:APA91bGZlFGDGiNWgRMUIxlNDCzi3FAEbWqsoRwyNhV0Z0oftfuq-UhZBmUL7kUC5b2yXU5YPiZVg-DrMhrQbNiZzBnIXaLVYZrCGrQR1WypOs_BrXSQrO48y-UTnzLyVottRelrbbUe'
            }
            var fcmbody = {
              "to": doc.data()['token'],
              "notification": {
                "title": title,
                "body": pesan,
              },
              "data": {
                "title": title,
                "body": pesan,
                'type': 'chat',
                "id" : vm.$store.state.activeTransaction.id,
                "click_action": "FLUTTER_NOTIFICATION_CLICK",
              }
            }
            axios.post('https://fcm.googleapis.com/fcm/send', fcmbody, {headers: fcmheaders})
            vm.nomor_updated = true
          }
        })
      })
    },
    templateDown(){
      if(this.activeTemplate != null && this.predefinedTemplates.length){
        this.activeTemplate -= 1
      }
    },
    templateUp(){
      if(this.activeTemplate != null && this.predefinedTemplates.length){
        this.activeTemplate += 1
      }
    },
    onPickFile () {
      this.$refs.fileInput.click()
    },
    onFilePicked (event) {
      const files = event.target.files
      //let filename = files[0].name
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        this.imageUrl = fileReader.result
      })
      fileReader.readAsDataURL(files[0])
      this.data.attachment = files[0]

      if(files[0] != null){
        var re = /(?:\.([^.]+))?$/;
        var file = files[0];
        var namafile = uuidv1() + '.' + re.exec(file.name)[1];

        const storageRef = firebase.storage().ref('converts/server/' + namafile).put(file);
        storageRef.on(`state_changed`,snapshot=>{
          this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
        }, error=>{console.log(error.message)},
          ()=>{this.uploadValue=null;
            storageRef.snapshot.ref.getDownloadURL().then((url)=>{
              let newData = firebase.database().ref('chatrooms/'+this.$props.transaction.id+'/chats').push();
                newData.set({
                    type: 'newimg',
                    user: this.nickname,
                    message: this.data.message,
                    attachment: url,
                    sendDate: dbSV.TIMESTAMP
                });
            })
        });
      }
    }
  },
  updated(){
    this.scrollToEnd()
  },
  computed: {
    predefinedTemplates: function(){
      return this.templates.filter(tmp => {
        return this.data.message.length >= 2 && tmp.short.includes(this.data.message)
      })
    },
  },
  firestore(){
    return {
      templates: db.collection('users').doc(firebase.auth().currentUser.uid).collection('templates')
    }
  }
}
</script>