
const Models = {
  methods: {
    Bank(name){
        let bank = {
            iconURL: ''
        };
        if(name == null) return bank;
        
        this.$store.state.banks.every(bnk => {
            bnk.keywords.forEach((keyword) => {
                if(keyword.toUpperCase() == name.toUpperCase()){
                    bank = bnk;
                    return false;
                }
            })
            return true;
        });
        return bank;
    }
  }
}

export default Models