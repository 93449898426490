import Vue from 'vue'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import VueFirestore from 'vue-firestore';
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './initialize'
import '@/bloc/bloc.js'

Vue.config.productionTip = false

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faSearch, faCopy, faSave, faTimes, faSlidersH, faExchangeAlt, faSignOutAlt, faCheck, faWrench, faCog, faGlobe, faCircle, faArrowLeft, faArrowRight, faEdit, faComment, faPaperPlane, faTrash, faPlusCircle, faMinusCircle, faCalculator, faHashtag, faCalendar, faGripVertical, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faUserSecret, faSearch, faCopy, faSave, faTimes, faSlidersH, faExchangeAlt, faSignOutAlt, faCheck, faWrench, faCog, faGlobe, faCheck, faCircle, faArrowLeft, faArrowRight, faEdit, faComment, faPaperPlane, faTrash, faPlusCircle, faMinusCircle, faCalculator, faTrash, faCheck, faHashtag, faCalendar, faGripVertical, faQuestionCircle)

Vue.component('font-awesome-icon', FontAwesomeIcon)


let app = '';
let config = {
  apiKey: "AIzaSyDUaiwrHUiZelJMR-pHA9xlSiBpFrh3Pk4",
  authDomain: "bypulsa-convert-pulsa.firebaseapp.com",
  databaseURL: "https://converts.asia-southeast1.firebasedatabase.app",
  projectId: "bypulsa-convert-pulsa",
  storageBucket: "bypulsa-convert-pulsa.appspot.com",
  messagingSenderId: "724438512836",
  appId: "1:724438512836:web:fddc1bf89213da5c0d8591",
  measurementId: "G-2TFSM47WQC"
};
firebase.initializeApp(config);
const db = firebase.firestore()
const fv = firebase.firestore.FieldValue
const dbSV = firebase.database.ServerValue
export {db, fv, dbSV}

Vue.use(VueFirestore);


import VueMobileDetection from "vue-mobile-detection";
Vue.use(VueMobileDetection);

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    /* eslint-disable no-new */
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app');
  }
});