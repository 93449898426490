<template>
  <div class="container">
      <div class="row">
      <div class="col-6">
          <table class="table table-responsive-sm table-hover table-outline mb-0">
          <thead class="thead-light">
              <tr>
                  <th style="width:55px"></th>
                  <th>Provider</th>
                  <th>ON/OFF</th>
              </tr>
          </thead>
              <tbody>
                  <tr v-for="(prv, key) in $store.state.providers" :key="key">
                      <td class="align-middle"><img :src="prv.iconUrl" width="35px" alt=""></td>
                      <td class="align-middle font-weight-bold">{{ prv.name }}</td>
                      <td class="align-middle">
                          <label class="switch mb-0">
                              <input id="switchstatus" type="checkbox" v-model="prv.status" @change="switchButton(prv)">
                              <span class="slider round"></span>
                          </label>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
      </div>
  </div>
</template>

<script>
import {db} from '@/main'

export default {
  data(){
    return {
      loading: false
    }
  },
  methods: {
    async switchButton(provider) {
        let status = provider.status ? 'available' : 'closed'
        await db.collection('providers').doc(provider.id).update({
            status: status
        })
        console.log('updated')
    },
  },
}
</script>