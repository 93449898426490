<template>
  <div class="min-vw-100 min-vh-100 position-fixed" style="height:calc(100vh);top:0;left:0;background:rgba(0,0,0,0.7);z-index:99999;overflow:scroll;font-size:.85rem">
    <div class="w-100 bg-white p-3" style="min-height: calc(100vh - 51px)">
      <div class="d-flex justify-content-between position-fixed bg-white w-100 p-2" style="top:0;left:0;height:51px;z-index:99">
        <ul class="nav nav-pills" id="pills-tab" role="tablist">
          <li class="nav-item" v-for="pr in providers" :key="'tab_'+pr">
            <span @click="tab = pr" class="nav-link" :class="{'active' : tab == pr}">{{pr}}</span>
          </li>
        </ul>
        <div><button class="btn btn-outline-danger" @click="$store.state.chips = !$store.state.chips">X</button></div>
      </div>
      <div v-if="loading" class="d-flex justify-content-center flex-column text-center font-weight-bold" style="height: calc(100vh - 51px)">
        <div>
          <orbit-spinner
            :animation-duration="1200"
            :size="64"
            color="#ff1d5e"
            style="margin:auto"
          />
          <div class="mt-5">loading...</div>
        </div>
      </div>
      <div v-else-if="!loading && numberYesterday.length == 0" class="d-flex justify-content-center flex-column text-center font-weight-bold" style="height: calc(100vh - 51px)">
        <div>
          <img src="@/assets/img/empty.png" width="250px" alt="">
          <div class="mt-5">Kosong, kek dompet developernya...</div>
        </div>
      </div>
      <div v-else style="margin-top:56px" class="tab-content">

        <div v-for="pr in providers" :key="'list_'+pr" class="tab-pane fade" :class="{'show active' : tab == pr}" role="tabpanel" aria-labelledby="pills-home-tab">
          <list-chip :list="lists" :provider="pr" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { OrbitSpinner } from 'epic-spinners'
import {db} from '@/main'
import ListChip from './ListChip.vue'
export default {
  components: {
    OrbitSpinner, ListChip
  },
  data: () => {
    return {
      providers: ['TELKOMSEL', 'AXIS', 'THREE', 'INDOSAT'],
      loading: false,
      selectedDate: new Date(),
      logYesterday: null,
      logTime: null,
      tab: 'TELKOMSEL',
      numberYesterday: []
    }
  },
  async mounted(){
    this.shift = await this.getShift()
    this.viewData()
  },
  methods: {
    async viewData(){
      this.loading = true
      this.numberYesterday = []

      if(this.selectedDate != null){
        var yesterdayData = new Date(new Date(this.selectedDate).setDate(this.selectedDate.getDate() - 1))
        this.logTime = 'log_'+moment(this.selectedDate).format('DD_MMM_yyyy')
        this.logYesterday = 'log_'+moment(yesterdayData).format('DD_MMM_yyyy')

        await db.collection('numbers').where('identifier', '==', this.logYesterday).orderBy('order').get().then((docs) => {
          docs.docs.forEach(el => {
            this.numberYesterday.push(el.data())
          });
        })
        this.loading = false
      }
    },
  },
  computed: {
    lists: function() {
      return this.numberYesterday.filter((nmr) => {
        return nmr.provider == this.tab
      })
    }
  }
}
</script>