<template>
    <div class="min-vw-100 min-vh-100 position-fixed" style="top:0;left:0;background:rgba(0,0,0,0.7);z-index:99999">
        <div class="w-100 h-100 position-fixed" style="top:0; left:0;z-index:-1" @click="$store.state.generatereport = !$store.state.generatereport"></div>

        <div class="bg-white w-75 mx-auto mt-5 p-3 row" style='border-radius:8px'>
            <div class="form-group mr-1">
                <label for="startday">Start Day (start: 1)</label>
                <input class="form-control" v-model.number="startday">
            </div>
            <div class="form-group mr-1">
                <label for="">End Day (end: 31)</label>
                <input class="form-control" v-model.number="endday">
            </div>
            <div class="form-group mr-1">
                <label for="">Month  (0-11)</label>
                <input class="form-control" v-model.number="month">
            </div>
            <div class="form-group mr-1">
                <label for="">Year</label>
                <input class="form-control" v-model.number="year">
            </div>
            <div class="form-group">
                <label for="">.</label>
                <div class="btn btn-primary form-control" @click="loopGenerator(startday, endday)">Generate Now</div>
            </div>
            <div class="form-group">
                <label for="">.</label>
                <div class="btn btn-primary form-control" @click="cleanUp()">Clean Up</div>
            </div>
        </div>
    </div>
</template>

<script>
import {db} from '@/main'

export default {
    data(){
        return {
            startday: 0,
            endday: 0,
            month: 0,
            year: 0
        }
    },
    methods: {
        async loopGenerator(startday, endday){
            for(var i = startday; i <= endday; i++){
                await this.generateCashFlow(i, this.month);
            }
        },
        async cleanUp(){
            for(var i = this.startday; i <= this.endday; i++){
                await db.collection('reports').doc('daily').collection('list').where('year', '==', this.year).where('day', '==', i).where('month', '==', this.month).get().then((snaps) => {
                    snaps.docs.forEach(async (doc) => {
                        await db.collection('reports').doc('daily').collection('list').doc(doc.id).delete()
                        console.log('deleted :'+doc.id)
                    })
                })
            }
        },
        async generateCashFlow(day, month){
            var start = new Date(this.year, month, day);
            var end = new Date(this.year, month, day+1);
            var publish = {
                debit : 0, sales : 0, sold : 0, fee : 0, credit : 0, amount : 0, qty : 0, day : day, month : month+1
            }
            console.log(start)

            await db.collection('sells').where('created_at', '>=', start).where('created_at', '<', end).get().then((snaps) => {
                publish.sold = snaps.docs.length;
                snaps.docs.forEach((snap) => {
                    var data = snap.data()
                    publish.debit += Math.ceil(data.amount * data.rate);
                    publish.sales += data.amount;
                    publish.fee += data.fee;
                });
            });
    
            await db.collection('converts').where('created', '>=', start).where('created', '<', end).get().then((value) => {
                publish.qty = 0;
                value.docs.forEach((element) => {
                    var data = element.data()
                    if(data.status == 'selesai' || data.status == 'settlement'){
                        publish.qty++;
                        publish.amount += data.nominal;
                        publish.credit += Math.ceil(data.nominal * data.rate);
                    }
                });
            });
            await this.storeReport(publish)
            console.log(publish)
            return
        },

        async storeReport(data){
            console.log('called store')
            var rref = db.collection('reports').doc('daily').collection('list').doc();
            await rref.set({
                'id': rref.id,
                'amount': data.amount,
                'created_at': new Date(),
                'credit': data.credit,
                'day': data.day,
                'debit': data.debit,
                'fee': data.fee,
                'month': data.month,
                'qty': data.qty,
                'sales': data.sales,
                'sold': data.sold,
                'updated_at': new Date(),
                'year': this.year
            });

            console.log('updated')
            console.log(rref.id)
            return
        }
    }
}
</script>