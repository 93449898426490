<template>
  <div>
    <div class="p-3" style='border-radius:8px'>
      <div class="container">
        <div class="row">
          <div class="col-8">
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li class="nav-item">
                <span @click="tab = 'TELKOMSEL'" class="nav-link" :class="{'active' : tab == 'TELKOMSEL'}">Telkomsel</span>
              </li>
              <li class="nav-item">
                <span @click="tab = 'XL/AXIS'" class="nav-link" :class="{'active' : tab == 'XL/AXIS'}">XL/AXIS</span>
              </li>
              <li class="nav-item">
                <span @click="tab = 'INDOSAT'" class="nav-link" :class="{'active' : tab == 'INDOSAT'}">Indosat</span>
              </li>
              <li class="nav-item">
                <span @click="tab = 'THREE'" class="nav-link" :class="{'active' : tab == 'THREE'}">Three</span>
              </li>
            </ul>
            <div class="tab-content">
              <div id="rate-telkomsel" class="tab-pane fade" :class="{'show active' : tab == 'TELKOMSEL'}" role="tabpanel" aria-labelledby="pills-home-tab">
                <table class="table table-responsive-sm table-hover table-outline mb-0">
                  <thead class="thead-light">
                    <tr>
                        <th class="text-center"><i class="icon-globe"></i></th>
                        <th>Minimal</th>
                        <th>Maksimal</th>
                        <th>Rate</th>
                        <th>Action</th>
                    </tr>
                  </thead>
                    <tbody>
                      <tr v-for="(rate, index) in ratetsel" :key='index'>
                          <td class="text-center align-middle"><div class="avatar">
                              <img class="img-avatar" src="@/assets/img/provider/telkomsel-logo.png" width="60px">
                          </div></td>
                          <td class="align-middle">
                            Rp. {{currency(rate.min)}}
                          </td>
                          <td class="align-middle">
                            Rp. {{currency(rate.max)}}
                          </td>
                          <td class="align-middle">
                            {{rate.rate}}
                          </td>
                          <td v-if="!activ && !hapus" class="align-middle">
                            <button class="btn btn-primary mr-2" type="button" @click="editRate(rate)"><i class="fa fa-edit"></i> Edit</button>
                            <button class="btn btn-outline-danger" type="button" @click="hapusRate(rate)"><i class="fa fa-window-close"></i> Hapus</button>
                          </td>
                      </tr>
                    </tbody>
                </table>
              </div>
              <div class="tab-pane fade" :class="{'show active' : tab == 'XL/AXIS'}" role="tabpanel" aria-labelledby="pills-home-tab">
                <table class="table table-responsive-sm table-hover table-outline mb-0">
                  <thead class="thead-light">
                    <tr>
                        <th class="text-center"><i class="icon-globe"></i></th>
                        <th>Minimal</th>
                        <th>Maksimal</th>
                        <th>Rate</th>
                        <th>Action</th>
                    </tr>
                  </thead>
                    <tbody>
                      <tr v-for="(rate, index) in ratexlaxis" :key='index'>
                          <td class="text-center align-middle"><div class="avatar">
                              <img class="img-avatar" src="@/assets/img/provider/xl-logo.png" width="60px">
                          </div></td>
                          <td class="align-middle">
                              Rp. {{currency(rate.min)}}
                          </td>
                          <td class="align-middle">
                              Rp. {{currency(rate.max)}}
                          </td>
                          <td class="align-middle">
                              {{rate.rate}}
                          </td>
                          <td class="align-middle">
                            <button class="btn btn-primary mr-2" type="button" @click="editRate(rate)"><i class="fa fa-edit"></i> Edit</button>
                            <button class="btn btn-outline-danger" type="button" @click="hapusRate(rate)"><i class="fa fa-window-close"></i> Hapus</button>
                          </td>
                      </tr>
                    </tbody>
                </table>
              </div>
              <div class="tab-pane fade" :class="{'show active' : tab == 'INDOSAT'}" role="tabpanel" aria-labelledby="pills-home-tab">
                <table class="table table-responsive-sm table-hover table-outline mb-0">
                  <thead class="thead-light">
                    <tr>
                        <th class="text-center"><i class="icon-globe"></i></th>
                        <th>Minimal</th>
                        <th>Maksimal</th>
                        <th>Rate</th>
                        <th>Action</th>
                    </tr>
                  </thead>
                    <tbody>
                      <tr v-for="(rate, index) in rateio" :key='index'>
                          <td class="text-center align-middle"><div class="avatar">
                              <img class="img-avatar" src="@/assets/img/provider/indosat-logo.png" width="60px">
                          </div></td>
                          <td class="align-middle">
                              Rp. {{currency(rate.min)}}
                          </td>
                          <td class="align-middle">
                              Rp. {{currency(rate.max)}}
                          </td>
                          <td class="align-middle">
                              {{rate.rate}}
                          </td>
                          <td class="align-middle">
                            <button class="btn btn-primary mr-2" type="button" @click="editRate(rate)"><i class="fa fa-edit"></i> Edit</button>
                            <button class="btn btn-outline-danger" type="button" @click="hapusRate(rate)"><i class="fa fa-window-close"></i> Hapus</button>
                          </td>
                      </tr>
                    </tbody>
                </table>
              </div>
              <div id="rate-three" class="tab-pane fade" :class="{'show active' : tab == 'THREE'}" role="tabpanel" aria-labelledby="pills-home-tab">
                <table class="table table-responsive-sm table-hover table-outline mb-0">
                  <thead class="thead-light">
                    <tr>
                        <th class="text-center"><i class="icon-globe"></i></th>
                        <th>Minimal</th>
                        <th>Maksimal</th>
                        <th>Rate</th>
                        <th>Action</th>
                    </tr>
                  </thead>
                    <tbody>
                      <tr v-for="(rate, index) in ratethree" :key='index'>
                          <td class="text-center align-middle"><div class="avatar">
                              <img class="img-avatar" src="@/assets/img/provider/three-logo.png" width="60px">
                          </div></td>
                          <td class="align-middle">
                              Rp. {{currency(rate.min)}}
                          </td>
                          <td class="align-middle">
                              Rp. {{currency(rate.max)}}
                          </td>
                          <td class="align-middle">
                              {{rate.rate}}
                          </td>
                          <td class="align-middle">
                            <button class="btn btn-primary mr-2" type="button" @click="editRate(rate)"><i class="fa fa-edit"></i> Edit</button>
                            <button class="btn btn-outline-danger" type="button" @click="hapusRate(rate)"><i class="fa fa-window-close"></i> Hapus</button>
                          </td>
                      </tr>
                    </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-if="activ" class="col-4">
            <div class="card">
                <div class="card-header">Edit {{activerate.provider}}</div>
                <div class="card-body" v-if="!editsaving">
                  <select class="form-control form-control" v-model="activerate.provider" name="operator" disabled>
                    <option value="telkomsel">Telkomsel</option>
                    <option value="xl">XL</option>
                    <option value="axis">AXIS</option>
                    <option value="three">Three</option>
                  </select><br>
                  <input class="form-control form-control" type="number" name="min" v-model="activerate.min" placeholder="minimal (eg. 25000)"><br>
                  <input class="form-control form-control" type="number" name="max" v-model="activerate.max" placeholder="maksimal (eg. 99999)"><br>
                  <input class="form-control form-control" type="number" name="rate" v-model="activerate.rate" placeholder="rate (eg. 0.8)"><br>
                  <button class="form-control form-control btn btn-primary mb-2" @click="saveEdit(activerate)"><font-awesome-icon icon="check" :style="{color: 'white', marginRight: '12px'}" />Simpan</button>
                  <button class="form-control form-control btn btn-danger" @click="activ = false">Batal</button>
                </div>
                <div class="card-body" v-else>
                  <div class="mt-3">
                    <orbit-spinner
                      :animation-duration="1200"
                      :size="55"
                      color="#ff1d5e"
                      style="margin:auto"
                    />
                    <p class="font-italic font-weight-bold small mt-4 text-center">Sedang menyimpan...</p>
                  </div>
                </div>
            </div>
          </div>
          <div v-if="hapus" class="col-4">
            <div class="card">
                <div class="card-header">Hapus rate {{activerate.provider}}</div>
                <div class="card-body" v-if="!deleting">
                  <h5>Min: {{currency(activerate.min)}}</h5>
                  <h5>Max: {{currency(activerate.max)}}</h5>
                  <h5>Rate: {{activerate.rate}}</h5>

                  <button class="form-control form-control btn btn-danger mb-2 mt-5" @click="rateHapus()"><font-awesome-icon icon="check" :style="{color: 'white', marginRight: '12px'}" />Hapus</button>
                  <button class="form-control form-control btn btn-secondary" @click="hapus = false">Batal</button>
                </div>
                <div class="card-body" v-else>
                  <div class="mt-3">
                    <orbit-spinner
                      :animation-duration="1200"
                      :size="55"
                      color="#ff1d5e"
                      style="margin:auto"
                    />
                    <p class="font-italic font-weight-bold small mt-4 text-center">Sedang menyimpan...</p>
                  </div>
                </div>
            </div>
          </div>
          <div v-if="!activ && !hapus" class="col-4">
            <div class="card">
                <div class="card-header">Tambah</div>
                <div class="card-body" v-if="!addsaving">
                  <select class="form-control form-control" v-model="addProvider" name="operator">
                    <option value="telkomsel">Telkomsel</option>
                    <option value="xl">XL</option>
                    <option value="axis">AXIS</option>
                    <option value="three">Three</option>
                  </select><br>
                  <input class="form-control form-control" type="number" name="min" v-model="addMin" placeholder="minimal (eg. 25000)"><br>
                  <input class="form-control form-control" type="number" name="max" v-model="addMax" placeholder="maksimal (eg. 99999)"><br>
                  <input class="form-control form-control" type="number" name="rate" v-model="addcRate" placeholder="rate (eg. 0.8)"><br>
                  <button class="form-control form-control btn btn-primary" @click="addRate()"><font-awesome-icon icon="check" :style="{color: 'white', marginRight: '12px'}" />Tambahkan</button>
                </div>
                <div class="card-body" v-else>
                  <div class="mt-3">
                    <orbit-spinner
                      :animation-duration="1200"
                      :size="55"
                      color="#ff1d5e"
                      style="margin:auto"
                    />
                    <p class="font-italic font-weight-bold small mt-4 text-center">Sedang menyimpan...</p>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { OrbitSpinner } from 'epic-spinners'
import {db} from '../../main'
export default {
  name: 'Rate',
  components: {
    OrbitSpinner
  },
  data: () => {
    return {
      tab: 'TELKOMSEL',
      rates: null,
      activ: false,
      saving: false,
      hapus: false,
      deleting: false,
      activerate: {
        id: null,
        provider: null,
        min: null,
        max: null,
        rate: null
      },
      //add rate,
      addProvider: null,
      addMin: null,
      addMax: null,
      addcRate: null,
      addsaving: false,
      editsaving: false
    }
  },
  methods: {
    currency(value){
      let val = (value/1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    editRate(rate){
      this.activerate = rate
      this.activ = true
    },
    hapusRate(rate){
      this.activerate = rate
      this.hapus = true
    },
    emptyActive(){
      this.activerate.id = null,
      this.activerate.provider = null,
      this.activerate.min = null,
      this.activerate.max = null,
      this.activerate.rate = null
    },
    addRate(){
      var vm = this
      if(this.addProvider != null && this.addMin != null && this.addMax != null & this.addcRate != null){
        vm.addsaving = true
        var DocRef = db.collection('rates-special').doc();
        DocRef.set({
          id: DocRef.id,
          provider: vm.addProvider,
          min: parseInt(vm.addMin),
          max: parseInt(vm.addMax),
          rate: parseFloat(vm.addcRate)
        }).then(function(){
          vm.addsaving = false
        })
      }
    },
    saveEdit(rate){
      this.saving = true
      var vm = this

      db.collection('rates-special').doc(rate.id).update({
        min: parseInt(vm.activerate.min),
        max: parseInt(vm.activerate.max),
        rate: parseFloat(vm.activerate.rate)
      }).then(function(){
        vm.activ = false
        vm.saving = false
      })
    },
    rateHapus(){
      var vm = this
      this.deleting = true
      
      db.collection('rates-special').doc(this.activerate.id).delete().then(function(){
        vm.hapus = false
        vm.deleting = false
      })
    },
  },
  computed: {
    ratetsel: function() {
      return this.rates.filter(function(rate){
        return rate.provider == 'telkomsel'
      })
    },
    ratexlaxis: function() {
      return this.rates.filter(function(rate){
        return rate.provider == 'xl/axis'
      })
    },
    rateio: function() {
      return this.rates.filter(function(rate){
        return rate.provider == 'indosat'
      })
    },
    ratethree: function() {
      return this.rates.filter(function(rate){
        return rate.provider == 'three'
      })
    },
  },
  firestore () {
    return {
      rates: db.collection('rates-special').orderBy('min', 'asc')
    }
  }
}
</script>