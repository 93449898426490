<template>
    <div class="min-vw-100 min-vh-100 position-fixed" style="top:0;left:0;background:rgba(0,0,0,0.7);z-index:99999">
        <div class="w-100 h-100 position-fixed" style="top:0; left:0;z-index:-1" @click="$store.state.settings.schedulev2 = !$store.state.settings.schedulev2"></div>
        <div class="bg-white mx-auto mt-3 p-3 row" style='border-radius:8px; width: 95%; height:calc(100vh - 2rem); overflow:scroll'>
            <div class="mb-4">
                <div class="d-flex justify-content-between">
                    <div class="d-flex">
                        <div class="sche schedule-cs mr-2">Customer Service</div>
                        <div class="sche schedule-finance mr-2">Finance</div>
                        <div class="sche schedule-seller">Seller</div>
                    </div>
                    <div class="d-flex">
                        <div @click="shift = 'pagi'" :class="shift == 'pagi' ? 'active-shift' : ''" class="shift-picker mr-2">Pagi</div>
                        <div @click="shift = 'siang'" :class="shift == 'siang' ? 'active-shift' : ''" class="shift-picker mr-2">Siang</div>
                        <div @click="shift = 'malam'" :class="shift == 'malam' ? 'active-shift' : ''" class="shift-picker">Malam</div>
                    </div>
                    <div class="report-header" style="width: fit-content">
                        <Datepicker input-class="form-control" default-panel="month" format="MMMM yyyy" minimum-view="month" v-model="date" />
                    </div>
                </div>
                <div class="d-inline-block position-relative mb-3" v-for="(day, cidx) in daysName" :key="'dayName_'+cidx">
                    <div class="day-name-block" style="margin:4px">{{day}}</div>
                </div>
                <div class="d-inline-block position-relative" v-for="(cntr, cidx) in skippedDay()" :key="'skip_'+cidx">
                    <div class="skip-schedule-block" style="margin:4px"></div>
                </div>
                <div class="d-inline-block position-relative cursor-pointer" v-for="(cntr, cidx) in daysInMonth" :key="'current_'+cidx">
                    <div class="position-absolute" style="top:8px;right:12px; font-size:0.75rem">{{cntr}}</div>
                    <div class="position-absolute" style="top: 24px; left: 12px; width:calc(100% - 24px)">
                        <div @click="checkSchedule(cntr, 'customer-service')" class="schedule-cs w-100" style="min-height:24px">
                            <transition-group name="fade">
                                <div :class="usr.user == user.cs ? 'bg-primary text-white font-weight-bold px-3' : ''" class="w-50 d-inline-block text-center" v-for="(usr, idx) in findSchedule(cntr, 'customer-service')" :key="'user_'+idx" style="font-size: 0.9rem">
                                {{usr.user}}
                                </div>
                            </transition-group>
                        </div>
                        <div @click="checkSchedule(cntr, 'finance')" class="schedule-finance w-100 mt-1 p-1" style="min-height:24px">
                            <transition-group name="fade">
                                <div :class="usr.user == user.cs ? 'bg-primary text-white font-weight-bold px-3' : ''" class="w-50 d-inline-block text-center" v-for="(usr, idx) in findSchedule(cntr, 'finance')" :key="'user_'+idx" style="font-size: 0.9rem">{{usr.user}}</div>
                            </transition-group>
                        </div>
                        <div @click="checkSchedule(cntr, 'seller')" class="schedule-seller w-100 mt-1 p-1" style="min-height:24px">
                            <transition-group name="fade">
                                <div :class="usr.user == user.cs ? 'bg-primary text-white font-weight-bold px-3' : ''" class="w-50 d-inline-block text-center" v-for="(usr, idx) in findSchedule(cntr, 'seller')" :key="'user_'+idx" style="font-size: 0.9rem">
                                {{usr.user}}
                                </div>
                            </transition-group>
                        </div>
                    </div>
                    <div class="schedule-block sch-v2" :class="findLibur(cntr).length == 0 ? 'bg-full' : ''" style="margin:4px"></div>
                </div>
                <div class="text-small">* Klik pada tanggal untuk memilih libur/membatalkannya</div>
            </div>
        </div>
    </div>
</template>

<script>
import {db} from '@/main'
import moment from 'moment'
import firebase from 'firebase/app'
import 'firebase/auth'
import Datepicker from 'vuejs-datepicker';

export default {
    components: {
        Datepicker
    },
    data(){
        return {
            date: new Date(),
            currentMonth: moment().format('MMMM YYYY'),
            daysInMonth: (new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1, 0).getDate()),
            daysName: ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'],
            shift: ''
        }
    },
    created(){
        this.startingDay()
    },
    methods:{
        skippedDay(){
            var date = this.date
            date.setDate(1)
            var day = date.getDay()
            var length = []
            for(var i = 0; i < day ; i++){
                length.push(i)
            }
            return length
        },
        startingDay(){
            var days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
            var date = this.date
            var day = days[date.getDay()]
            return day
        },
        async checkSchedule(day, position){
            var isExist = false
            this.fschles.forEach(async (sch) => {
                if(sch.month == this.date.getMonth() && day == sch.day && sch.user == this.user.cs){
                    isExist = true
                    await this.unsetSchedule(sch.id)
                }
            })
            if(isExist) return;
            if(this.shift == ''){ alert('Pilih shift dulu bro'); return }
            await this.setJadwal(day, position)
        },
        async unsetSchedule(id){
            await db.collection('pre-schedules').doc(id).delete()
        },
        async setJadwal(day, position){
            var ref = db.collection('pre-schedules').doc();
            var schedule = {
                'id': ref.id,
                'user': this.user.cs,
                'uid': this.user.uid,
                'position': position,
                'shift': this.shift,
                'day': day,
                'month': this.date.getMonth(),
                'year': this.date.getFullYear(),
                'created_at': new Date()
            }
            await ref.set(schedule)
        },
        findSchedule(day, position){
            var libur = []
            this.fschles.forEach((sch) => {
                if(day == sch.day && sch.month == this.date.getMonth() && sch.year == this.date.getFullYear() && position == sch.position){
                    libur.push(sch)
                }
            })
            return libur
        },
        findLibur(day){
            var libur = []
            this.fschles.forEach((sch) => {
                if(day == sch.day && sch.month == this.date.getMonth() && sch.year == this.date.getFullYear() && sch.user == this.user.cs){
                    libur.push(sch)
                }
            })
            return libur
        }
    },
    computed: {
        fschles: function(){
            return this.schedules.filter(sc => {
                return sc.shift == this.shift
            })
        }
    },
    firestore(){
        return {
            schedules: db.collection('pre-schedules'),
            user: db.collection('users').doc(firebase.auth().currentUser.uid)
        }
    }
}
</script>