<template>
    <div class="container">
        <div class="row" style="margin-bottom:58px">
            <div class="col-12" style="margin-top:16px">
                <table>
                    <thead>
                        <tr>
                            <th>CS</th>
                            <th class="text-right">Pulsa</th>
                            <th class="text-right">Transaksi</th>
                            <th class="text-right">Pengeluaran</th>
                            <th class="text-right">Saldo Valid</th>
                            <th class="text-right">Saat Ini</th>
                        </tr>
                    </thead>
                    <tbody> 
                        <tr v-for="(cs, idx) in csList" :key=idx >
                            <td>{{cs.name}}</td>
                            <td>{{currency(cs.pulsa)}}</td>
                            <td>{{currency(cs.count)}}</td>
                            <td>{{currency(cs.credit)}}</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import {db} from '@/main'
export default {
    data(){
        return {
            start: new Date(new Date().getFullYear()+'-'+(new Date().getMonth()+1)+'-'+new Date().getDate() +' 00:00')
        }
    },
    computed: {
        csList(){
            let users = this.css
            users.forEach((us) => {
                users[users.indexOf(us)].pulsa = 0
                users[users.indexOf(us)].credit = 0
                users[users.indexOf(us)].count = 0
            })
            this.logs.forEach(element => {
                users.forEach((usr, index) => {
                    if(element.admin == usr.name && (element.status == 'selesai' || element.status == 'settlement')){
                        users[index].pulsa += element.nominal
                        users[index].credit += element.nominal * element.rate + element.fee
                        users[index].count ++
                    }
                });
            });
            return users
        },
    },
    firestore(){
        return {
            logs: db.collection('converts').where('created', '>=', this.start),
            css: db.collection('teams')
        }
    }
}
</script>