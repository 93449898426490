<template>
    <div style="font-size:0.9rem">
        <Navigation />
        <CareLeftbar :cares=cares />

        <FAQ v-if="$store.state.settings.faq" />
        <black-list v-if="$store.state.settings.blacklist"></black-list>

        <transition name='fade'><CarePicker v-if="user.cs == null" /></transition>

        <div v-if="activeCare != null" class="maintrx mt-5" style="transition-duration:300ms" :style="$store.state.cs.listNumbers ? 'margin-right:250px' :''">
            <div class="row">
                <ChatCare :transaction="activeCare" />
                <div class="col-md-5 col-sm-12 mb-5">
                    <div class="card mt-1">
                        <div class="card-body">
                            <span>{{activeCare.cs}}</span> (<span>{{activeCare.admin}}</span>)
                            <div v-if="activeCare.media == 'APP'">
                                <button class="btn btn-primary" @click="$store.state.ccare.cs_log_chat = true">Lihat Log Chat CS</button>
                            </div>
                            <div v-else class="badge badge-success">WhatsApp</div>
                        </div>
                    </div>
                    <div class="card mt-1">
                        <div class="card-body">
                        <table>
                            <tr v-if="activeCare.nomor_server.value != ''">
                                <td>Nomor Chip</td>
                                <td class="font-weight-bold d-flex align-items-center">
                                    <img :src="getProviderIcon(activeCare.provider)" width="28px" class="mr-1">
                                    <span>{{activeCare.nomor_server.value}} {{activeCare.nomor_server.shortcut}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>Nomor Customer</td>
                                <td class="font-weight-bold d-flex align-items-center">
                                    <img :src="getProviderIcon(activeCare.provider)" width="28px" class="mr-1">
                                    <span>{{activeCare.nomor}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" class="text-center font-weight-bold">
                                    <div class="d-flex justify-content-between align-middle">
                                    <span style="margin: auto 0; color:green">{{currency(activeCare.nominal)}} x {{activeCare.rate}} - {{currency(activeCare.fee)}}<span v-html="cbFormat(cashback(activeCare))"></span> = {{currency((activeCare.nominal * activeCare.rate - activeCare.fee + cashback(activeCare)).toFixed(0))}}</span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        </div>
                    </div>
                    <div class="card mt-1">
                        <div class="card-body">
                            <table>
                                <tr>
                                <td style="width:100px;">Bank</td>
                                <td class="font-weight-bold d-flex align-items-center">
                                    <div class="mr-3">
                                    <img :src="bankIconURL(activeCare.bank.bank)" width="24px" :alt="activeCare.bank.bank">
                                    </div>
                                    <div>{{activeCare.bank.bank}}</div>
                                </td>
                                </tr>
                                <tr>
                                <td @click="printTrx(activeCare)">Nomor</td>
                                <td class="font-weight-bold">{{activeCare.bank.norek}}</td>
                                </tr>
                                <tr>
                                <td>Nama</td>
                                <td class="font-weight-bold">{{activeCare.bank.atasnama}}</td>
                                </tr>
                            </table>
                            <div class="position-absolute" style="top:0;right:0">
                                <button v-if="!copiedRek" class="btn btn-primary py-2 px-4" @click="setCopyAble(activeCare)"><font-awesome-icon icon="copy" style="color: 'white'" /></button>
                                <button v-else class="btn btn-success py-2 px-4" ><font-awesome-icon icon="check" style="color: 'white'" /></button>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-1" v-if="isMoreThanTwoDay(activeCare)">
                        <transition name="swipeleft">
                            <div v-if="activeCare.id != null" style="right:16px;bottom:16px">
                            <div class="card shadow">
                                <div class="card-header bg-primary text-white"></div>
                                <div class="card-body" style="font-size:14px">
                                    <button @click="closeTicket(activeCare)" class="btn btn-success w-100">Sudah Selesai</button>
                                </div>
                            </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
            <input style="position:fixed;top:-100px" type="text" id="noRek" name="noRek" :value="'Bank: '+activeCare.bank.bank+'Rekening: '+activeCare.bank.norek">
        </div>

        <ChatLogCS :trx="$store.state.activeTransaction" v-if="$store.state.ccare.cs_log_chat" />

        <transition name="swiperight">
            <Chips v-if="$store.state.chips" />
        </transition>

        <transition name="fade">
            <DetailTrx v-if="$store.state.seller.detailsTrx.length > 0" />
        </transition>


    <transition name='fade'>
        <div v-if="$store.state.seller.loading" class="position-fixed w-100 h-100 d-flex" style="top:0; left:0; z-index:999999; background:rgba(0,0,0,.75)">
        <div class="card py-4 text-center w-25 m-auto">
            <fingerprint-spinner
            :animation-duration="1500"
            :size="96"
            color="#ff1d5e"
            style="margin:auto"
            />
            <div class="py-3"></div>
            <span class="font-italic">Loading...</span>
        </div>
        </div>
    </transition>
    </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import {db, dbSV} from '@/main'
import Navigation from '@/components/customerCare/Navigation.vue'
import CareLeftbar from '@/components/customerCare/Sidebar.vue'
import CarePicker from '../../components/customerCare/CarePicker.vue'
import ChatCare from '@/components/customerCare/Chatbox.vue'
import FAQ from '@/components/customerCare/FAQ'
import ChatLogCS from '@/components/customerCare/ChatLogCS'
import BlackList from '@/components/customerCare/BlackList'
import Chips from '@/components/customerCare/Chips'
import DetailTrx from '@/components/seller/DetailTrx'
import { FingerprintSpinner } from 'epic-spinners'

export default {
    components: {
        Navigation, CareLeftbar, CarePicker, ChatCare, FAQ, ChatLogCS, BlackList, Chips, DetailTrx,FingerprintSpinner
    },
    data: () => {
        return {
            copiedRek: false
        }
    },
    methods: {
        isMoreThanTwoDay(trx){
            var createdAt = trx.ticket.created_at.toDate()
            var today = new Date()
            var isMoreThan2Day = 86400000*2 < today.getTime() - createdAt.getTime()
            return isMoreThan2Day
        },
        cashback(trx){
            var cb = 0;

            if(trx.coupon != null) {
                if(trx.coupon.id != null){
                cb = trx.rate * trx.nominal * trx.coupon['value'];
                if(cb > trx.coupon['maxValue']) cb = trx.coupon['maxValue'];
                }
            }
            return cb
        },
        cbFormat(value){
            var resp = ''
            if(value > 0) resp = ` + <span style="color:blue">${this.currency(value)}</span>`
            return resp
        },
        async sendCalculated(trx){
            if(!this.sendCalculation){
                this.sendCalculation = true
                var calc = `${this.currency(trx.nominal)} x ${trx.rate} - ${this.currency(trx.fee)} = Rp ${this.currency(trx.nominal*trx.rate - trx.fee)}`

                let newData = firebase.database().ref('chatrooms/'+trx.id+'/chats').push()
                await newData.set({ type: 'newmsg', user: firebase.auth().currentUser.email, message: calc, sendDate: dbSV.TIMESTAMP })

                await db.collection('converts').doc(trx.id).update({
                chat: {'cs': 'read', 'customer': 'unread'},
                on_app: true //visible to app cs
                })
                
                setTimeout(() => { this.sendCalculation = false }, 5000)
            }
        },
        async closeTicket(trx){
            await db.collection('converts').doc(trx.id).update({
                'ticket.status': 'closed'
            });
        }
    },
    computed: {
        activeCare: function(){
        var value
        this.cares.filter(trx => {
            if(trx.id == this.$store.state.activeTransaction.id) value = trx
        })
        return value
        }
    },
    firestore(){
        return {
            user: db.collection('users').doc(firebase.auth().currentUser.uid),
            cares: db.collection('converts').where('ticket.status', '==', 'open').orderBy('ticket.updated_at', 'desc')
        }
    }
}
</script>