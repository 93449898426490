<template> 
    <div>
        <div class="p-3" style='border-radius:8px'>
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li class="nav-item">
                <span @click="tab = 'GENERAL'" class="nav-link" :class="{'active' : tab == 'GENERAL'}">GENERAL</span>
              </li>
              <li class="nav-item">
                <span @click="tab = 'PROVIDER'" class="nav-link" :class="{'active' : tab == 'PROVIDER'}">PROVIDER</span>
              </li>
              <li class="nav-item">
                <span @click="tab = 'BANK'" class="nav-link" :class="{'active' : tab == 'BANK'}">BANK</span>
              </li>
            </ul>

            <div class="tab-content">
              <div class="tab-pane fade" :class="{'show active' : tab == 'GENERAL'}">
                  <div>
                      <div @dblclick="changeChannel(ch)" class="d-inline-block cursor-pointer" v-for="ch in channels" :key="ch.id">
                          <span class="p-4 d-inline-block m-1" :style="ch.status ? 'background-color:#'+ch.color.substring(4, 10) : 'bcakground-color:grey'" style='color:white'>{{ch.name}}</span>
                      </div>
                      <div class="d-inline-block cursor-pointer">
                          <span class="p-4 d-inline-block m-1 text-white bg-primary" :class="specialRate.special_rate ? 'bg-success' : 'bg-secondary'" @dblclick="changeSpecialRate(specialRate.special_rate)">SPECIAL RATE</span>
                      </div>
                  </div>
                <table class="table table-responsive-sm table-hover table-outline mb-0">
                  <thead class="thead-light">
                    <tr>
                        <th style="width:55px"></th>
                        <th>Pengaturan</th>
                        <th>Action</th>
                    </tr>
                  </thead>
                    <tbody>
                      <tr>
                          <td></td>
                          <td class="align-middle">Pengumuman</td>
                          <td>
                              <span v-if="message.announcement == null" style="width:calc(100% - 92px);margin-right:12px;display:inline-block">loading...</span>
                              <span v-else-if="savingoffline == true" style="width:calc(100% - 92px);margin-right:12px;display:inline-block">menyimpan...</span>
                              <textarea v-else type="text" style="height:100px; width:calc(100% - 92px);margin-right:12px" v-model="message.announcement" />
                              <button class="btn btn-primary" style="font-size:12px" @click="setMessage(message.announcement)">Simpan</button>
                          </td>
                      </tr>
                    </tbody>
                </table>
              </div>
              <div class="tab-pane fade" :class="{'show active' : tab == 'PROVIDER'}">
                  <ProviderSetting />
              </div>
              <div class="tab-pane fade" :class="{'show active' : tab == 'BANK'}">
                  <div class="container">
                      <div class="row">
                        <div class="col-12">
                            <table class="table table-responsive-sm table-hover table-outline mb-0">
                            <thead class="thead-light">
                                <tr>
                                    <th style="width:55px"></th>
                                    <th>Bank</th>
                                    <th>ON/OFF</th>
                                </tr>
                            </thead>
                                <tbody>
                                    <tr v-for="(bnk, index) in $store.state.banks" :key="index">
                                      <td class="align-middle"><img :src="bnk.iconURL" width="35px" alt=""></td>
                                      <td class="align-middle font-weight-bold">{{bnk.name}}</td>
                                      <td class="align-middle">
                                          <label class="switch mb-0">
                                              <input id="switchstatus" type="checkbox" v-model="bnk.status" @change="switchStatus(bnk)">
                                              <span class="slider round"></span>
                                          </label>
                                      </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
import { db } from '../../main'
import ProviderSetting from './items/provider'

export default {
    name: 'General-Settings',
    components: {
        ProviderSetting
    },
    data: () => {
        return {
            tab: 'GENERAL',
            maintenance: {
                maintenance: false
            },
            message: {
                announcement: ''
            },
            savingoffline: false,
        }
    },
    mounted(){
        db.collection('settings').doc('announcement').get().then((value) => {
            this.message = value.data()
        })
    },
    methods: {
        async changeSpecialRate(status){
            await db.collection('settings').doc('transactions').update({
                'special_rate': !status
            })
        },
        changeChannel(ch){
            db.collection('channels').doc(ch.id).update({status: !ch.status})
        },
        maintenanceMode(set) {
            db.collection('settings').doc('fgM8BnNndtCmSQsAR7N4').update({
                maintenance: set
            }).then(function(){
                //console.log('updated maintenance mode')
            })
        },
        async switchStatus(bank) {
            await db.collection("const_banks").doc(bank.id).update({
                status: bank.status
            })
            console.log('updated')
        },
        setMessage(value){
            var vm = this
            this.savingoffline = true
            db.collection('settings').doc('announcement').update({
                announcement: value
            }).then(function(){
                vm.savingoffline = false
                console.log('success')
            })
        }
    },
    firestore () {
        return {
            channels: db.collection('channels'),
            specialRate: db.collection('settings').doc('transactions')
        }
    }
}
</script>