<template>
    <div class="creativeside">
      <div class="settings">
        <div class="setting-item" :class="{'active' : set.route == $store.state.navigation}" v-for="(set, index) in settingList" :key="index" @click="$store.state.navigation = set.route">{{set.title}}</div>
      </div>
    </div>
</template>

<script>
import firebase from "firebase/app"
import {db} from "@/main"

export default {
  name: 'finance-sidebar',
  data(){
    return {
      settingList: [
        {
          title: 'Creativeboard',
          route: 'creativeboard',
        }
      ]
    }
  },
  async mounted(){
    const privilagesRef = await db.collection('users').doc(firebase.auth().currentUser.uid).get()
    privilagesRef.data().privilages.forEach((priv) => {
      this.settingList.push({ title: priv.title, route: priv.route })
    })

    this.$store.state.navigation = this.settingList[0].route
  },
  computed: {
  },
}
</script>