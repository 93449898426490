<template>
    <div class="container-fluid">
        <div class="row mt-3    " style="margin-bottom:58px">
            <div class="col-12">
                <div class="d-flex justify-content-end">
                    <Datepicker input-class="form-control" v-model="startDate" />
                    <div class="mr-2"></div>
                    <Datepicker input-class="form-control" v-model="endDate" />
                    <button class="btn btn-outline-primary ml-3" @click="showData()">Show</button>
                </div>
            </div>
            <div class="col-12" style="margin-top:16px">
                <table>
                    <thead>
                        <tr>
                            <th>Bank</th>
                            <th v-for="i in dataLength" :key="i">
                                {{startDate.getDate()+i-1}}
                            </th>
                        </tr>
                    </thead>
                    <tbody> 
                        <tr v-for="(bank, i) in serverBanks" :key="i">
                            <td style="max-width:160px">
                                <div class="d-flex">
                                    <img :src="bankIconURL(bank.bank)" height="72px" />
                                    <div class="ml-3">
                                        <div class="small">{{bank.holder}}</div>
                                        <div class="font-weight-bold">{{bank.account_number}}</div>
                                        <div class="small">Rp {{currency(bank.balance)}}</div>
                                    </div>
                                </div>
                            </td>

                            <td v-for="i in dataLength" :key="i">
                                <div v-html="getBeginning(bank, startDate.getDate()+i-1, 'satupagi')"></div>
                                <div v-html="getEnding(bank, startDate.getDate()+i-1, 'satupagi')"></div>
                                <div class="py-2"></div>
                                <div v-html="getBeginning(bank, startDate.getDate()+i-1, 'satusiang')"></div>
                                <div v-html="getEnding(bank, startDate.getDate()+i-1, 'satusiang')"></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import {db} from '@/main'
import firebase from 'firebase/app'
import 'firebase/auth'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker';

export default {
    name:'finances',
    components: {Datepicker},
    data(){
        return {
            startDate: new Date(Date.now() - 345600000),
            endDate: new Date(),
            logTime: ''
        }
    },
    mounted(){
        this.showData()
    },
    methods: {
        showData(){
            if(this.selectedDate != null){
                this.logTime = 'FNC'+moment(this.selectedDate).format('DDMMMyyyy')
            }
        },
        getBeginning(bank, date, shift){
            var dt = date
            if(date < 10){
                dt = '0'+date
            }
            var value = '-'
            this.financesDaily.forEach(el => {
                if(el.server_bank_id == bank.id && el.identifier == 'FNC'+dt+moment(this.selectedDate).format('MMMyyyy') && shift == el.shift){
                    value = `<span class="small d-block text-secondary">Beginning:</span> <span class="font-weight-bold">${this.currency(el.beginning)}</span>`
                }
            });
            return value
        },
        getEnding(bank, date, shift){
            var dt = date
            if(date < 10){
                dt = '0'+date
            }
            var value = '-'
            this.financesDaily.forEach(el => {
                if(el.server_bank_id == bank.id && el.identifier == 'FNC'+dt+moment(this.selectedDate).format('MMMyyyy') && shift == el.shift){
                    value = `<span class="small d-block mt-1 text-secondary">Ending:</span> <span class="font-weight-bold">${this.currency(el.ending)}</span>`
                }
            });
            return value
        }
    },
    computed: {
        todayFiances: function(){
            return this.financesDaily.filter(fnc => {
                return fnc.identifier == this.logTime
            })
        },
        dataLength: function(){
            return this.endDate.getDate() - this.startDate.getDate() + 1
        }
    },
    firestore(){
        return {
            serverBanks: db.collection('server-banks').orderBy('updated_at', 'desc'),
            financesDaily: db.collection('finances-daily').orderBy('created_at'),
            user: db.collection('users').doc(firebase.auth().currentUser.uid)
        }
    }
}
</script>