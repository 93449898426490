import Vue from 'vue'
import {db} from '@/main'

Vue.mixin({
    methods: {
        getTeams(){
            db.collection('teams').get().then((snaps) => {
                this.$store.state.teams = []
                snaps.docs.forEach((element) => {
                    this.$store.state.teams.push(element.data())
                });
            })
        },
        getBanks(){
            db.collection('const_banks').get().then((snaps) => {
                this.$store.state.banks = []
                snaps.docs.forEach((bnk) => {
                    let theBank = bnk.data()
                    theBank.id = bnk.id
                    this.$store.state.banks.push(theBank)
                })
            })
        },
        getProviders(){
            db.collection('providers').get().then((snaps) => {
                this.$store.state.providers = []
                snaps.docs.forEach((prov) => {
                    let theProvider = prov.data()
                    theProvider.id = prov.id
                    this.$store.state.providers.push(theProvider)
                })
            })
        },
        
    },
    mounted(){
        this.getTeams(),
        this.getBanks(),
        this.getProviders()
    }
})