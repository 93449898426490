<template>
    <div>
        <table>
            <thead>
                <th>Preview</th>
                <th>Title</th>
                <th>URL</th>
                <th>Status</th>
                <th>Action</th>
            </thead>
            <tbody>
                <tr>
                    <td colspan="5" class="text-right">
                        <button class="btn btn-primary" @click="onPickFile()">Add Promotion</button>
                        <input
                            type="file"
                            style="display: none"
                            ref="fileInput"
                            accept="image/*"
                            @change="onFilePicked"/>
                    </td>
                </tr>
                <tr v-for="promo in promotions" :key="promo.id">
                    <td><img :src="promo.image" style="max-width:160px" /></td>
                    <td><input type="text" v-model="promo.title" class="w-100" /></td>
                    <td><input type="text" v-model="promo.uri" class="w-100" /></td>
                    <td>
                        <label class="switch mb-0">
                              <input id="switchstatus" type="checkbox" v-model="promo.status">
                              <span class="slider round"></span>
                          </label>
                    </td>
                    <td class="d-flex flex-column">
                        <button class="d-block mb-2 btn btn-success text-small" @click="updatePromotion(promo)">Simpan</button>
                        <button @click="deletePromotion(promo)" class="d-block btn btn-outline-danger text-small">Hapus</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import {db, fv} from "@/main"
import firebase from 'firebase/app'
import 'firebase/storage'
const uuidv1 = require('uuid/v1');

export default {

    methods: {
        async deletePromotion(promo){
            if(confirm(`Yakin hapus carousel ${promo.title}? Mending di nonaktifkan saja.`) == true){
                await db.collection('articles').doc(promo.id).delete()
                alert('Berhasil dihapus')
            }
        },
        async updatePromotion(promo){
            await db.collection('articles').doc(promo.id).update({
                uri: promo.uri,
                title: promo.title,
                status: promo.status
            })
            alert('Berhasil di update')
        },
        onPickFile () {
            this.$refs.fileInput.click()
        },
        onFilePicked (event) {
            const files = event.target.files
            //let filename = files[0].name
            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.imageUrl = fileReader.result
            })
            fileReader.readAsDataURL(files[0])
            this.file = files[0]

            if(files[0] != null){
                var re = /(?:\.([^.]+))?$/;
                var file = files[0];
                var namafile = uuidv1() + '.' + re.exec(file.name)[1];

                const storageRef = firebase.storage().ref('articles/' + namafile).put(file);
                storageRef.on(`state_changed`,snapshot=>{
                this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
                }, error=>{console.log(error.message)},
                ()=>{this.uploadValue=null;
                    storageRef.snapshot.ref.getDownloadURL().then( async (url)=>{
                        let docRef = db.collection('articles').doc()
                        await docRef.set({
                            id: docRef.id,
                            category: 'promotion',
                            created_at: fv.serverTimestamp(),
                            image: url,
                            uri: '',
                            title:'',
                            status: false
                        })
                        console.log(url)
                    })
                });
            }
        }
    },
    firestore(){
        return {
            promotions: db.collection('articles').orderBy('status', 'desc')
        }
    }
}
</script>