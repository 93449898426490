<template>
    <div class="position-fixed w-100 h-100">
        <div v-if="$store.state.finance.inputBankStatement" class="position-fixed w-100 h-100" style="background:rgba(0,0,0,0.75); top:0; left:0" @click="!loading ? $store.state.finance.inputBankStatement = false : ''"></div>
        <div class="position-fixed card" v-if="$store.state.finance.inputBankStatement" style="left:50%; top:15%; transform:translate(-50%, 0);z-index:9999">
            <div class="card-body d-flex">
                <div class=" pr-5">
                    <div class="form-group">
                        <label class="small">Pilih <b class="text-success">Bank</b></label>
                        <div>
                            <img v-for="(bnk, i) in listBank" :key="'bnk'+i" :src="bankIconURL(bnk)" height="56px" class="btn" :class="[tempData.bank == bnk ? 'btn-primary' : '']" @click="setData(bnk)" />
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="small">Rekening <b class="text-success">Atas Nama</b></label>
                        <div v-for="(hldr, i) in listHolder" :key="'hlr'+i" class="px-3 py-2 rounded mb-1 cursor-pointer" :class="[tempData.holder == hldr ? 'btn-primary' : 'bg-white']" @click="setData(tempData.bank, hldr)">
                            <span>{{hldr}}</span>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="small"><b class="text-success">Nomor</b> Rekening</label>
                        <div v-for="(acc, i) in listAccount" :key="'acc'+i" class="px-3 py-2 rounded mb-1 cursor-pointer" :class="[tempData == acc ? 'btn-primary' : 'bg-white']" @click="tempData = acc">
                            <span>{{acc.account_number}}</span>
                        </div>
                    </div>
                </div>
                <div>
                    <div v-if="tempData.id != null" class="pr-5 text-right">
                        <div class="form-group">
                            <label class="small" for="beginning">Saldo <b class="text-success">Mulai</b> Shift</label>
                            <input class="form-control" type="number" name="beginning" v-model.number="beginning" placeholder="(e.g 20000000)" :disabled="loading" @keypress.enter="newRecord">
                        </div>
                        <button v-if="!loading" @click="newRecord" class="btn btn-success"><font-awesome-icon icon="save" /> Simpan</button>
                        <button v-else class="btn btn-secondary"><font-awesome-icon icon="save" /> Menyimpan...</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {db} from '@/main'
import firebase from 'firebase/app'
import axios from 'axios'

export default {
    data(){
        return {
            loading: false,
            beginning: null,
            ending: '',
            notes: '',
            tempData: {
                id: null,
                bank: null,
                holder: null,
                account_number: null,
                created_at: null
            }
        }
    },
    methods: {
        setData(bnk, holder){
            this.tempData = {
                id: null,
                bank: bnk,
                holder: holder,
                account_number: null,
                created_at: null,
                updated_at: null
            }
        },
        async newRecord(){
            if(this.beginning == null){
                alert('tidak boleh kosong'); return;
            }

            this.loading = true

            var shift = await this.getShift()
            
            var data = {
                'shift': shift,
                'uid': firebase.auth().currentUser.uid,
                'beginning': this.beginning,
                'activeBank': this.tempData,
                'cs': this.user.cs,
                'updateType': 'beginning'
            };
            var config = {
                method: 'post',
                url: 'https://us-central1-bypulsa-convert-pulsa.cloudfunctions.net/financeStoreDaily',
                headers: {
                'Content-Type': 'application/json'
                },
                data : data
            };

            await axios(config).catch((error) => console.log(error));
            this.emptyData()
        },
        emptyData(){
            this.loading = false
            this.$store.state.finance.inputBankStatement = false
            this.beginning = null
            this.ending = ''
            this.notes = ''
            this.tempData = {
                id: null,
                bank: null,
                holder: null,
                account_number: null,
                created_at: null,
                updated_at: null
            }
        }
    },
    computed: {
        listBank: function(){
            var filtered_array = [];
            for(var i =0; i < this.serverBanks.length; i++) {
                if(filtered_array.indexOf(this.serverBanks[i].bank) === -1) {
                filtered_array.push(this.serverBanks[i].bank)
                }
            }
            return filtered_array;
        },
        listHolder: function(){
            var filtered_array = [];
            for(var i =0; i < this.serverBanks.length; i++) {
                if(this.tempData.bank == this.serverBanks[i].bank){
                    if(filtered_array.indexOf(this.serverBanks[i].holder) === -1) {
                        filtered_array.push(this.serverBanks[i].holder)
                    }
                }
            }
            return filtered_array;
        },
        listAccount: function(){
            var filtered_array = [];
            var finalData = [];
            for(var i =0; i < this.serverBanks.length; i++) {
                if(this.tempData.bank == this.serverBanks[i].bank && this.tempData.holder == this.serverBanks[i].holder){
                    if(filtered_array.indexOf(this.serverBanks[i].account_number) === -1) {
                        filtered_array.push(this.serverBanks[i].account_number)
                        finalData.push(this.serverBanks[i])
                    }
                }
            }
            return finalData;
        },
    },
    firestore(){
        return {
            serverBanks: db.collection('server-banks').orderBy('bank').orderBy('holder').orderBy('account_number'),
            user: db.collection('users').doc(firebase.auth().currentUser.uid)
        }
    }
}
</script>