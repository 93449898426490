<template>
  <div style="font-size:.85rem">
    <table>
      <thead>
        <tr>
          <th style="width: 36px; padding: 8px"><button class="btn text-small" @click="isDragable = !isDragable" :class="isDragable ? 'btn-success':'btn-outline-secondary'"><font-awesome-icon size="lg" icon="grip-vertical" color="#c3c3c3" /></button></th>
          <th style="width:78px">Chip</th>
          <th width="64px"><button class="btn btn-outline-secondary text-small py-1" @click="sendMultipleATCommand()">Pulsa</button></th>
          <th>Log</th>
        </tr>
      </thead>
      <draggable tag="tbody" v-model="modems" ghost-class="ghost" v-bind="dragOptions">
        <tr v-for="md in modems" :key="md.order" :class="isDragable ? 'cursor-move' : ''">
          <td class='text-center'><font-awesome-icon v-if="isDragable" size="lg" icon="grip-vertical" color="#c3c3c3" /></td>
          <td class="text-center" @click="consoleLog(md)" v-html="chipStatus(md)"></td>
          <td>
            <button v-if="md.cimi != '' && ussdPort != md.port && ussdPort != 'ALL'" class="btn btn-outline-secondary text-small py-1" @click="sendATCommand(md)">{{ussdPulsa(getCardNumber(md.cimi).provider)}}</button>
            <span class="badge badge-secondary" v-if="(ussdPort == 'ALL' && md.cimi != '') || ussdPort == md.port">sent...</span>
            </td>
          <td v-if="md.status == 'READY'" class="d-flex justify-content-between">
            <div v-if="numbers.length == 0 || md.cimi == null"></div>
            <div v-else-if="getCardNumber(md.cimi) != ''"><span v-html="getLastLog(md)"></span></div>
            <div style="width:300px" v-else-if="md.cimi != ''">
              <button class="button btn-primary text-small" @click="setChip(md)">Set Chip</button>
              <span class="ml-3">{{md.cimi}}</span>
            </div>
            <div><button class="btn btn-outline-secondary text-small py-1" @click="logPort = md">logs</button></div>
          </td>
          <td v-else v-html="portStatus(md)"></td>
        </tr>
      </draggable>
    </table>
    <LoadingOverlay v-if="loading" />

    <div v-if="activePort.id != null" class="position-fixed vw-100 vh-100" style="top:0;left:0">
      <div @click="activePort = {}" class="vw-100 vh-100" style="background-color:rgba(0,0,0,0.8)"></div>
      <div class="position-absolute w-100 bg-white p-4 shadow-lg" style="top:0;left:0">
        <div class="form-group mr-3">
          <label for="">Set Chip ke <span class="font-weight-bold">{{activePort.id}}</span></label>
          <input class="form-control" contenteditable="true" data-tab="6"
            v-model="serverNumber"
            placeholder="pilih chip => eg. ax9"
            autofocus
            id="chipPicker"
            ref="chipPicker"
          />
        </div>
        <div class="predefined-template _3SvgF" v-for="tmp in predefNumber" :key="tmp.key">
          <div @mouseover="setIndexOnHover(tmp)" style="cursor:pointer" class="DuUXI" :class="{'active-template' : predefNumber[activeNumber] == tmp}" @click="storeCimi(tmp, activePort.cimi)" >
            <span class="font-weight-bold">({{tmp.shortcut}})</span> {{tmp.value}}
          </div>
        </div>
      </div>
    </div>

    <div v-if="logPort.id != null" class="position-fixed vw-100 vh-100" style="top:0;left:0">
      <div @click="logPort = {}" class="vw-100 vh-100" style="background-color:rgba(0,0,0,0.8)"></div>
      <div class="position-absolute bg-white p-4 shadow-lg" style="border-radius:8px;top:5vh;left:0;width:90vw;margin-left:5vw">
        <table>
          <thead>
            <tr>
              <th style="width:78px">Chip</th>
              <th>Date</th>
              <th>Log</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="lg in actLogs" :key="lg.id">
              <td v-html="chipStatus(lg)"></td>
              <td>{{fbDateToString(lg.created_at.seconds*1000, 'LLL')}}</td>
              <td><span v-html="isFraud(lg.message)"></span>{{lg.message}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import {db} from '@/main'
import draggable from "vuedraggable";
import LoadingOverlay from '@/components/Loading'

export default {
  components: {LoadingOverlay, draggable},
  data(){
    return {
      ussdPort: '',
      isDragable: false,
      activePort: {},
      logPort: {},
      loading: false,
      activeNumber: '',
      serverNumber:'',
      numbers: [],
      logs: []
    }
  },
  created(){
    var yesterdayData = new Date(new Date().setDate(new Date().getDate() - 1))
    this.logYesterday = 'log_'+moment(yesterdayData).format('DD_MMM_yyyy')
    this.getNumbers()
    this.getLogs()
  },
  methods: {
    consoleLog(val){console.log(val)},
    ussdPulsa(provider){
      switch (provider) {
        case 'TELKOMSEL': return '*888#'
        case 'THREE': return '*123#'
        case 'XL/AXIS': case 'AXIS': case 'XL': case 'INDOSAT': return '*123#'
      }
    },
    async sendMultipleATCommand(){
      this.ussdPort = 'ALL'
      this.modems.forEach(async (mdm) => {
        if(mdm.cimi != ''){
          var command  = `AT+CUSD=1,"${this.ussdPulsa(this.getCardNumber(mdm.cimi).provider)}",15\r`
          var data = { 'port': mdm.port, 'command':command }

          var config = {
            method: 'post',
            url: 'http://127.0.0.1:9999/write',
            headers: {'Content-Type': 'application/json'},
            data : data
          };

          await axios(config)
        }
      })
      setTimeout(() => {
        this.ussdPort = ''
      }, 5000);
    },
    async sendATCommand(md){
      this.ussdPort = md.port
      var command  = `AT+CUSD=1,"${this.ussdPulsa(this.getCardNumber(md.cimi).provider)}",15\r`
      var data = { 'port': md.port, 'command':command }

      var config = {
        method: 'post',
        url: 'http://127.0.0.1:9999/write',
        headers: {'Content-Type': 'application/json'},
        data : data
      };

      await axios(config)
      setTimeout(() => {
        this.ussdPort = ''
      }, 5000);
    },
    async getLogs(){
      db.collection('modem_logs').orderBy('created_at', 'desc').limit(1000).onSnapshot((snap) => {
        this.logs = []
        snap.docs.forEach(doc => {
          this.logs.push(doc.data())
          if(doc.data().cimi == null){
            this.modems.forEach(element => {
              if(element.port == doc.data().port){
                db.collection('modem_logs').doc(doc.data().id).update({cimi: element.cimi})
              }
            });
          }
        })
      })
    },
    isFraud(msg){
      var fraud = ''
      var sender = ''
      var regex = msg.match('CMT: "(.*)",,')
      if(regex != null){
        sender = regex[1]
        if(sender.length > 7) fraud = `<span class="badge badge-danger text-small mr-2">PENIPUAN</span>`
      }
      return `${fraud} ${sender}`
    },
    getLastLog(md){
      var resp = ''
      var lists = []
      this.logs.forEach((log)=> {
        if(log.cimi == md.cimi) lists.push(log)
      })
      if(lists.length > 0){
        var last = lists[0]
        console.log(last)
        var pulsaMasukReg = last.message.match('CMT: "(.*)",,')
        var fraud = ''
        if (pulsaMasukReg != null) {
          var sender = pulsaMasukReg[1]
          var indexSender = last.message.indexOf(sender)
          if(sender.length > 7) fraud = `<span class="badge badge-danger text-small mr-2">PENIPUAN</span>`
          
          var msg = last.message.substring(indexSender+sender.length + 27, last.message.length)
          resp = `<div class="font-weight-bold">${fraud}${sender}</div><div>${msg}<div><div class="text-muted text-small">${this.fbDateToString(last.created_at.seconds*1000, 'LLL')}</div>`
        }

        var checkPulsaRegTsel = last.message.match('CUSD: 1,"(.*)')
        if(checkPulsaRegTsel != null) resp = `<div>${fraud}${checkPulsaRegTsel[1] }</div><div class="text-muted text-small">${this.fbDateToString(last.created_at.seconds*1000, 'LLL')}</div>`
        var checkPulsaRegThree = last.message.match('CUSD: 2,"(.*)",15')
        if(checkPulsaRegThree != null) resp = `<div>${fraud}${checkPulsaRegThree[1]}</div><div class="text-muted text-small">${this.fbDateToString(last.created_at.seconds*1000, 'LLL')}</div>`
      }
      return resp
    },
    setChip(md){
      this.activePort = md;
      setTimeout(() => {
        document.getElementById("chipPicker").focus()
      }, 300);
    },
    chipStatus(md){
      var phone = {}
      if(md.cimi != null && md.cimi != '') phone  = this.getCardNumber(md.cimi)
      else phone = {shortcut: ''}
      switch (md.status) {
        case 'READY': return `<span class="badge badge-success" style="font-size:.8rem">${md.port} ${phone.shortcut}</span>`
        case 'CABUT PASANG LAGI': return `<span class="badge badge-secondary">${phone.shortcut}</span>`
        default: return `<span class="badge badge-secondary">${phone.shortcut}</span>`
      }
    },
    portStatus(md){
      switch (md.status) {
        case 'READY': return `<span class="badge badge-success" style="font-size:.8rem">${md.status}</span>`
        case 'CABUT PASANG LAGI': return `<span class="badge badge-secondary">${md.status}</span>`
      }
    },
    getNumbers(){
      db.collection('numbers').where('identifier', '==', this.logYesterday).orderBy('order').onSnapshot((snaps) => {
        this.numbers = []
        snaps.forEach(element => {
          this.numbers.push(element.data())
        });
      })
    },
    getCardNumber(cimi){
      var phone = ''
      if(cimi != null){
        this.numbers.forEach(element => {
          if(element.cimi == cimi) phone = element
        });
      }
      return phone
    },
    setIndexOnHover(data){
      this.predefNumber.forEach((def, index) => {
        if(def == data){
          this.activeNumber = index
        }
      });
    },
    async storeCimi(number, cimi){
      this.loading = true
      await db.collection('numbers').doc(number.id).update({cimi: cimi})
      this.activeNumber = ''
      this.serverNumber = ''
      this.loading = false
      this.activePort = {}
      this.getNumbers
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    }
  },
  computed: {
    predefNumber: function(){
      return this.numbers.filter(tmp => {
        return this.serverNumber.length >= 3 && tmp.shortcut.includes(this.serverNumber.toUpperCase()) || this.serverNumber.length >= 3 && tmp.value.includes(this.serverNumber.toUpperCase())
      })
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.isDragable,
        ghostClass: "ghost"
      };
    },
    actLogs: function(){
      return this.logs.filter(lg => {
        return lg.cimi == this.logPort.cimi
      })
    }
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    }
  },
  firestore(){
    return {
      modems: db.collection('modems').orderBy('order')
    }
  }
}
</script>

<style scoped>
 td{
   padding: 4px 8px;
 }
 .button{
   padding: 2px 6px;
    border-radius: 4px;
 }
 .cursor-move {
  cursor: move;
}
.cursor-move i {
  cursor: pointer;
}
</style>