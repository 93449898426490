<template>
  <div class="mr-3">
    <img v-if="$props.provider.toUpperCase() == 'TELKOMSEL'" src="@/assets/img/provider/telkomsel-logo.png" :height="$props.height" alt="">
    <img v-else-if="$props.provider.toUpperCase() == 'THREE'" src="@/assets/img/provider/three-logo.png" :height="$props.height" alt="">
    <img v-else-if="$props.provider.toUpperCase() == 'XL'" src="@/assets/img/provider/xl-logo.png" :height="$props.height" alt="">
    <img v-else-if="$props.provider.toUpperCase() == 'AXIS'" src="@/assets/img/provider/axis-logo.png" :height="$props.height" alt="">
    <img v-else-if="$props.provider.toUpperCase() == 'SHOPEE'" src="@/assets/img/shopee.png" :height="$props.height" alt="">
    <img v-else-if="$props.provider.toUpperCase() == 'WHATSAPP'" src="@/assets/img/whatsapp.png" :height="$props.height" alt="">
  </div>
</template>

<script>
export default {
  props: {
    provider: String,
    height: String
  }
}
</script>