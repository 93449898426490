<template> 
    <div class="min-vw-100 min-vh-100 position-fixed" style="top:0;left:0;background:rgba(0,0,0,0.7);z-index:99999">
        <div class="w-100 h-100 position-fixed" style="top:0; left:0;z-index:-1" @click="$store.state.settings.kalkulator = !$store.state.settings.kalkulator"></div>
        <div class="bg-white w-75 mx-auto mt-5 p-3 row" style='border-radius:8px'>
            <div class="col-md-6">
                <div class="bg-success text-white font-weight-bold p-1 text-center mb-2" v-if="specialRateStatus.special_rate">SPECIAL RATE ON!!!</div>
                <div class="provider d-flex">
                    <div class="provider-item small" :class="[provider == 'telkomsel' ? 'provider-active' : '']" @click="provider = 'telkomsel'; setCopy()"><img src="@/assets/img/provider/telkomsel-logo.png" width="35px" style="margin-right:0.4rem" alt="">Telkomsel</div>
                    <div class="provider-item small" :class="[provider == 'xl/axis' ? 'provider-active' : '']" @click="provider = 'xl/axis'; setCopy()"><img src="@/assets/img/provider/xl-logo.png" width="35px" style="margin-right:0.4rem" alt="">XL/Axis</div>
                    <div class="provider-item small" :class="[provider == 'three' ? 'provider-active' : '']" @click="provider = 'three'; setCopy()"><img src="@/assets/img/provider/three-logo.png" width="35px" style="margin-right:0.4rem" alt="">Three</div>
                    <div class="provider-item small" :class="[provider == 'indosat' ? 'provider-active' : '']" @click="provider = 'indosat'; setCopy()"><img src="@/assets/img/provider/indosat-logo.png" width="35px" style="margin-right:0.4rem" alt="">Indosat</div>
                </div>

                <div class="nominal form-group mt-3">
                    <input type="number" v-model.number="nominal" @keyup="setCopy()" class="form-control">
                </div>

                <div class="hasil font-weight-bold mb-5" v-if="activeRate != null"><span class="font-weight-bold">Rp {{currency(activeRate * nominal)}}</span> ({{activeRate}})</div>
            </div>
            <div class="col-md-6" v-if="activeRate != null">

                <div class="bank d-flex mb-4">
                    <div class="provider-item text-center" style="width:100px;height:45px" :class="[ bank == 'free' ? 'provider-active' : '']" @click="bank = 'free'; fee = 0; setCopy()">FREE</div>
                    <div class="provider-item text-center" style="width:100px;height:45px"  :class="[ bank == '6500' ? 'provider-active' : '']" @click="bank = '6500'; fee = 6500; setCopy()">4.000</div>
                    <div class="provider-item text-center" style="width:100px;height:45px"  :class="[ bank == '1000' ? 'provider-active' : '']" @click="bank = '1000'; fee = 1000; setCopy()">1.000</div>
                </div>
                
                <div>{{currency(nominal)}} x {{activeRate}} - {{currency(fee)}} = Rp {{currency(nominal*activeRate - fee)}}</div>
                <input style="position:fixed;top:-100px" type="text" id="calcformat" name="calcformat" :value="stringToCopy">
                <div class="my-5">
                    <button class="btn btn-primary py-3 px-3" @click="copyPerhitungan()" >COPY FORMAT</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { db } from '../main'
export default {
    name: 'Kalkulator',
    data: () => {
        return {
            provider: '',
            nominal:'',
            bank: '',
            fee: 0,
            stringToCopy: ''
        }
    },
    methods: {
        setCopy(){
            this.stringToCopy = `${this.currency(this.nominal)} x ${this.activeRate} - ${this.currency(this.fee)} = Rp ${this.currency(this.nominal*this.activeRate - this.fee)}`
        },
        copyPerhitungan() {
        var copyText = document.getElementById("calcformat");
        copyText.select();
        copyText.setSelectionRange(0, 99999)
        document.execCommand("copy");
        },
    },
    computed:{
        activeRate(){
            var active = {}
            if(this.specialRateStatus.special_rate){
                this.specialRates.filter(el =>{
                    if(this.provider == el.provider){
                        if(this.nominal >= el.min && this.nominal <= el.max) active = el
                    }
                })
            } else {
                this.rates.filter(el =>{
                    if(this.provider == el.provider){
                        if(this.nominal >= el.min && this.nominal <= el.max) active = el
                    }
                })
            }
            return active.rate
        },
    },
    firestore () {
        return {
            rates: db.collection('rates'),
            specialRates: db.collection('rates-special'),
            specialRateStatus: db.collection('settings').doc('transactions')
        }
    }
}
</script>