<template>
    <div>
        <div class="btn btn-primary" @click="$store.state.generatereport = true">Generate Report</div>
        <GenerateReport v-if="$store.state.generatereport == true" />
    </div>
</template>

<script>
import GenerateReport from '@/components/holder/GenerateReport'

export default {
    components: {
        GenerateReport
    }
}
</script>