<template>
    <div class="container">
        <div class="row" style="margin-bottom:58px">
            <div class="col-12" style="margin-top:16px">
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Fee</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody> 
                        <tr v-for="(bank, i) in banks" :key="i">
                            <td><img :src="bank.iconURL" width="48px"></td>
                            <td>
                                <div>{{ bank.name }}</div>
                                <div>
                                    <span class="badge badge-success">{{ bank.type }}</span>
                                    <span v-if="bank.bi_fast" class="badge badge-primary ml-2">Bi-Fast</span>
                                </div>
                            </td>
                            <td><input type="number" v-model.number="bank.fee"></td>
                            <td></td>
                            <td><button class="btn btn-success" @click="updateBanking(bank)">Save</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div v-if="addBank" class="position-fixed bg-white p-4 z-index-999 shadow rounded-lg" style="top:64px;z-index:9999">
            <button @click="addBank = false" class="position-absolute bg-danger text-white px-3 py-2 rounded-lg" style="top:-4px;right:-4px;">x</button>
            <div class="bg-primary text-white text-center font-weight-bold">Tambah Bank Baru</div>
            <div class="form-group my-4">
                <label for="bank_input">Nama Bank</label>
                <input type="text" class="form-control" v-model="newBank.name" placeholder="Bank Name...">
            </div>
            <div class="form-group my-4">
                <label for="keywords_input">Kata Kunci Pencarian</label>
                <input type="text" class="form-control" v-model="newBank.keywords" placeholder="Keywords A, Keyword b, c">
                <small class="form-text text-muted">Pisahkan dengan koma. (e.g bank bca, bca, bank central asia)</small>
            </div>

            <div class="form-group my-4">
                <label for="fee_input">Biaya Transfer</label>
                <input type="number" class="form-control" v-model.number="newBank.fee" placeholder="2500">
            </div>

            <div class="my-4">
                <label class="d-block" for="fee_side">Biaya Transfer dibebankan ke</label>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" id="fee_side1" type="radio" v-model="newBank.fee_side" value="client">
                    <label class="form-check-label" for="fee_side1">Customer</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" id="fee_side2" type="radio" v-model="newBank.fee_side" value="server">
                    <label class="form-check-label" for="fee_side2">Perusahaan</label>
                </div>
            </div>

            <div class="my-4">
                <label class="d-block" for="type">Type</label>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" id="type1" type="radio" v-model="newBank.type" value='bank'>
                    <label class="form-check-label" for="type1">Bank</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" id="type2" type="radio" v-model="newBank.type" value='ewallet'>
                    <label class="form-check-label" for="type2">eWallet</label>
                </div>
            </div>

            <div class="my-4">
                <label class="d-block" for="fee_side">Bi-Fast</label>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" id="bi_fast1" type="radio" v-model="newBank.bi_fast" :value=true>
                    <label class="form-check-label" for="bi_fast1">Ya</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" id="bi_fast2" type="radio" v-model="newBank.bi_fast" :value=false>
                    <label class="form-check-label" for="bi_fast2">Tidak</label>
                </div>
            </div>

            <div class="my-4">
                <img class="mx-4" v-if="newBank.iconURL != ''" :src="newBank.iconURL" width="48px">
                <button v-if="uploadValue == null && newBank.iconURL == ''" class="btn btn-outline-primary" @click="onPickFile()">Icon Bank / Gambar...</button>
                <button v-if="uploadValue == null && newBank.iconURL !== ''" class="btn btn-outline-primary" @click="onPickFile()">Change...</button>
                <div v-else class="btn btn-primary">Uploading... {{ (uploadValue*100).toFixed(0) }}%</div>
                <input
                    type="file"
                    style="display: none"
                    ref="fileInput"
                    accept="image/*"
                    @change="onFilePicked"/>
            </div>

            <div class="my-4" v-if="uploadValue == null">
                <button class="btn btn-success" @click="storeBank(newBank)">Simpan</button>
            </div>
        </div>
        <div v-else class="position-fixed" style="bottom:56px; right:16px;z-index:999"><button class="btn btn-success" style="font-size:2rem" @click="addBank = true">+</button></div>
    </div>
</template>


<script>
import {db} from '@/main'
const uuidv1 = require('uuid/v1')
import firebase from 'firebase/app'
import 'firebase/storage'

export default {
    data(){
        return {
            addBank: false,
            newBank: {
                name: '',
                bi_fast: true,
                iconURL: '',
                country: 'Indonesia',
                fee:0,
                fee_side:'server',
                keywords: '',
                status: true,
                type:'bank'
            },
            file: null,
            imageLocalURL: '',
            uploadValue: null,
        }
    },
    methods: {
        onPickFile () {
            this.$refs.fileInput.click()
        },
        onFilePicked (event) {
        const files = event.target.files
        //let filename = files[0].name
        const fileReader = new FileReader()
        fileReader.addEventListener('load', () => {
            this.imageLocalURL = fileReader.result
        })
        fileReader.readAsDataURL(files[0])
        this.file = files[0]

        if(files[0] != null){
            var re = /(?:\.([^.]+))?$/;
            var file = files[0];
            var namafile = uuidv1() + '.' + re.exec(file.name)[1];

            const storageRef = firebase.storage().ref('banking/' + namafile).put(file);
            storageRef.on(`state_changed`,snapshot=>{
            this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
            }, error=>{console.log(error.message)},
            ()=>{this.uploadValue=null;
                storageRef.snapshot.ref.getDownloadURL().then( async (url)=>{
                    this.newBank.iconURL = url
                })
            });
        }
        },
        async updateBanking(bank){
            const bnk = await db.collection('const_banks').where('name', '==', bank.name).get()
            if(bnk.docs.length == 0) return

            const id = bnk.docs[0].id
            await db.collection('const_banks').doc(id).update({
                fee: bank.fee,
                status: bank.status
            })
            alert('Berhasil disimpan')
        },
        async storeBank(newBanking){
            const keywords = newBanking.keywords.split(',');
            newBanking.keywords = keywords
            const docRef = db.collection('const_banks').doc()
            await db.collection('const_banks').doc(docRef.id).set(newBanking)
            alert('Berhasil ditambahkan')
        }
    },
    firestore(){
        return {
            banks: db.collection('const_banks').orderBy('name', 'asc')
        }
    }
}
</script>