<template>
    <div class="dashboard d-flex">
        <Navigation />
        <Masterside />
        <StatOverlay />

        <div class="main p-2" style="margin-top:51px;">
            <Dashboard v-if="$store.state.navigation == 'DASHBOARD'" />
            <Rate v-if="$store.state.navigation == 'RATE'" />
            <SpecialRate v-if="$store.state.navigation == 'SPECIAL RATE'" />
            <General v-if="$store.state.navigation == 'GENERAL'" />
            <CS v-if="$store.state.navigation == 'CS'" />
            <ServerBanks v-if="$store.state.navigation == 'SERVER BANKS'" />
            <FinancialLogs v-if="$store.state.navigation == 'FINANCIAL LOGS'" />
        </div>
    </div>
</template>

<script>
import Navigation from '@/components/navs/MasterNav'
import Masterside from '@/components/dashboard/MasterSide'
import StatOverlay from '@/components/StatOverlay'
import General from '@/components/settings/General'
import Rate from '@/components/settings/Rate'
import SpecialRate from '@/components/settings/SpecialRate'
import CS from './CustomerService'
import ServerBanks from './ServerBanks'
import Dashboard from './DashboardInfo'
import FinancialLogs from './FinancialLogs.vue'

export default {
    name: 'master-dashboard',
    components:{
        Navigation, Masterside, Rate, General, CS, StatOverlay, Dashboard, ServerBanks, FinancialLogs, SpecialRate
    },
    mounted(){
        
    }
}
</script>