<template>
  <div class="min-vw-100 min-vh-100 position-fixed" style="top:56px;left:0;background:rgba(0,0,0,0.7);z-index:9999">
      <div class="w-100 h-100 position-fixed" style="top:0; left:0;z-index:-1" @click="$store.state.settings.newtrx = !$store.state.settings.newtrx"></div>
      <div class="text-right mt-2 mb-2" style="margin-left:12.5%">
        <button class="btn btn-primary" @click="$store.state.chips = !$store.state.chips">Cek Chips</button>
      </div>
      <div v-if="!saving" class="bg-white mx-auto p-3" style='border-radius:8px; max-width: 680px'>
          <div class="row">
            <div v-if="dataSaved != ''" class="col-xs-12">
              <span class="card px-3 py-2 mb-3 bg-success text-white">{{dataSaved}}</span>
            </div>
            <div class="col-md-6 col-xs-12 mb-4">
              <div class="d-flex mb-2">
                <button class="btn w-100 mr-1" :class="$store.state.cs.newTrfMode == 'TEXTAREA' ? 'btn-primary' : 'btn-outline-primary'" @click="$store.state.cs.newTrfMode = 'TEXTAREA'">Template</button>
                <button class="btn w-100 ml-1" :class="$store.state.cs.newTrfMode == 'MANUAL' ? 'btn-primary' : 'btn-outline-primary'" @click="$store.state.cs.newTrfMode = 'MANUAL'">Manual</button>
              </div>

              <textarea v-if="$store.state.cs.newTrfMode == 'TEXTAREA'" v-model="parseData" name="parseForm" id="" style='width:100%' rows="6"></textarea>
              <div v-else>
                <div class="form-group">
                  <input name="nomor" class="form-control" v-model="nomor" @change="parsingData()" type="text" placeholder="Nomor HP">
                </div>
                <div class="form-group">
                  <input name="nominal" class="form-control" v-model.number="nominal" @change="parsingData()" type="text" placeholder="Nominal">
                </div>
                <div class="form-group">
                  <input name="rekening" class="form-control" v-model="bank" @change="parsingData()" type="text" placeholder="Bank (misal. BCA)">
                </div>
                <div class="form-group">
                  <input name="name" class="form-control" v-model="atasnama" @change="parsingData()" type="text" placeholder="Nama">
                </div>
                <div class="form-group">
                  <input name="norek" class="form-control" v-model="norek" @change="parsingData()" type="text" placeholder="Nomor Rekening">
                </div>
              </div>
              
              <div class='d-flex justify-content-between'>
                <button v-if="$store.state.cs.newTrfMode == 'TEXTAREA'" class="btn btn-primary" @click="parsingData()">Parse</button>
                <span v-if="nomor_server == ''" class="text-small text-muted ml-2">*) Format chat otomatis tercopy setelah parse</span>
                <button v-if="rate && nomor_server" class="btn btn-success" @click="simpanData()">Simpan</button>
              </div>

              <div class="my-2">
                  <button v-if="!copiedData" class="btn btn-outline-primary" @click="copyPerhitungan()" ><font-awesome-icon icon="paper-plane" style="margin-right: 8px" /> Format Chat</button>
                  <button v-else class="btn btn-success" @click="copyPerhitungan()" >Copied!</button>
              </div>
              
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="mb-2">
                <input v-if="nomor_server == ''" class="pick-server-numb" contenteditable="true" data-tab="6"
                  v-model="serverNumber"
                  @keypress.enter="nomor_server = predefNumber[activeNumber].value; actNumber = predefNumber[activeNumber]"
                  @keydown.up="numberDown()" 
                  @keydown.down="numberUp()" 
                />
                <div v-else><span v-if="$store.state.numberLists.length > 0" class="font-weight-bold">({{getId[0].shortcut}})</span> {{nomor_server}}</div>
                <div v-if="nomor_server == '' && serverNumber.length > 2">
                  <div class="predefined-template _3SvgF" v-for="tmp in predefNumber" :key="tmp.key">
                    <div @mouseover="setIndexOnHover(tmp)" style="cursor:pointer" class="DuUXI" :class="{'active-template' : predefNumber[activeNumber] == tmp}" @click="nomor_server = predefNumber[activeNumber].value; actNumber = predefNumber[activeNumber]" >
                      <span class="font-weight-bold">({{tmp.shortcut}})</span> {{tmp.value}} <span class="font-weight-bold">({{currency(tmp.nominal)}})</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex mb-2">
                  <img :src="getProviderIcon(provider)" width="64px" style="margin:auto">
                  <font-awesome-icon icon="arrow-right" size="2x" style="color: 'white'; margin: auto" />
                  <img :src="bankIconURL(bank)" width="64px" style="margin:auto">
              </div>

              <div class="card mb-2">
                <div class="card-body">
                  <div class="d-flex">
                    <div>
                      <table>
                        <tr>
                          <td>Nomor</td>
                          <td class="font-weight-bold"><input type="text" v-model="nomor" :disabled="!editing"></td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <!-- <span class="font-weight-bold" style="color:green">{{currency(nominal)}} x {{rate}} - {{currency(fee)}} = {{currency((nominal * rate - fee).toFixed(0))}}</span> -->

                            <span v-html="getCalculation(nominal, rate, bank)"></span>
                          </td>
                        </tr>
                        <tr><td colspan="2"><div class="bg-success text-white font-weight-bold p-1 text-center mb-2" v-if="specialRateStatus.special_rate">SPECIAL RATE ON!!!</div></td></tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-body">
                  <table>
                    <tr>
                      <td style="width:100px;">Bank</td>
                      <td class="font-weight-bold">{{bank}}</td>
                    </tr>
                    <tr>
                      <td>Nomor</td>
                      <td class="font-weight-bold">{{norek}}</td>
                    </tr>
                    <tr>
                      <td>Nama</td>
                      <td class="font-weight-bold">{{atasnama}}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
      </div>

      <div v-else class="position-fixed card px-4 py-3" style="left:50%; top:50%; transform:translate(-50%,-50%)">
        <orbit-spinner
          :animation-duration="1200"
          :size="36"
          color="#ff1d5e"
          style="margin:auto"
        />
        <div class="text-center font-italic mt-4" style="font-size:12px">Menyimpan...</div>
      </div>


      <transition name="swiperight">
        <Chips v-if="$store.state.chips" />
      </transition>
      <!-- hidden element -->
      <input class="position-fixed" style="top:-100px" type="text" id="calcformat" name="calcformat" :value="stringToCopy">
  </div>
</template>

<script>
import { db } from '../main'
import firebase from 'firebase/app'
import 'firebase/auth'
import { OrbitSpinner } from 'epic-spinners'
import moment from 'moment'
import Chips from '@/views/Chips'

import axios from 'axios'

export default {
  name: 'new-transaction',
  components: {
    OrbitSpinner, Chips
  },
  data(){
    return {
      dataSaved: '',
      stringToCopy: '',
      copiedData: false,
      parseData : null,
      provider: '',
      nomor: null,
      nomor_server: '',
      serverNumber: '',
      activeNumber: 0,
      nominal : null,
      bank : null,
      atasnama : null,
      norek : null,
      fee: null,
      rate: null,
      point: 0,
      editing: false,
      saving: false,
      actNumber: {},
      month: moment(new Date).format('MMM_yyyy')
    }
  },
  methods: {
    setCopy(){
      const theBank = this.getTheBank(this.bank)
      if(theBank == null) return

        //if eWallet, the fee wont be calculated from the service
        const fee = theBank.fee_side == 'server' ? theBank.fee : 0
        
        this.stringToCopy = `${this.currency(this.nominal)} x ${this.rate} - ${this.currency(fee)} = Rp ${this.currency(this.nominal*this.rate - fee)}`
        setTimeout(() => {
          this.copyPerhitungan()
        }, 200);
    },
    copyPerhitungan() {
      var copyText = document.getElementById("calcformat");
      copyText.select();
      copyText.setSelectionRange(0, 99999)
      document.execCommand("copy");

      this.copiedData = true
      setTimeout(() => {
        this.copiedData = false
      }, 3000)
    },

    getCalculation(nom, rte, bbnk){
      const theBank = this.getTheBank(bbnk)
      if(theBank == null) return

      //if eWallet, the fee wont be calculated from the service
      const fee = theBank.fee_side == 'server' ? theBank.fee : 0
      const notes = theBank.fee_side == 'server' ? `` : `<br/><span class='bg-danger text-white'>Fee langsung dari ${theBank.name}</span>`

      var theCalculation = nom * rte - fee
      const calcReturn = `<span style="margin: auto 0; color:green">${this.currency(nom)} x ${rte} - ${this.currency(fee)} = ${this.currency(theCalculation)}</span>`


      return calcReturn+notes
    },
    parsingData(){
      if(this.$store.state.cs.newTrfMode == 'TEXTAREA'){
        var nomorRegex = /NOMOR:\s(\d*)/
        var getNomor = nomorRegex.exec(this.parseData.toUpperCase())
        this.nomor  = getNomor[1]

        var nominalRegex = /NOMINAL:\s(...*)/
        var getNominal = nominalRegex.exec(this.parseData.toUpperCase())
        this.nominal = parseInt(getNominal[1].replace(/\./g, ""))

        var bankRegex = /BANK:\s(...*)\sATAS/
        var getBank = bankRegex.exec(this.parseData.toUpperCase())
        this.bank = getBank[1]

        var atasnamaRegex = /ATAS\sNAMA:\s(...*)\sNOMOR/
        var getAtasNama = atasnamaRegex.exec(this.parseData.toUpperCase())
        this.atasnama = getAtasNama[1]

        var norekRegex = /REKENING:\s(...*)/
        var getNorek = norekRegex.exec(this.parseData.toUpperCase())
        if(getNorek[1].includes(')')){
          var removeBracket = /\)\s(\w*)/
          var bracketRem = removeBracket.exec(this.parseData.toUpperCase())
          this.norek = bracketRem[1]
        } else {
          this.norek = getNorek[1]
        }
      }
      
      this.provider = this.getProvider(this.nomor)

      if(this.nominal != null && this.provider != null && this.nomor != null && this.bank){
        this.getRates()
        this.fee = this.getBankFee(this.bank)
        this.setCopy()
      }
    },
    async simpanData(){
      this.saving = true

      var shift = await this.getShift()

      var data = {
        'bank': {
          'bank': this.bank,
          'atasnama': this.atasnama,
          'norek': this.norek
        },
        'cs': firebase.auth().currentUser.email,
        'fee': this.fee,
        'nominal': this.nominal,
        'nomor': this.nomor,
        'nomor_server': this.actNumber,
        'provider': this.provider,
        'rate': this.rate,
        'admin': this.user.cs,
        'shift': shift
      }

      var config = {
        method: 'post',
        url: 'https://us-central1-bypulsa-convert-pulsa.cloudfunctions.net/createWhatsAppTransaction', // this is for production
        //url: 'http://localhost:2222/bypulsa-convert-pulsa/us-central1/createWhatsAppTransaction', //this is local test
        headers: {
          'Content-Type': 'application/json'
        },
        data : data,
      };

      axios(config).then((response) => {
        this.resetInput()
        console.log(response)
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    setIndexOnHover(data){
      this.predefNumber.forEach((def, index) => {
        if(def == data){
          this.activeNumber = index
        }
      });
    },
    resetInput(){
      this.saving = false
      this.dataSaved = 'Transaksi berhasil di simpan.';
      this.stringToCopy = '',
      this.parseData = '',
      this.provider = '',
      this.nomor = null,
      this.nomor_server = '',
      this.serverNumber = '',
      this.activeNumber = 0,
      this.nominal = null,
      this.bank = null,
      this.atasnama = null,
      this.norek = null,
      this.fee = null,
      this.rate = null,
      this.point = 0,
      this.actNumber = {},

      setTimeout(() => {
        this.dataSaved = '';
      }, 5000);
    },
    getRates(){
      var min = 99999999;
      var smallerrate

      if(this.specialRateStatus.special_rate){
        this.specialRates.forEach(el => {
          if(el.min < min && el.provider.toUpperCase().includes(this.provider.toUpperCase())){
            min = el.min;
            smallerrate = el;
          }
          if(el.provider.toUpperCase().includes(this.provider.toUpperCase()) && this.nominal >= el.min && this.nominal <= el.max){
            this.rate = el.rate
          }
        });
      } else {
        this.rates.forEach(el => {
          if(el.min < min && el.provider.toUpperCase().includes(this.provider.toUpperCase())){
            min = el.min;
            smallerrate = el;
          }
          if(el.provider.toUpperCase().includes(this.provider.toUpperCase()) && this.nominal >= el.min && this.nominal <= el.max){
            this.rate = el.rate
          }
        });
      }
      if(this.rate == null){
        this.rate = smallerrate.rate
      }
    },
    numberDown(){
      if(this.activeNumber != null && this.predefNumber.length){
        this.activeNumber -= 1
      }
    },
    numberUp(){
      if(this.activeNumber != null && this.predefNumber.length){
        this.activeNumber += 1
      }
    },
  },
  computed: {
    getId(){
      return this.$store.state.numberLists.filter(nmr => {
        return nmr.value == this.nomor_server
      })
    },
    predefNumber: function(){
      return this.$store.state.numberLists.filter(tmp => {
        return this.serverNumber.length >= 3 && tmp.shortcut.includes(this.serverNumber.toUpperCase()) || this.serverNumber.length >= 3 && tmp.value.includes(this.serverNumber.toUpperCase())
      })
    },
  },
  firestore () {
    return {
      rates: db.collection('rates'),
      specialRates: db.collection('rates-special'),
      specialRateStatus: db.collection('settings').doc('transactions'),
      user: db.collection('users').doc(firebase.auth().currentUser.uid)
    }
  }
}
</script>