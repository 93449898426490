<template>
<div>
  <SellerNav />
  <div style="z-index:99999; top:8px; right:120px" class="position-fixed d-flex">
    <Datepicker input-class="form-control" v-model="selectedDate" />
    <button class="btn btn-outline-primary ml-3" @click="viewData()">Show</button>
  </div>
  <div class="container-fluid" style="margin-top:68px;font-size:.9rem">
    <div class="row">
      <div class="col-12">
        <div class="mb-5">
          <table>
            <thead>
              <tr>
                <th>Time</th>
                <th>Buyer</th>
                <th>Chip</th>
                <th>Rate</th>
                <th>Credit Out</th>
                <th>TF Fee</th>
                <th>Bank</th>
                <th>Sent To</th>
                <th><font-awesome-icon icon="globe" /></th>
                <th>Total</th>
                <th></th>
              </tr>
            </thead>
            <tr v-if="nowStart.toString() == startMidnight.toString()">
              <td></td>
              <td><input type="text" ref="buyer" v-model="newSell.buyer" @keypress.enter="simpanData()"></td>
              <td>
                <input class="text-center" v-if="newSell.chip.length <= 10" contenteditable="true" data-tab="6"
                  v-model="newSell.chip"
                  @keypress.enter="newSell.chip = predefNumber[activeNumber]; $refs.rate.focus()"
                  @keydown.up="numberDown()" 
                  @keydown.down="numberUp()" 
                />
                <div v-else><span class="font-weight-bold">({{newSell.chip.shortcut}})</span> {{newSell.chip.value}}</div>
                <div v-if="newSell.chip.length > 2 && newSell.chip.length <= 10" @click="newSell.chip = ''" class="position-fixed" style="top:0;left:0;width:100vw;height:100vh;z-index:99;background:rgba(0,0,0,.75)"></div>
                <div v-if="newSell.chip.length > 2 && newSell.chip.length <= 10" class="position-fixed" style="top:0;left:0;width:320px;height:100vh;margin-top:51px;z-index:100;background:#f0f0f0">
                  <div class="predefined-template _3SvgF" v-for="tmp in predefNumber" :key="tmp.key">
                    <div @mouseover="setIndexOnHover(tmp)" class="DuUXI cursor-pointer" :class="{'active-template' : predefNumber[activeNumber] == tmp}" @click="newSell.chip = predefNumber[activeNumber]; $refs.rate.focus()"><span class="font-weight-bold">({{tmp.shortcut}})</span> {{tmp.value}} <span class="font-weight-bold">({{currency(tmp.nominal)}})</span></div>
                  </div>
                </div>
              </td>
              <td><input ref="rate" style="max-width:48px" type="number" v-model.number="newSell.rate" @keypress.enter="simpanData()"> %</td>
              <td><input style="width:90px" type="text" v-model.number="newSell.amount" @keypress.enter="simpanData()"></td>
              <td><input style="width:54px" type="text" v-model.number="newSell.fee"></td>
              <td>
                <select style="width:120px; height:27px" name="bank" id="bank" v-model="newSell.bank" @keypress.enter="simpanData()">
                  <option value="" disabled></option>
                  <option value="BCA">BCA</option>
                  <option value="BNI">BNI</option>
                  <option value="BRI">BRI</option>
                  <option value="MANDIRI">Mandiri</option>
                </select>
              </td>
              <td><input style="width:120px" type="text" v-model="newSell.sentTo" @keypress.enter="simpanData()"></td>
              <td></td>
              <td></td>
            </tr>
            <tr v-if="saving">
              <td colspan="11">
                <div class="py-2 d-flex align-items-center justify-content-center">
                  <fingerprint-spinner
                    :animation-duration="2000"
                    :size="32"
                    color="#ff1d5e"
                  />
                  <span class="text-small font-italic mx-3">menyimpan data...</span>
                  <fingerprint-spinner
                    :animation-duration="1000"
                    :size="32"
                    color="#ff1d5e"
                  />
                </div>
              </td>
            </tr>
            <tr v-for="(trx, index) in sells" :key="index" @dblclick="deletePop = true; wantToDel = trx">
              <td>{{fbDateToString(trx.created_at.seconds*1000, 'HH:mm:ss')}}</td>
              <td>{{trx.buyer}}</td>
              <!-- <td class="text-center"><Provider :provider="trx.media" height="36px" /></td> -->
              <td style="width:180px"><span class="font-weight-bold">({{trx.chip.shortcut}})</span> {{trx.chip.value}}</td>
              <td>{{(trx.rate * 100).toFixed(2)}}%</td>
              <td>{{currency(trx.amount)}}</td>
              <td>{{currency(trx.fee)}}</td>
              <td><img v-if="trx.bank != null" :src="bankIconURL(trx.bank.bank)" height="36px"></td>
              <td>{{trx.sentTo}}</td>
              <td class="text-center"><Provider :provider="trx.chip.provider" height="36px" /></td>
              <td>{{currency(trx.amount + trx.fee)}}</td>
              <td><button class="btn text-small btn-outline-success" @click="copyData(trx)">CP</button></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  <transition name="swiperight">
    <Chips v-if="$store.state.chips" />
  </transition>
  <transition name="swiperight">
    <ChipsFast v-if="$store.state.chipsfast" />
  </transition>

  <transition name="fade">
    <div v-if="deletePop" class="position-fixed" style="bottom:0; right:0; background:white; border-top-left-radius:8px; box-shadow: #e91e6378 -3px 0px 16px 2px">
      <h4 class="bg-danger text-white font-weight-bold px-4 py-3" style="border-top-left-radius:8px;">Hapus transaksi berikut?</h4>
      <div class="px-4 py-3">
        <div>{{wantToDel.buyer}}</div>
        <div>{{wantToDel.media}}</div>
        <div>({{wantToDel.chip.shortcut}}) {{wantToDel.chip.value}}</div>
        <div>{{(wantToDel.rate * 100).toFixed(2)}}%</div>
        <div>{{wantToDel.nominal}}</div>

        <div v-if="!deleting" class="mt-4 d-flex justify-content-between">
          <button class="btn btn-danger" @click="deleteTrx(wantToDel)">Hapus</button>
          <button class="btn btn-outline-secondary" @click="deletePop = false; wantToDel = ''">Batal</button>
        </div>

        <div v-else class="mt-4 d-flex justify-content-between">
          <fingerprint-spinner
            :animation-duration="1500"
            :size="36"
            color="#ff1d5e"
            style="margin:auto"
          />
        </div>

      </div>
    </div>
  </transition>

  <transition name="fade">
    <div class="position-fixed bg-danger text-white px-3 py-2" style="bottom:0; left:0; border-top-right-radius: 8px" v-if="error.length > 0">
      <ul v-for="(er, index) in error" :key="index">
        <li>{{er}}</li>
      </ul>
    </div>
  </transition>

  <transition name='fade'>
    <div v-if="$store.state.seller.loading" class="position-fixed w-100 h-100 d-flex" style="top:0; left:0; z-index:999999; background:rgba(0,0,0,.75)">
      <div class="card py-4 text-center w-25 m-auto">
        <fingerprint-spinner
          :animation-duration="1500"
          :size="96"
          color="#ff1d5e"
          style="margin:auto"
        />
        <div class="py-3"></div>
        <span class="font-italic">Loading...</span>
      </div>
    </div>
  </transition>

  <transition name="fade">
      <Schedule v-if="$store.state.settings.schedule" />
  </transition>
  <transition name="fade">
      <ScheduleV2 v-if="$store.state.settings.schedulev2" />
  </transition>

  <StatOverlay />
</div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import firebase from 'firebase/app'
import 'firebase/auth'
import {db} from '@/main'
import SellerNav from '@/components/navs/SellerNav'
import Provider from '@/utils/Provider'
import Chips from '@/views/seller/Chips'
import ChipsFast from '@/views/seller/ChipsFast'
import { FingerprintSpinner } from 'epic-spinners'
import Datepicker from 'vuejs-datepicker';
import StatOverlay from '@/components/StatOverlay'
import Schedule from '@/components/Schedule'
import ScheduleV2 from '@/components/ScheduleV2'

export default {
  name: 'seller-board',
  components: {
    SellerNav, Provider, Chips, ChipsFast, FingerprintSpinner, Datepicker, StatOverlay, Schedule, ScheduleV2
  },
  data(){
    return {
      deleting: false,
      error: [],
      wantToDel: '',
      deletePop: false,
      numberLists: [],
      newSell: {
        buyer: '',
        chip: '',
        media: 'WhatsApp',
        rate: null,
        bank: '',
        sentTo: '',
        amount: null,
        fee: null,
        note: ''
      },
      selectedDate: new Date(),
      logTime: 'log_'+moment(new Date).format('DD_MMM_yyyy'),
      logYesterday: null,
      nowStart: moment().startOf('day'),
      startMidnight: moment().startOf('day'),
      endMidnight: moment().endOf('day'),
      shift: 'SELL',
      activeNumber: 0,
      saving: false
    }
  },
  mounted(){
    var yesterdayData = new Date().setDate(new Date().getDate() - 1)
    this.logYesterday = 'log_'+moment(yesterdayData).format('DD_MMM_yyyy')
    //this.logTime = 'log_09_Apr_2021'
    //this.logYesterday = 'log_08_Apr_2021'
    db.collection('numbers').where('identifier', '==', this.logYesterday).orderBy('order').get().then((snaps) => {
      snaps.docs.forEach(el => {
        this.numberLists.push(el.data())
      });
    })
  },
  methods: {
    viewData(){
      if(this.selectedDate != null){
        this.startMidnight = moment(this.selectedDate).startOf('day')
        this.endMidnight = moment(this.selectedDate).endOf('day')
      }
    },
    setIndexOnHover(data){
      this.predefNumber.forEach((def, index) => {
        if(def == data){
          this.activeNumber = index
        }
      });
    },
    resetError(){
      this.saving = false
      setTimeout(() => {
        this.error = []
      }, 3000)
    },
    deleteTrx(trx){
      this.deleting = true
      var data = {
        identifier: this.logTime,
        shift: this.shift,
        trx: trx
      };
      var config = {
        method: 'post',
        url: 'https://us-central1-bypulsa-convert-pulsa.cloudfunctions.net/deleteSelling',
        headers: {
          'Content-Type': 'application/json'
        },
        data : data,
      };

      axios(config).then((response) => {
        this.deleting = false
        this.deletePop = false
        console.log(response)
      })
    },
    copyData(trx){
      this.newSell = {
        buyer: trx.buyer,
        chip: '',
        media: trx.media,
        rate: (trx.rate*100).toFixed(2),
        bank: trx.bank.bank,
        sentTo: trx.sentTo,
        amount: trx.amount,
        fee: trx.fee,
        note: trx.note
      }
    },
    async simpanData(){
      this.saving = true
      if(this.newSell.buyer == ''){
        this.error.push('Buyer harus di isi')
        this.resetError()
        return
      }
      if(this.newSell.media == ''){
        this.error.push('Media harus di isi')
        this.resetError()
        return
      }
      if(this.newSell.chip == '' || this.newSell.chip == null){
        this.error.push('Chip harus di isi')
        this.resetError()
        return
      }
      if(this.newSell.rate == '' || this.newSell.rate > 100 || this.newSell.rate < 50){
        this.error.push('Periksa kembali rate')
        this.resetError()
        return
      }
      if(this.newSell.amount == '' || this.newSell.amount == null){
        this.error.push('Masukkan jumlah pulsa')
        this.resetError()
        return
      }
      if(this.newSell.bank == '' || this.newSell.bank == null){
        this.error.push('Masuikkan tujuan bank perusahaan')
        this.resetError()
        return
      }
      if(this.newSell.fee == '' || this.newSell.fee == null){
        this.error.push('Masukkan biaya transfer')
        this.resetError()
        return
      }

      var data = {
        identifier: this.logTime,
        shift: this.shift,
        buyer: this.newSell.buyer,
        chip: this.newSell.chip,
        media: this.newSell.media,
        rate: (this.newSell.rate/100).toFixed(3),
        bank: this.newSell.bank,
        sentTo: this.newSell.sentTo,
        amount: this.newSell.amount,
        fee: this.newSell.fee,
        note: this.newSell.note,
        cs: firebase.auth().currentUser.email
      };
      var config = {
        method: 'post',
        url: 'https://us-central1-bypulsa-convert-pulsa.cloudfunctions.net/sellingOut',
        headers: {
          'Content-Type': 'application/json'
        },
        data : data,
      };

      axios(config).then((response) => {
        this.activeNumber = 0
        this.newSell = {
          buyer: '',
          chip: '',
          media: 'WhatsApp',
          rate: null,
          bank: '',
          sentTo: '',
          amount: null,
          fee: null,
          note: ''
        }
        this.$refs.buyer.focus();
        this.saving = false
        console.log(response)
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    numberDown(){
      if(this.activeNumber != null && this.predefNumber.length){
        this.activeNumber -= 1
      }
    },
    numberUp(){
      if(this.activeNumber != null && this.predefNumber.length){
        this.activeNumber += 1
      }
    },
  },
  computed: {
    sells(){
      return this.rawSells.filter(sell => {
        return sell.created_at.seconds > this.startMidnight.unix() && sell.created_at.seconds < this.endMidnight.unix()
      })
    },
    getId(){
      return this.numberLists.filter(nmr => {
        return nmr.value == this.$store.state.activeTransaction.nomor_server
      })
    },
    predefNumber: function(){
      return this.numberLists.filter(tmp => {
        return this.newSell.chip.length >= 3 && tmp.shortcut.includes(this.newSell.chip.toUpperCase()) || this.newSell.chip.length >= 3 && tmp.value.includes(this.newSell.chip.toUpperCase())
      })
    },
  },
  firestore () {
    return {
      rawSells: db.collection('sells').orderBy('created_at', 'desc').limit(550),

    }
  }
}
</script>