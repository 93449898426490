<template>
    <div>
        <table>
            <thead>
                <th>Preview</th>
                <th>URL</th>
                <th>Action</th>
            </thead>
            <tbody>
                <tr>
                    <td colspan="3" class="text-center">
                        <button class="btn btn-primary" @click="onPickFile()">Tambah Carousel</button>
                        <input
                            type="file"
                            style="display: none"
                            ref="fileInput"
                            accept="image/*"
                            @change="onFilePicked"/>
                    </td>
                </tr>
                <tr v-for="carous in carousels" :key="carous.id">
                    <td><img :src="carous.img" style="max-width:400px" /></td>
                    <td class="d-flex">
                        <input type="text" v-model="carous.uri" />
                        <button class="ml-2 btn btn-success text-small" @click="updateUri(carous)">Simpan</button>

                    </td>
                    <td> <button @click="deleteCarousel(carous)" class="btn btn-danger">Hapus</button></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/storage'
import {db} from "@/main"
const uuidv1 = require('uuid/v1');

export default {
    name:'carousel-setting',
    data(){
        return {
            imageUrl: '',
            file: null,
            uploadValue: 0
        }
    },
    methods: {
        async deleteCarousel(carousel){
            if(confirm(`Yakin hapus carousel dengan url: ${carousel.uri}`) == true){
                await db.collection('carousels').doc(carousel.id).delete()
                alert('Berhasil dihapus')
            }
        },
        async updateUri(carousel){
            await db.collection('carousels').doc(carousel.id).update({
                uri: carousel.uri
            })
            alert('Berhasil di update')
        },
        onPickFile () {
            this.$refs.fileInput.click()
        },
        onFilePicked (event) {
        const files = event.target.files
        //let filename = files[0].name
        const fileReader = new FileReader()
        fileReader.addEventListener('load', () => {
            this.imageUrl = fileReader.result
        })
        fileReader.readAsDataURL(files[0])
        this.file = files[0]

        if(files[0] != null){
            var re = /(?:\.([^.]+))?$/;
            var file = files[0];
            var namafile = uuidv1() + '.' + re.exec(file.name)[1];

            const storageRef = firebase.storage().ref('carousel/' + namafile).put(file);
            storageRef.on(`state_changed`,snapshot=>{
            this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
            }, error=>{console.log(error.message)},
            ()=>{this.uploadValue=null;
                storageRef.snapshot.ref.getDownloadURL().then( async (url)=>{
                    let docRef = db.collection('carousels').doc()
                    await docRef.set({
                        id: docRef.id,
                        img: url,
                        url: 'https://instagram.com/bypulsa'
                    })
                    console.log(url)
                })
            });
        }
        }
    },
    firestore(){
        return {
            carousels: db.collection('carousels')
        }
    }
}
</script>