<template>
  <div class="position-fixed w-100 h-100 bg-white p-3" style="top:0; left:0;z-index:9999; overflow:scroll; font-size:0.85rem">
    <table style="margin-top:56px">
      <thead>
        <tr>
          <th class="cursor-pointer" @click="orderBy = 'CREATED'">Tgl</th>
          <th class="text-center"><i class="icon-globe"></i></th>
          <th class="cursor-pointer" @click="orderBy = 'NAMA'">Nama</th>
          <th class="cursor-pointer" @click="orderBy = 'BANK'">Rekening</th>
          <th class="cursor-pointer" @click="orderBy = 'NOMINAL'">Pulsa</th>
          <th class="cursor-pointer" @click="orderBy = 'NOMOR'">Nomor</th>
          <th class="cursor-pointer" @click="orderBy = 'CHIP'">Chip</th>
          <th class="cursor-pointer" @click="orderBy = 'RATE'">Rate</th>
          <th class="cursor-pointer" @click="orderBy = 'FEE'">Fee</th>
          <th>Cash Out</th>
      </tr>
      </thead>
        <tbody>
          <tr v-for="(comp, index) in even(filteredComp)" :key="index" @dblclick="$store.state.cs.editComplete = comp">
            <td>
              <div class="font-weight-bold">{{fbDateToString(comp.created.seconds*1000, 'HH:mm')}}</div>
              <div class="text-small">{{fbDateToString(comp.created.seconds*1000, 'DD MMM YYYY')}}</div>
            </td>
            <td>
              <img v-if="comp.provider.toUpperCase() == 'TELKOMSEL'" class="img-avatar" src="@/assets/img/provider/telkomsel-logo.png" width="36px">
              <img v-if="comp.provider.toUpperCase() == 'XL' || comp.provider.toUpperCase() == 'AXIS'" class="img-avatar" src="@/assets/img/provider/axis-logo.png" width="36px">
              <img v-if="comp.provider.toUpperCase() == 'THREE'" class="img-avatar" src="@/assets/img/provider/three-logo.png" width="36px">
              <img v-if="comp.provider.toUpperCase() == 'INDOSAT'" class="img-avatar" src="@/assets/img/provider/indosat-logo.png" width="36px">
            </td>
            <td>{{comp.bank.atasnama}}</td>
            <td class="d-flex">
              <img :src="bankIconURL(comp.bank.bank)" height="32px" :alt=comp.bank.bank class="mr-3">
              <div>
                <div>{{comp.bank.bank}}</div>
                <div class="text-small">{{comp.bank.norek}}</div>
              </div>
            </td>
            <td>{{currency(comp.nominal)}}</td>
            <td>{{comp.nomor}}</td>
            <td><span class="font-weight-bold">({{comp.nomor_server.shortcut}})</span> {{comp.nomor_server.value}}</td>
            <td>{{comp.rate}}</td>
            <td><span v-if="comp.fee > 0">{{comp.fee}}</span></td>
            <td>{{currency(comp.nominal * comp.rate)}}</td>
          </tr>
        </tbody>
    </table>
    <div class="position-fixed p-1" style="top:56px; right:0"><button class="btn btn-danger" @click="$store.state.csMenuDisplay = 'otw'">X</button></div>

    <div v-if="$store.state.cs.editComplete != null">
      <EditComplete />
      <div class="w-100 h-100 position-fixed" style="top:0; left:0;background:rgba(0,0,0,0.7)" @click="emptyData()"></div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import {db} from '@/main'
import EditComplete from '@/components/cs/EditComplete'
import moment from 'moment'

export default {
  name: 'HistoryList',
  components: {
    EditComplete
  },
  data(){
    return {
      orderBy: '',
      sorting: 'ASC',
      completes: [],
      logTime: 'log_'+moment(new Date).format('DD_MMM_yyyy'),
    }
  },
  mounted(){
    db.collection('users').doc(firebase.auth().currentUser.uid).get().then((data) => {
      db.collection('converts').where('admin', '==', data.data().cs).orderBy('created_at', 'desc').limit(1000).onSnapshot((snaps) => {
        this.completes = []
        snaps.docs.forEach(el => {
          if(el.data().status == 'selesai' || el.data().status == 'settlement'){
            this.completes.push(el.data())
          }
        });
      })
    })
  },
  methods:{
    emptyData(){
      if(this.$store.state.cs.savingEdit == false){
        this.$store.state.cs.editComplete = null
      }
    },
    even: function(arr) {
      return arr.sort((a, b) => {
        if(this.sorting == 'ASC'){
          switch (this.orderBy) {
            case 'CREATED':
              return b.created - a.created;
          
            case 'NAMA':
              return ('' + a.bank.atasnama).localeCompare(b.bank.atasnama);
          
            case 'NOMINAL':
              return b.nominal - a.nominal
          
            case 'BANK':
              return ('' + a.bank.bank).localeCompare(b.bank.bank);
          
            case 'NOMOR':
              return b.nomor - a.nomor
          
            case 'CHIP':
              return b.nomor_server.shortcut - a.nomor_server.shortcut
          
            case 'RATE':
              return b.rate - a.rate
          
            case 'FEE':
              return b.fee - a.fee
          
            default:
              return b.created - a.created;
          }
        }
      });
    },
  },
  computed: {
    filteredComp(){
      return this.completes.filter(comp => {
        return comp.nomor_server != null && comp.created.seconds > this.$store.state.timestamps.yesterdayMidnight.unix()
      })
    }
  }
}
</script>