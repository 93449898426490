<template>
    <div>
        <NavigationBar />
        <CreativeSidebar />
        <div :class="!this.$isMobile() ? 'mainholder' : ''" class="p-2" style="margin-top:51px;">
            <Creativeboard v-if="$store.state.navigation == 'creativeboard'" />
            <Carousel v-if="$store.state.navigation == 'carousel'" />
            <PopupPromotion v-if="$store.state.navigation == 'popup-promotion'" />
        </div>
    </div>
</template>

<script>
import NavigationBar from '@/components/Navbar.vue'
import CreativeSidebar from '@/components/creative/sidebar.creative'

import Creativeboard from '@/views/creative/creativeboard.vue'
import Carousel from "@/views/creative/Carousel.vue"
import PopupPromotion from "@/views/creative/popup_promotion.vue"

export default {
    components: {
        NavigationBar, CreativeSidebar,
        Creativeboard, Carousel, PopupPromotion
    },
    created(){
        this.$store.state.navigation = 'finboard'
    }
}
</script>