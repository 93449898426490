<template>
  <div class="navbartop py-2 px-2 bg-white shadow-sm d-flex justify-content-between">
    <div class="d-flex justify-content-between">
      <div class="headinfo" style="cursor: pointer; z-index:1000; border-radius:4px" @click="$store.state.popMenu = !$store.state.popMenu">
        <div class="ml-2 avatar"><img src="@/assets/logo.png" style="border-radius:90px" width="100%"></div>
        <div class="displayName">
          <div class="font-weight-bold">Holder</div>
          <div class="text-small" >{{user.email}}</div>
        </div>
      </div>
      <!--
      <label class="switch mb-0 ml-3">
        <input id="switchstatus" type="checkbox" v-model="user.status" @change="changeOnlineStatus(user.status)">
        <span class="slider round"></span>
      </label>-->
    </div>
    <div>
      <transition name="swipeup">
        <div v-if="$store.state.popMenu" class="dropdown-menu dropdown-menu-left p-0 m-2 show shadow border-top-8px" style="width:250px">
          <div style="cursor:pointer" role="menuitem" @click="logOut()" class="dropdown-item py-2 pr-5">
            <span class="text-danger"><font-awesome-icon icon="sign-out-alt" class="mr-3" />Sign Out</span>
          </div>
        </div>
      </transition>
    </div>

    <transition name='fade'>
        <Rate v-if="$store.state.settings.rate" />
    </transition>

    <transition name='fade'>
        <Settings v-if="$store.state.settings.general" />
    </transition>

    <transition name='fade'>
      <div v-if="$store.state.popMenu" @click="$store.state.popMenu = false" id="blackscreen" class="min-vw-100 min-vh-100 position-fixed" style="top:0;left:0;background:rgba(0,0,0,0.7);z-index:999"></div>
    </transition>
  </div>
</template>

<script>
import {db} from '@/main'
import firebase from 'firebase/app'
import 'firebase/auth'
import Rate from '@/components/settings/Rate'
import Settings from '@/components/settings/General'
import moment from 'moment'

export default {
  name: 'Navigation',
  components:{
    Rate, Settings
  },
  data(){
    return {
      logTime: 'log_'+moment(new Date).format('DD_MMM_yyyy'),
      user: {
        email: '',
        status: false,
        type: ''
      },
    }
  },
  firestore(){
    return {
      user: db.collection('users').doc(firebase.auth().currentUser.uid)
    }
  }
}
</script>