<template>
  <div class="navbartop py-2 px-2 bg-white shadow-sm d-flex justify-content-between">
    <div class="d-flex justify-content-between">
      <div class="headinfo">
        <div class="ml-2 avatar" style="z-index:1000" @click="$store.state.popMenu = !$store.state.popMenu"><img src="@/assets/logo.png" style="border-radius:90px" width="100%"></div>
        <div class="displayName">
          <div class="font-weight-bold">{{user.cs}}</div>
          <div class="text-small">{{user.email}}</div>
        </div>
      </div>
      <transition name="swipeup">
        <div v-if="$store.state.popMenu" class="dropdown-menu dropdown-menu-left p-0 m-2 show shadow border-top-8px" style="width:250px">
          <div class="dropdown-header dropdown-menu-lg text-center text-white border-top-8px" style="background:rgba(0,0,0,0.5)"><strong>Pengaturan</strong></div>
          <div style="cursor:pointer" role="menuitem" @click="$store.state.settings.schedule = !$store.state.settings.schedule;$store.state.popMenu = false" class="dropdown-item py-2 pr-5">
            <span><font-awesome-icon icon="calendar" class="mr-3" />Schedule</span>
          </div>
          <div style="cursor:pointer" role="menuitem" @click="$store.state.settings.schedulev2 = !$store.state.settings.schedulev2;$store.state.popMenu = false" class="dropdown-item py-2 pr-5">
            <span><font-awesome-icon icon="calendar" class="mr-3" />Schedule V2</span>
          </div>
          <div @click="stopShift()" style="cursor:pointer" role="menuitem" class="dropdown-item py-2 pr-5">
            <span class="text-danger"><font-awesome-icon icon="sign-out-alt" class="mr-3" />Stop Shift</span>
          </div>
        </div>
      </transition>
    </div>
    <div><button class="btn btn-primary" @click="$store.state.chipsfast = !$store.state.chipsfast">FC</button></div>
    <div><button class="btn btn-primary" @click="$store.state.chips = !$store.state.chips">Chips</button></div>

    <transition name='fade'>
      <div v-if="$store.state.popMenu" @click="$store.state.popMenu = false" id="blackscreen" class="min-vw-100 min-vh-100 position-fixed" style="top:0;left:0;background:rgba(0,0,0,0.7);z-index:999"></div>
    </transition>

    <transition name='fade'>
      <CSPicker v-if="user.cs == null" />
    </transition>
  </div>
</template>

<script>
import {db} from '@/main'
import firebase from 'firebase/app'
import 'firebase/auth'
import CSPicker from '@/components/CSPicker'

export default {
  name: 'SellerNav',
  components: {
    CSPicker
  },
  data(){
    return {
      reseting: false,
      user: {
        email: '',
        status: false,
        type: ''
      },
      cscounter: null
    }
  },
  methods:{
    csRegex(email){
      return email.match(/^([^@]*)@/)[1]
    },
    resetCSCounter(){
      var vm = this
      this.reseting = true
      
      let batch = db.batch();
      let cs1 = db.collection('cs').doc('cs1');
      batch.update(cs1, {client: 0});

      let cs2 = db.collection('cs').doc('cs2');
      batch.update(cs2, {client: 0});

      let cs3 = db.collection('cs').doc('cs3');
      batch.update(cs3, {client: 0});

      let cs4 = db.collection('cs').doc('cs4');
      batch.update(cs4, {client: 0});

      return batch.commit().then(function(){
        vm.reseting = false
      })
    },
  },
  mounted(){
    var vm = this
    db.collection('users').where('email', '==', firebase.auth().currentUser.email).onSnapshot(function(user){
      vm.user = user.docs[0].data()
    })

    this._keyListener = function(e) {
        if (e.key === "s" && (e.ctrlKey || e.metaKey)) {
            e.preventDefault(); // present "Save Page" from getting triggered.

            this.$store.state.chips = !this.$store.state.chips;
        }
    };

    document.addEventListener('keydown', this._keyListener.bind(this));
  },
  beforeDestroy() {
      document.removeEventListener('keydown', this._keyListener);
  },
  firestore () {
    return {
      cscounter: db.collection('cs')
    }
  }
}
</script>