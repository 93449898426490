<template>
    <div>
        <table class="table">
            <tr>
                <th width="180px">User</th>
                <th>Appeal Info</th>
                <th width="175px">Action</th>
            </tr>
            <tr v-for="user in appealList" :key="user.id">
                <td>
                    <div class="d-inline-block rounded p-2 m-1 align-top" style="width:calc(33.3% - .5rem); background: #f5f5f5">
                        <div class="d-flex">
                            <div class="mr-3"><img :src="user.photoUrl" class="rounded-circle" width="56px"></div>
                            <div>
                                <div class="text-small text-secondary">{{user.email}}</div>
                                <div v-if="user.bank != null">
                                    <div v-if="user.bank[0] != null">
                                        <span class="d-block font-weight-bold">{{user.bank[0].atasnama}}</span>
                                        <span class="d-block text-small">{{user.bank[0].norek}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div v-for="appeal in user.ban.appeal" :key="user.ban.appeal.indexOf(appeal)" class="d-flex mb-5">
                        <div class="mr-5">
                            <div>{{appeal.bank}}</div>
                            <div class="font-weight-bold">{{appeal.name}}</div>
                            <div class="text-muted text-small">{{appeal.accountNumber}}</div>
                        </div>
                        <div>
                            <div class="mb-2">
                                <span class="d-block text-small text-muted">Ban Reason</span>
                                <span>{{appeal.suspendReason}}</span>
                            </div>
                            <div>
                                <span class="d-block text-small text-muted">Appeal Action</span>
                                <span>{{appeal.appealAction}}</span>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <button class="btn btn-success text-small" @click="approveAppeal(user)">Approve</button>
                    <button class="btn btn-outline-danger ml-3 text-small" @click="rejectAppeal(user)">Reject</button>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import {db, fv} from '@/main'
import axios from 'axios'

export default {
    methods: {
        async rejectAppeal(user){
            var title = 'Appeal Gagal'
            var message = 'Maaf, permintaan appeal-mu di tolak karena tidak sesuai dengan data.'
            await this.sendAppealNotif(user, title, message)

            await db.collection('users').doc(user.uid).update({
                'ban.status': 'suspended',
                'ban.updated_at': fv.serverTimestamp()
            })
        },
        async approveAppeal(user){
            var title = 'Appeal Berhasil!'
            var message = 'Selamat, permintaan appeal-mu di terima dan akunmu kembali aktif.'
            await this.sendAppealNotif(user, title, message)

            await db.collection('users').doc(user.uid).update({
                'ban.status': 'approved',
                'ban.updated_at': fv.serverTimestamp()
            })
        },
        async sendAppealNotif(user, title, message){
            var data = await db.collection('users').doc(user.uid).collection('tokens').orderBy('createdAt', 'desc').limit(1).get()
            var token = data.docs[0].data()['token']

            if(token != null){
                var fcmheaders = {
                    'Content-Type': 'application/json',
                    'Authorization': 'key=AAAAqKvmdMQ:APA91bGZlFGDGiNWgRMUIxlNDCzi3FAEbWqsoRwyNhV0Z0oftfuq-UhZBmUL7kUC5b2yXU5YPiZVg-DrMhrQbNiZzBnIXaLVYZrCGrQR1WypOs_BrXSQrO48y-UTnzLyVottRelrbbUe'
                }
                var fcmbody = {
                    "to": token,
                    "notification": {
                        "title": title,
                        "body": message,
                    },
                    "data": {
                        "title": title,
                        "body": message,
                        'type': 'appeal',
                        "click_action": "FLUTTER_NOTIFICATION_CLICK",
                    }
                }
                await axios.post('https://fcm.googleapis.com/fcm/send', fcmbody, {headers: fcmheaders})
            }
        }
    },
    firestore(){
        return {
            appealList: db.collection('users').where('ban.status', '==', 'appeal')
        }
    }
}
</script>