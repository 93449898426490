<template>
    <div class="container-fluid">
        <div class="row mt-3    " style="margin-bottom:58px">
            <div class="col-12">
                <div class="d-flex justify-content-end">
                    <Datepicker input-class="form-control" v-model="selectedDate" />
                    <button class="btn btn-outline-primary ml-3" @click="showData()">Show</button>
                </div>
            </div>
            <div class="col-12" style="margin-top:16px">
                <table>
                    <thead>
                        <tr>
                            <th>Bank</th>
                            <th class="text-right">Awal Shift</th>
                            <th class="text-right">Akhir Shift</th>
                            <th class="text-right">Saat Ini</th>
                            <th class="text-right">Admin</th>
                        </tr>
                    </thead>
                    <tbody> 
                        <tr v-for="(bank, i) in todayFiances" :key="i">
                            <td>
                                <div v-for="(bankInfo, idx) in serverBanks" :key="'A1'+idx">
                                    <div class="d-flex" v-if="bankInfo.id == bank.server_bank_id">
                                        <img :src="bankIconURL(bankInfo.bank)" height="72px" />
                                        <div class="ml-3">
                                            <div class="small">{{bankInfo.holder}}</div>
                                            <div class="font-weight-bold">{{bankInfo.account_number}}</div>
                                            <div class="small">Rp {{currency(bankInfo.balance)}}</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="text-right">
                                    <div>
                                        <label class="small" for="beginning">Saldo <b class="text-success">Awal</b> Shift</label>
                                        <div class="font-weight-bold">Rp {{currency(bank.beginning)}}</div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="text-right" v-if="bank.ending != null">
                                    <div>
                                        <label class="small" for="ending">Saldo <b class="text-danger">Akhir</b> Shift</label>
                                        <div class="font-weight-bold">Rp {{currency(bank.ending)}}</div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-right">
                                <!--
                                <label class="small" for="beginning">Saldo <b class="text-primary">Saat ini</b></label>
                                <div v-for="sb in serverBanks" :key="sb.id">
                                    <div class="font-weight-bold" style="font-size:1.25rem" v-if="sb.id == bank.server_bank_id">Rp {{currency(sb.balance)}}</div>
                                </div>
                                -->
                                <div v-if="bank.ending != null">
                                    <label class="small">Pengeluaran</label>
                                    <div class="font-weight-bold" style="font-size:1.25rem">Rp {{currency(bank.remittance)}}</div>
                                </div>
                            </td>
                            <td>
                                <div class="text-primary font-weight-bold">{{bank.notes}}</div>
                                <div>{{bank.cs}}</div>
                                <div class="small">{{bank.id}}</div>
                            </td>
                        </tr>
                        <tr><td colspan="5" style="height:72px"></td></tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import {db} from '@/main'
import firebase from 'firebase/app'
import 'firebase/auth'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker';

export default {
    name:'financial-logs',
    components: {Datepicker},
    data(){
        return {
            selectedDate: new Date(),
            logTime: ''
        }
    },
    mounted(){
        this.showData()
    },
    methods: {
        showData(){
            if(this.selectedDate != null){
                this.logTime = 'FNC'+moment(this.selectedDate).format('DDMMMyyyy')
            }
        }
    },
    computed: {
        todayFiances: function(){
            return this.financesDaily.filter(fnc => {
                return fnc.identifier == this.logTime
            })
        }
    },
    firestore(){
        return {
            serverBanks: db.collection('server-banks').orderBy('bank').orderBy('holder').orderBy('account_number'),
            financesDaily: db.collection('finances-daily').orderBy('created_at'),
            user: db.collection('users').doc(firebase.auth().currentUser.uid)
        }
    }
}
</script>