<template>
    <div class="creativeboard d-flex">
        <div v-if="rawReport.length > 0" class="report mb-2 pb-3 mr-2">
            <div class="mb-4">
                <div class="report-header">
                    <div class="report-title">Contributions</div>
                    <div class="report-date">{{lastMonth}}</div>
                </div>
                <div class="d-inline-block" v-for="(cntr, cidx) in daysInLastMonth" :key="'last_'+cidx" v-html="contributionCount(cidx+1, lastMonth)" @click="contributionDetails(cidx+1, lastMonth)"></div>
            </div>
            <div class="mb-4">
                <div class="report-header">
                    <div class="report-date">{{currentMonth}}</div>
                </div>
                <div class="d-inline-block" v-for="(cntr, cidx) in daysInMonth" :key="'current_'+cidx" v-html="contributionCount(cidx+1, currentMonth)" @click="contributionDetails(cidx+1, currentMonth)"></div>
            </div>
        </div>
        <div v-if="detailsReport.length > 0" class="report mb-2 pb-3">
            <div class="report-header">
                <div class="report-title">Daily Report</div>
                <div class="report-date">{{detailsDate}}</div>
            </div>
            <transition-group type="transition" :name="'flip-list'">
                <li class="list-group-item" v-for="element in detailsReport" :key="element.id" :id="'report-item-'+element.id">
                    <span class="small badge text-white" :style="'background:'+element.background">{{element.name}}</span>
                    <i :class="element.fixed? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'" @click=" element.fixed=! element.fixed" aria-hidden="true"></i>
                    {{element.value}}
                </li>
            </transition-group>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import {db} from '@/main'

export default {
    data(){
        return {
            lastMonth: moment().subtract(1,'months').format('MMMM'),
            currentMonth: moment().format('MMMM YYYY'),
            rawReport: [],
            detailsReport: [],
            detailsDate: '',
            daysInLastMonth: (new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1, 0).getDate()),
            daysInMonth: (new Date((new Date()).getFullYear(), (new Date()).getMonth(), 0).getDate()),
        }
    },
    created(){
        db.collection('users').where('type', '==', 'CREATIVE').get().then((snaps) => {
            this.rawReport = []
            snaps.docs.forEach(element => {
                db.collection('creative-teams').doc(element.id).collection('daily-reports').orderBy('created_at', 'asc').get().then((reports) => {
                    reports.docs.forEach(rep => {
                        var data = rep.data()
                        data.uid = element.id
                        data.name = element.data().name
                        data.background = element.data().background
                        this.rawReport.push(data)
                    });
                })
            });
        })
    },
    methods: {
        contributionCount(date, month){
            var timeStart = moment(date+' '+month+' '+'2021').unix()
            var timeEnd = moment(date+' '+month+' '+'2021').endOf('day').unix()
            var count = 0
            this.rawReport.forEach(el => {
                if(timeStart < el.created_at.seconds && timeEnd >= el.created_at.seconds){
                    count++
                }
            });
            if(count > 10){
                return `<div class="contribution-block bg-superb tooltipe"><span class="tooltiptext">(${date}) ${count} kontribusi</span></div>`
            } else if(count > 5){
                return `<div class="contribution-block bg-high tooltipe"><span class="tooltiptext">(${date}) ${count} kontribusi</span></div>`
            } else if(count > 2){
                return `<div class="contribution-block bg-moderate tooltipe"><span class="tooltiptext">(${date}) ${count} kontribusi</span></div>`
            } else if(count > 0){
                return `<div class="contribution-block bg-low tooltipe"><span class="tooltiptext">(${date}) ${count} kontribusi</span></div>`
            } else {
                return `<div class="contribution-block tooltipe"><span class="tooltiptext">(${date}) ${count} kontribusi</span></div>`
            }
        },
        contributionDetails(date, month){
            this.detailsReport = []
            this.detailsDate = `${date} ${month}`
            var timeStart = moment(date+' '+month+' '+'2021').unix()
            var timeEnd = moment(date+' '+month+' '+'2021').endOf('day').unix()
            this.rawReport.forEach(el => {
                if(timeStart < el.created_at.seconds && timeEnd >= el.created_at.seconds){
                    this.detailsReport.push(el)
                }
            });
        }
    },
    computed: {
        dragOptions() {
            return {
                animation: 0,
                group: "description",
                disabled: !this.editable,
                ghostClass: "ghost"
            };
        },
        reports(){
            var now = moment()
            return this.rawReport.filter(el => {
                return now.startOf('day') < el.created_at.seconds*1000
            })
        }
    }
}
</script>