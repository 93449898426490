import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from "firebase/app"
import 'firebase/auth'
import {db} from '../main'
//import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue'
import Login from '../views/Login'
import MasterDashboard from '../views/admin/Dashboard'
import Copas from '@/views/CoPas'
import Sellerboard from '@/views/seller/Sellerboard'

//master
import ListServerBank from '@/views/admin/ServerBanks'
import FinancialLogs from '@/views/admin/FinancialLogs'

//finance
import Finboard from '@/views/finance/finance.layout'


import {Role} from '@/helpers/Role'
import DummyGenerator from '@/helpers/DummyGenerator'
import ForPaper from '@/helpers/ForPaper'

//holder
import Holder from '@/views/holder/main'

import Creativeboard from '@/views/creative/creative.layout'

import NewCSTeam from '@/helpers/newteam'

import ModemView from '@/views/checker/modemboard'
import PresenceDaily from '@/views/public/daily.presence'
import PresenceReport from '@/views/public/report.presence'

import CustomerCare from '@/views/customercare/Index'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Dashboard,
    meta: {
      authorize: [
        Role.CS
      ],
      requiresAuth: true,
      title: 'Dashboard - byPulsa'
    }
  },
  {
    path: '/new-team',
    name: 'New_CS_Team',
    title: 'New CS Team',
    component: NewCSTeam,
    meta: {
      authorize: [Role.Master, Role.Holder]
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      authorize: [Role.Master],
      requiresAuth: true,
      title: 'Dashboard - byPulsa'
    }
  },
  {
    path: '/master',
    name: 'master-dashboard',
    component: MasterDashboard,
    meta: {
      authorize: [Role.Master],
      requiresAuth: true,
      title: 'Dashboard - byPulsa'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      authorize: [],
      title: 'Login - byPulsa'
    }
  },
  {
    path: '/copas',
    name: 'Copas',
    component: Copas,
    meta: {
      authorize: [],
      title: 'Copas - byPulsa'
    }
  },

//======== HOLDER GOES HERE ========//
  {
    path: '/holderboard',
    name: 'Holderboard',
    component: Holder,
    meta: {
      requiresAuth: true,
      authorize: [Role.Holder]
    }
  },
//======== HOLDER GOES HERE ========//

//======== MASTER GOES HERE ========//
  {
    path: '/list-banks',
    name: 'ListServerBank',
    component: ListServerBank,
    meta: {
      authorize: [Role.Master]
    }
  },
  {
    path: '/financial',
    name: 'FinancialLogs',
    component: FinancialLogs,
    meta: {
      authorize: [Role.Master]
    }
  },
//======== MASTER END HERE ========//

//======== SELLER GOES HERE ========//
  {
    path: '/sellerboard',
    name: 'Sellerboard',
    component: Sellerboard,
    meta: {
      requiresAuth: true,
      title: 'Seller - Dashboard',
      authorize: [Role.Seller, Role.Master]
    }
  },
//======== SELLER END HERE ========//

//======== FINANCE GOES HERE ========//
  {
    path: '/finboard',
    name:  'Finboard',
    component: Finboard,
    meta: {
      requiresAuth: true,
      title: 'Financial - Dashboard',
      authorize: [Role.Finance, Role.Holder]
    }
  },
  {
    path: '/creativeboard',
    name: 'creativeboard',
    component: Creativeboard,
    meta: {
      requiresAuth: true,
      title: 'Creative Dashboard',
      authorize: [Role.Holder, Role.Creative]
    }
  },
//======== FINANCE END HERE ========//

//===== customer care ======//
  {
    path: '/customer-care',
    name: 'customer-care',
    component: CustomerCare,
    meta: {
      requireSAuth: true,
      title: 'Customer Care',
      authorize: [Role.Holder, Role.CustomerCare]
    }
  },


//======== DEV GOES HERE ========//
  {
    path: '/modem',
    name: 'modem',
    component: ModemView,
    meta: {
      requiresAuth: true,
      title: 'Checker Dashboard',
      authorize: [Role.Holder, Role.Creative, Role.CS]
    }
  },
  {
    path: '/presence/daily',
    name: 'presence-daily',
    component: PresenceDaily,
    meta: {
      requiresAuth: false,
      title: 'Daily Presence'
    }
  },
  {
    path: '/presence/report',
    name: 'presence-report',
    component: PresenceReport,
    meta: {
      requireAuth: false,
      title: 'Presence Report'
    }
  },
  {
    path: '/generate-dummy',
    name: 'DummyGenerator',
    component: DummyGenerator,
    meta: {
      authorize: [Role.Seller, Role.CS, Role.Master, Role.Holder]
    }
  },
  {
    path: '/for-paper',
    name: 'ForPaper',
    component: ForPaper,
    meta: {
      authorize: [Role.Seller, Role.CS, Role.Master, Role.Holder]
    }
  },
//======== DEV GOES HERE ========//
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  window.document.title = to.meta && to.meta.title ? to.meta.title : 'byPulsa';
  const currentUser = firebase.auth().currentUser;
  
  if(to.meta.requiresAuth){
    if (currentUser == null && to.path !== '/login') next('login');
    else if(currentUser != null) {
      db.collection('users').doc(currentUser.uid).get().then(user => {
        const type = user.data().type
        try {
          if(to.meta.authorize.includes(type)){
            next()
          } else {
            switch (type) {
              case 'seller': next('/sellerboard'); break;
              case 'FINANCE': next('/finboard'); break;
              case 'master': next('/master'); break;
              case 'HOLDER': next('/holderboard'); break;
              case 'CREATIVE': next('/creativeboard'); break;
              case 'customer_care': next('/customer-care'); break;
              default: break;
            }
          }
        } catch (error) {
          console.log('error: '+error)
        }
      })
    } else {
      next();
    }
  } else {
    next()
  }
});

export default router