<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import {db, fv} from '@/main'
import firebase from 'firebase/app'
import 'firebase/auth';

export default {
  async mounted(){
    var ip = await this.getLoginIP();
    var ref = await db.collection('users').doc(firebase.auth().currentUser.uid).collection('sign_history').doc()
    await ref.set({
      'id': ref.id,
      'ip': ip,
      'created_at': fv.serverTimestamp()
    })
    console.log('stored')
    console.log(ref)
  }
}
</script>

<style lang="scss">
  @import 'assets/scss/app';
</style>