<template>
  <div>
    <table>
        <thead>
            <tr>
              <th>PURCHASE SOURCE</th>
              <th colspan="2">25 Maret 2022</th>
              <th colspan="2">09 April 2022</th>
              <th colspan="2">Changes</th>
            </tr>
        </thead>
        <thead>
            <tr>
              <th>Provider</th>
              <th>Rate</th>
              <th>Purchase</th>
              <th>Rate</th>
              <th>Purchase</th>
              <th>Rate</th>
              <th>QTY</th>
            </tr>
        </thead>
        <tbody>
          <tr>
            <td>Telkomsel</td>
            <td>{{rates.telkomsel.before}}</td>
            <td>{{currency(rates.telkomsel.qtyb)}}</td>
            <td>{{rates.telkomsel.after}}</td>
            <td>{{currency(rates.telkomsel.qtya)}}</td>
            <td><span v-html="status(rates.telkomsel.after-rates.telkomsel.before)"></span></td>
            <td><span v-html="qtyChange(rates.telkomsel.qtya, rates.telkomsel.qtyb)"></span></td>
          </tr>
          <tr>
            <td>XL/Axis</td>
            <td>{{rates.axis.before}}</td>
            <td>{{currency(rates.axis.qtyb)}}</td>
            <td>{{rates.axis.after}}</td>
            <td>{{currency(rates.axis.qtya)}}</td>
            <td><span v-html="status(rates.axis.after-rates.axis.before)"></span></td>
            <td><span v-html="qtyChange(rates.axis.qtya, rates.axis.qtyb)"></span></td>
          </tr>
          <tr>
            <td>Indosat</td>
            <td>{{rates.indosat.before}}</td>
            <td>{{currency(rates.indosat.qtyb)}}</td>
            <td>{{rates.indosat.after}}</td>
            <td>{{currency(rates.indosat.qtya)}}</td>
            <td><span v-html="status(rates.indosat.after-rates.indosat.before)"></span></td>
            <td><span v-html="qtyChange(rates.indosat.qtya, rates.indosat.qtyb)"></span></td>
          </tr>
          <tr>
            <td>Three</td>
            <td>{{rates.three.before}}</td>
            <td>{{currency(rates.three.qtyb)}}</td>
            <td>{{rates.three.after}}</td>
            <td>{{currency(rates.three.qtya)}}</td>
            <td><span v-html="status(rates.three.after-rates.three.before)"></span></td>
            <td><span v-html="qtyChange(rates.three.qtya, rates.three.qtyb)"></span></td>
          </tr>
        </tbody>
    </table>

    <br/>

    <table>
        <thead>
            <tr>
              <th>PRICING PRE/POST PPN 11%</th>
              <th colspan="2">25 Maret 2022</th>
              <th colspan="2">09 April 2022</th>
              <th colspan="2">Changes</th>
            </tr>
        </thead>
        <thead>
            <tr>
              <th>Provider</th>
              <th>Rate</th>
              <th>Sales</th>
              <th>Rate</th>
              <th>Sales</th>
              <th>Rate</th>
              <th>QTY</th>
            </tr>
        </thead>
        <tbody>
          <tr>
            <td>Telkomsel</td>
            <td>{{sells.telkomsel.before}}</td>
            <td>{{currency(sells.telkomsel.qtyb)}}</td>
            <td>{{sells.telkomsel.after}}</td>
            <td>{{currency(sells.telkomsel.qtya)}}</td>
            <td><span v-html="status(sells.telkomsel.after-sells.telkomsel.before)"></span></td>
            <td><span v-html="qtyChange(sells.telkomsel.qtya, sells.telkomsel.qtyb)"></span></td>
          </tr>
          <tr>
            <td>XL/Axis</td>
            <td>{{sells.axis.before}}</td>
            <td>{{currency(sells.axis.qtyb)}}</td>
            <td>{{sells.axis.after}}</td>
            <td>{{currency(sells.axis.qtya)}}</td>
            <td><span v-html="status(sells.axis.after-sells.axis.before)"></span></td>
            <td><span v-html="qtyChange(sells.axis.qtya, sells.axis.qtyb)"></span></td>
          </tr>
          <tr>
            <td>Indosat</td>
            <td>{{sells.indosat.before}}</td>
            <td>{{currency(sells.indosat.qtyb)}}</td>
            <td>{{sells.indosat.after}}</td>
            <td>{{currency(sells.indosat.qtya)}}</td>
            <td><span v-html="status(sells.indosat.after-sells.indosat.before)"></span></td>
            <td><span v-html="qtyChange(sells.indosat.qtya, sells.indosat.qtyb)"></span></td>
          </tr>
          <tr>
            <td>Three</td>
            <td>{{sells.three.before}}</td>
            <td>{{currency(sells.three.qtyb)}}</td>
            <td>{{sells.three.after}}</td>
            <td>{{currency(sells.three.qtya)}}</td>
            <td><span v-html="status(sells.three.after-sells.three.before)"></span></td>
            <td><span v-html="qtyChange(sells.three.qtya, sells.three.qtyb)"></span></td>
          </tr>
        </tbody>
    </table>
  </div>
</template>

<script>
import {db} from '@/main'
export default {
  data(){
    return {
      trxs: [],
      rates: {
        telkomsel: { before: 0, after: 0, qtyb: 0, qtya:0 },
        axis: { before: 0, after: 0, qtyb: 0, qtya:0 },
        three: { before: 0, after: 0, qtyb: 0, qtya:0 },
        indosat: { before: 0, after: 0, qtyb: 0, qtya:0 }
      },
      sells: {
        telkomsel: { before: 0, after: 0, qtyb: 0, qtya:0 },
        axis: { before: 0, after: 0, qtyb: 0, qtya:0 },
        three: { before: 0, after: 0, qtyb: 0, qtya:0 },
        indosat: { before: 0, after: 0, qtyb: 0, qtya:0 }
      }
    }
  },
  async created(){
    //this.getTrx('log_29_Mar_2022', 'log_09_Apr_2022')
    //this.getSales('log_29_Mar_2022', 'log_09_Apr_2022')
    const idf = 'log_31_Mar_2022'
    const snap = await db.collection('converts').where('provider', '==', 'THREE').where('identifier', '==', idf).get()
    let value = 0
    let rate = 0
    snap.docs.forEach((doc) => {
      value+=doc.data().nominal
      if(rate == 0) rate = parseFloat(doc.data().rate)
      else {
        rate+=parseFloat(doc.data().rate)
        rate = rate/2
      }
    })
    console.log(idf)
    console.log(value)
    console.log(rate)

  },
  methods: {
    status(value){
      let resp = ``
      if(value > 0) resp = `<div class='bg-success text-white font-weight-bold text-center'>${(value*100).toFixed(0)}%</div>`
      else if(value < 0) resp = `<div class='bg-danger text-white font-weight-bold text-center'>${(value*100).toFixed(0)}%</div>`
      else resp = `<div class='bg-grey font-weight-bold text-center'>${(value*100).toFixed(0)}%</div>`
      return resp
    },
    qtyChange(after, before){
      let resp = ``
      const gap = after-before
      const change = (gap/after*100).toFixed(0)
      if(gap > 0) resp = `<div class='bg-success text-white font-weight-bold text-center'>${change}%</div>`
      else if(gap < 0) resp = `<div class='bg-danger text-white font-weight-bold text-center'>${change}%</div>`
      else resp = `<div class='bg-grey font-weight-bold text-center'>${change}%</div>`
      return resp
    },
    async getTrx(before, after){
      const befores = await db.collection('converts').where('identifier', '==', before).get()
      befores.docs.forEach((doc) => {
        const data = doc.data()
        switch (data.provider) {
          case 'TELKOMSEL':
            this.rates.telkomsel.before = data.rate
            this.rates.telkomsel.qtyb+=data.nominal
            break;
          case 'AXIS':
            this.rates.axis.before = data.rate
            this.rates.axis.qtyb+=data.nominal
            break;
          case 'INDOSAT':
            this.rates.indosat.before = data.rate
            this.rates.indosat.qtyb+=data.nominal
            break;
          case 'THREE':
            this.rates.three.before = data.rate
            this.rates.three.qtyb+=data.nominal
            break;
        }
      })


      const afters = await db.collection('converts').where('identifier', '==', after).get()
      afters.docs.forEach((doc) => {
        const data = doc.data()
        switch (data.provider) {
          case 'TELKOMSEL':
            this.rates.telkomsel.after = data.rate
            this.rates.telkomsel.qtya+=data.nominal
            break;
          case 'AXIS':
            this.rates.axis.after = data.rate
            this.rates.axis.qtya+=data.nominal
            break;
          case 'INDOSAT':
            this.rates.indosat.after = data.rate
            this.rates.indosat.qtya+=data.nominal
            break;
          case 'THREE':
            this.rates.three.after = data.rate
            this.rates.three.qtya+=data.nominal
            break;
        }
      })
    },

    async getSales(before, after){
      const befores = await db.collection('sells').where('identifier', '==', before).get()
      befores.docs.forEach((doc) => {
        const data = doc.data()
        if(0.00 < parseFloat(data.rate) && 1.00 >= parseFloat(data.rate)){
          switch (data.chip.provider) {
            case 'TELKOMSEL':
              this.sells.telkomsel.before = data.rate
              this.sells.telkomsel.qtyb+=data.amount-data.fee
              break;
            case 'AXIS':
              this.sells.axis.before = data.rate
              this.sells.axis.qtyb+=data.amount-data.fee
              break;
            case 'INDOSAT':
              this.sells.indosat.before = data.rate
              this.sells.indosat.qtyb+=data.amount-data.fee
              break;
            case 'THREE':
              this.sells.three.before = data.rate
              this.sells.three.qtyb+=data.amount-data.fee
              break;
          }
        }
      })


      const afters = await db.collection('sells').where('identifier', '==', after).get()
      afters.docs.forEach((doc) => {
        const data = doc.data()
        if(0.00 < parseFloat(data.rate) && 1.00 >= parseFloat(data.rate)){
          switch (data.chip.provider) {
            case 'TELKOMSEL':
              this.sells.telkomsel.after = data.rate
              this.sells.telkomsel.qtya+=data.amount-data.fee
              break;
            case 'AXIS':
              this.sells.axis.after = data.rate
              this.sells.axis.qtya+=data.amount-data.fee
              break;
            case 'INDOSAT':
              this.sells.indosat.after = data.rate
              this.sells.indosat.qtya+=data.amount-data.fee
              break;
            case 'THREE':
              this.sells.three.after = data.rate
              this.sells.three.qtya+=data.amount-data.fee
              break;
          }
        }
      })
    }
  }
}
</script>