<template>
  <div style="padding: 8px; height:100vh">
    <div v-for="log in sortPresence(presenceList)" :key="log.id" class="position-relative d-inline-block presence-card text-center" :class="log.status == 'in' ? 'status-in':'status-out'">
      <div class="v-center">
        <div>{{log.name}}({{log.status}})</div>
        <div class="text-small">{{fbDateToString(log.created_at.seconds * 1000, 'HH:mm:ss')}}</div>
      </div>
    </div>
    <div class="position-fixed vw-100 vh-100" style="top:0;left:0" v-if="checkIn != null">
      <div class="position-absolute vw-100 vh-100" style="background-color:rgba(0,0,0,0.7)"></div>
      <div class="position-absolute bg-white vw-100 vh-100" style="transform: translate(-50%, -50%); left: 50%; top: 50%;border-radius: 12px; border-radius:16px">
        <lottie-animation v-if="checkIn.status == 'in'" path="animations/welcome.json" :loop="false" />
        <lottie-animation v-else path="animations/thankyou.json" :loop="false" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {db} from '@/main'
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  name: 'presence-daily',
  components: {LottieAnimation},
  data(){
    return {
      presences: [],
      checkIn: null
    }
  },
  created(){
    this.getDaily()
  },
  methods: {
    getDaily(){
      var init = false

      let m1 = moment()
      let m2 = moment()
      m1.startOf('day');
      m2.endOf('day');

      console.log(m1)
      console.log(m2)

      db.collection("presences").orderBy("created_at").where("created_at", ">", m1.toDate())
        .where("created_at", "<=", m2.toDate()).onSnapshot((snaps) => {
          this.presences = []
          snaps.docs.forEach(element => {
            this.presences.push(element.data())
          });
                  
          if(!init) init = true
          else {
            snaps.docChanges().forEach((change) => {
              this.checkIn = this.presences[change.newIndex]
              var dur = 3000
              if(this.checkIn.status == 'in') dur = 6000
              setTimeout(() => {
                this.checkIn = null
              }, dur);
            })
          }
        })
    },
    sortPresence(arr) {
      return arr.slice().sort(function(a, b) {
        return b.created_at - a.created_at;
      });
    }
  },
  computed: {
    presenceList: function(){
      var newList = []
      this.presences.filter((prs) => {
        var exist = false
        newList.forEach((item) => {
          if(item.cid == prs.cid) exist = true
        })
        if(!exist){
          newList.push(prs)
        } else{
          var index
          newList.forEach((already) => {
            if(already.cid == prs.cid) index = newList.findIndex( (element) => element.cid === prs.cid);
          })
          newList[index] = prs
        }
      })
      return newList
    }
  }
}
</script>

<style>

.v-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.presence-card {
  color:white;
  width: calc((100% - 32px) / 5);
  height: calc((100vh - 32px) / 5);
}
.status-out{
  background:red;
  }
  .status-in{
  background:green;}
.presence-card:not(:nth-child(5)){
  margin-right: 8px;
}
</style>