<template>
  <div class="navbartop py-2 px-2 bg-white shadow-sm d-flex justify-content-between">
    <div class="d-flex justify-content-between">
      <div class="headinfo" style="cursor: pointer; z-index:1000; border-radius:4px" @click="$store.state.popMenu = !$store.state.popMenu">
        <div class="ml-2 avatar"><img src="@/assets/logo.png" style="border-radius:90px" width="100%"></div>
        <div class="displayName">
          <div class="font-weight-bold">{{user.cs}}</div>
          <div class="text-small" v-if="omset == 0">{{user.email}}</div>
          <div class="text-small" v-else>{{currency(omset)}}</div>
        </div>
      </div>
      <!--
      <label class="switch mb-0 ml-3">
        <input id="switchstatus" type="checkbox" v-model="user.status" @change="changeOnlineStatus(user.status)">
        <span class="slider round"></span>
      </label>-->
    </div>
    <div>
      <transition name="swipeup">
        <div v-if="$store.state.popMenu" class="dropdown-menu dropdown-menu-left p-0 m-2 show shadow border-top-8px" style="width:250px">
          <div class="dropdown-header dropdown-menu-lg text-center text-white border-top-8px" style="background:rgba(0,0,0,0.5)"><strong>Pengaturan</strong></div>
          <div style="cursor:pointer" role="menuitem" @click="$store.state.settings.kalkulator = !$store.state.settings.kalkulator;$store.state.popMenu = false" class="dropdown-item py-2 pr-5">
            <span><font-awesome-icon icon="calendar" class="mr-3" />Kalkulator</span>
          </div><div style="cursor:pointer" role="menuitem" @click="$store.state.settings.schedule = !$store.state.settings.schedule;$store.state.popMenu = false" class="dropdown-item py-2 pr-5">
            <span><font-awesome-icon icon="calendar" class="mr-3" />Schedule</span>
          </div>
          <div style="cursor:pointer" role="menuitem" @click="$store.state.settings.schedulev2 = !$store.state.settings.schedulev2;$store.state.popMenu = false" class="dropdown-item py-2 pr-5">
            <span><font-awesome-icon icon="calendar" class="mr-3" />Schedule V2</span>
          </div>
          <div style="cursor:pointer" @click="$store.state.settings.quickreplay = !$store.state.settings.quickreplay;$store.state.popMenu = false" class="dropdown-item py-2 pr-5">
            <span><font-awesome-icon icon="comment" class="mr-3" />Quick Replay</span>
          </div>
          <div style="cursor:pointer" @click="$store.state.settings.hashtags = !$store.state.settings.hashtags;$store.state.popMenu = false" class="dropdown-item py-2 pr-5">
            <span><font-awesome-icon icon="hashtag" class="mr-3" />Hashtags</span>
          </div>
          <div style="cursor:pointer" role="menuitem" @click="stopShift()" class="dropdown-item py-2 pr-5">
            <span class="text-danger"><font-awesome-icon icon="sign-out-alt" class="mr-3" />Stop Shift</span>
          </div>
        </div>
      </transition>
    </div>
    <transition name='fade'>
      <div v-if="$store.state.popMenu" @click="$store.state.popMenu = false" id="blackscreen" class="min-vw-100 min-vh-100 position-fixed" style="top:0;left:0;background:rgba(0,0,0,0.7);z-index:999"></div>
    </transition>
  </div>
</template>

<script>
import {db} from '../main'
import firebase from 'firebase/app'
import 'firebase/auth'
import moment from 'moment'

export default {
  name: 'Navigation',
  data(){
    return {
      logTime: 'log_'+moment(new Date).format('DD_MMM_yyyy'),
      omset: 0,
      user: {
        email: '',
        status: false,
        type: ''
      },
    }
  },
  mounted(){
    setInterval(() => {
      db.collection('converts').where('admin', '==', this.user.cs).where('identifier', '==', this.logTime).get().then((docs) => {
        this.omset = 0
        docs.docs.forEach(el => {
          if(el.data().status == 'selesai' || el.data().status == 'settlement'){
            this.omset += el.data().nominal
          }
        });
      })
    }, 10000)
  },
  firestore(){
    return {
      user: db.collection('users').doc(firebase.auth().currentUser.uid),
    }
  }
}
</script>