<template>
<div>
    <div style="z-index:99999; top:8px; right:120px" class="position-fixed d-flex">
        <Datepicker input-class="form-control" v-model="selectedDate" />
        <button class="btn btn-outline-primary ml-3" @click="viewData()">Show</button>
    </div>
    <table>
    <thead>
        <tr class="text-center">
            <th><i class="icon-globe"></i></th>
            <th class="text-center">ID</th>
            <th class="text-center">Nomor</th>
            <th class="text-center">Awal</th>
            <th class="text-center" v-for="(team, idx) in teamList" :key="'title_three_'+idx">{{team.name}}</th>
        </tr>
    </thead>
        <tbody>
        <tr v-for="(tsel, index) in $props.list" :key='index'>
            <td class="text-center align-middle" style="width:36px">
                <div class="avatar">
                <img class="img-avatar" :src="icon" width="36px">
                </div>
            </td>
            <td class="align-middle text-center" style="width:36px" @click="printValue(tsel.id)">
                {{tsel.shortcut}}
            </td>
            <td class="align-middle text-center" style="width:130px">
                {{tsel.value}}
            </td>
            <td class="align-middle text-center">
                {{currency(tsel.nominal)}}
            </td>
            <td class="hrvable text-center" v-for="(team, idx) in teamList" :key="'three_'+idx" @dblclick="showLogTrxs(logTime, tsel, team.identifier)">{{getTheNomorSum(tsel, team.identifier)}}</td>
        </tr>
        </tbody>

        <!-- hidden element -->
        <input class="position-fixed" style="top:-100px" type="text" :id="'copyServerPhone_'+$props.provider" :value="stringToCopy">
    </table>
</div>
</template>

<script>
import moment from 'moment'
import {db} from '@/main'
import Datepicker from 'vuejs-datepicker';


const teamList = [
  {
    'name': '1A',
    'identifier': 'satupagi'
  },
  {
    'name': '1B',
    'identifier': 'satusiang'
  },
  {
    'name': '2A',
    'identifier': 'duapagi'
  },
  {
    'name': '2B',
    'identifier': 'duasiang'
  },
  {
    'name': '3A',
    'identifier': 'tigapagi'
  },
  {
    'name': '3B',
    'identifier': 'tigasiang'
  },
  {
    'name': '4A',
    'identifier': 'empatpagi'
  },
  {
    'name': '4B',
    'identifier': 'empatsiang'
  }
]

export default {
    components: {Datepicker},
    props: {
        list: Array,
        provider: String
    },
    data(){
        return {
            teamList: teamList,
            icon: null,
            stringToCopy: '',
            dataToCopy: null,
            copiedData: false,
            loading: false,
            selectedDate: new Date(),
            logYesterday: null,
            logTime: null,
            newAmount: null,
            saving: false,
            editValue: '',
            numberYesterday: [],
            numberToday: [],
            csLogs: []
        }
    },
    async mounted(){
        this.shift = await this.getShift()
        this.viewData()
        this.icon = this.getProviderIcon(this.$props.provider)
    },
    methods: {
        printValue(val){console.log(val)},
        async viewData(){
            this.loading = true
            this.numberYesterday = []
            this.numberToday = []
            this.csLogs = []

            if(this.selectedDate != null){
                var yesterdayData = new Date(new Date(this.selectedDate).setDate(this.selectedDate.getDate() - 1))
                this.logTime = 'log_'+moment(this.selectedDate).format('DD_MMM_yyyy')
                this.logYesterday = 'log_'+moment(yesterdayData).format('DD_MMM_yyyy')

                await db.collection('numbers').where('identifier', '==', this.logYesterday).orderBy('order').get().then((docs) => {
                docs.docs.forEach(el => {
                    this.numberYesterday.push(el.data())
                });
                })

                await db.collection('numbers').where('identifier', '==', this.logTime).orderBy('order').get().then((docs) => {
                docs.docs.forEach(el => {
                    this.numberToday.push(el.data())
                });
                })

                await db.collection('cs_logs').where('identifier', '==', this.logTime).get().then((snap) => {
                snap.docs.forEach(el => {
                    this.csLogs.push(el.data())
                });
                })

                this.loading = false
            }
        },
        getSingleSum(val){
            var data = []
            var sumUp = 0
            this.csLogs.forEach(element => {
                if(element.nomor == val.value && element.identifier == this.logTime){
                data.push(element)
                }
            });

            data.forEach(element => {
                if(element.shift.toUpperCase() != 'SELL'){
                sumUp += element.nominal
                } else {
                sumUp -= element.nominal
                }
            });

            return (sumUp + val.nominal);
        },
        getChecker(val){
            var data
            this.numberToday.forEach(el => {
                if(val.value == el.value){
                data = el.nominal
                }
            })

            return data;
        },
        getCheckerTime(val){
            var data
            this.numberToday.forEach(el => {
                if(val.value == el.value){
                data = el.updated_at
                }
            })

            return data;
        },
        getTheNomorSum(val, shift){
        var data
        this.csLogs.forEach(element => {
            if(element.nomor == val.value && element.identifier == this.logTime && element.shift.toUpperCase() == shift.toUpperCase()){
            data = this.currency(element.nominal)
            }
        });

        return data;
        },
    },
}
</script>