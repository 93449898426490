<template>
    <div class="min-vw-100 min-vh-100 position-fixed" style="top:0;left:0;background:rgba(0,0,0,0.7);z-index:99999">
        <div class="w-100 h-100 position-fixed" style="top:0; left:0;z-index:-1" @click="$store.state.settings.blacklist = false"></div>
        <div class="w-96 mx-auto mt-3 p-3 row" style='border-radius:8px; background:white'>
            <div class="col-md-2">
                <div>
                    <div v-for="status in blacklistStatus" :key="status" class="rounded p-2 cursor-pointer list-faq" :class="blacklistActive == status ? 'active-faq':''" style="background:#f5f5f5" @click="blacklistActive = status">{{status}}</div>
                </div>
            </div>
            <div class="col-md-10" style="max-height:90vh; overflow: scroll;">
                <Appeal v-if="blacklistActive == 'appeal'" />
                <Suspended v-if="blacklistActive == 'suspended'" />
                <Blacklist v-if="blacklistActive == 'blacklist'" />
            </div>
        </div>
    </div>
</template>

<script>
import {db} from '@/main'
import Suspended from '@/components/customerCare/suspension/suspended.vue'
import Blacklist from '@/components/customerCare/suspension/blacklist.vue'
import Appeal from '@/components/customerCare/suspension/appeal.vue'

export default {
    name: 'blacklist',
    components: {
        Appeal, Suspended, Blacklist
    },
    data(){
        return {
            blacklistActive: 'appeal',
            blacklistStatus: ['appeal', 'suspended', 'banned', 'blacklist']
        }
    },
    firestore(){
        return {
            banned: db.collection('users').where('ban.status', '==', 'banned'),
        }
    }
}
</script>