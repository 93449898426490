<template>
  <div class="navbartop py-2 px-2 bg-white shadow-sm d-flex justify-content-between">
    <div class="d-flex justify-content-between">
      <div class="headinfo">
        <div class="ml-2 avatar" style="z-index:1000" @click="$store.state.popMenu = !$store.state.popMenu"><img src="@/assets/logo.png" style="border-radius:90px" width="100%"></div>
        <div class="displayName">
          <div class="font-weight-bold">{{user.cs}}</div>
          <div class="text-small">{{user.email}}</div>
        </div>
      </div>
      <transition name="swipeup">
        <div v-if="$store.state.popMenu" class="dropdown-menu dropdown-menu-left p-0 m-2 show shadow border-top-8px" style="width:250px">
          <div class="dropdown-header dropdown-menu-lg text-center text-white border-top-8px" style="background:rgba(0,0,0,0.5)"><strong>Creativeboard</strong></div>
          <div @click="logOut()" style="cursor:pointer" role="menuitem" class="dropdown-item py-2 pr-5">
            <span class="text-danger"><font-awesome-icon icon="sign-out-alt" class="mr-3" />Logout</span>
          </div>
        </div>
      </transition>
    </div>

    <transition name='fade'>
      <div v-if="$store.state.popMenu" @click="$store.state.popMenu = false" id="blackscreen" class="min-vw-100 min-vh-100 position-fixed" style="top:0;left:0;background:rgba(0,0,0,0.7);z-index:999"></div>
    </transition>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import {db} from '@/main'

export default {
  name: 'navigation-bar',
  methods:{
    csRegex(email){
      return email.match(/^([^@]*)@/)[1]
    },
  },
  firestore(){
    return {
      user: db.collection('users').doc(firebase.auth().currentUser.uid),
    }
  }
}
</script>