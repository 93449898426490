<template>
    <div class="dashboard d-flex">
        <Navigation />
        <Sidebar />
        <StatOverlay />

        <div class="mainholder p-2" style="margin-top:56px;">
            <Dashboard v-if="$store.state.navigation == 'DASHBOARD'" />
            <Rate v-if="$store.state.navigation == 'RATE'" />
            <General v-if="$store.state.navigation == 'GENERAL'" />
            <CS v-if="$store.state.navigation == 'CS'" />
            <ServerBanks v-if="$store.state.navigation == 'SERVER BANKS'" />
            <FinancialLogs v-if="$store.state.navigation == 'FINANCIAL LOGS'" />
            <Finance v-if="$store.state.navigation == 'FINANCES'" />
            <CreativeReport v-if="$store.state.navigation == 'CREATIVE_REPORT'" />
            <CashflowReport v-if="$store.state.navigation == 'CASHFLOW_REPORT'" />
            <Usernames v-if="$store.state.navigation == 'USERNAMES'" />
        </div>
    </div>
</template>

<script>
import Navigation from '@/components/holder/Navigation'
import Sidebar from '@/components/holder/Sidebar'
import StatOverlay from '@/components/StatOverlay'

import Dashboard from './Dashboard'

import General from '@/components/settings/General'
import Rate from '@/components/settings/Rate'
import CS from '../admin/CustomerService'
import ServerBanks from './ServerBanks'
import FinancialLogs from './FinancialLogs.vue'
import Finance from './Finance.vue'
import CreativeReport from './CreativeReport.vue'
import CashflowReport from './CashflowReport.vue'
import Usernames from './Usernames.vue'

export default {
    name: 'holderboard',
    components:{
        Navigation, Sidebar, Dashboard, StatOverlay, Rate, General, CS, ServerBanks, FinancialLogs, Finance, CreativeReport, CashflowReport, Usernames
    }
}
</script>