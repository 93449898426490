const authBloc = {
    methods: {
        async getLoginIP(){
            const { ip } = await fetch('https://api.ipify.org?format=json', { method: 'GET' })
            .then(res => res.json())
            .catch(error => console.error(error));
        
            return ip ?? "Unknown";
        }
    },
}

export default authBloc