<template>
  <div>
    <div style="text-align:center; line-height:4rem;background:black;color:white;font-weight:bold">{{user.email}}</div>
    <div @click="copyToClipboard(trx.id);tickingCD(trx.id)" style="border:1px solid rgba(0,0,0,.1)" class="position-relative itemTrx d-flex p-3 mt-1" v-for="(trx, index) in even(copasAble)" :key="index" :class="{'copied': copiedRek && actId == trx.id}">
      <div class='mr-3'>
        <img v-if="trx.bank.bank == 'BCA'" src="@/assets/img/bank/bank-bca.png"  width="40px" alt="">
        <img v-if="trx.bank.bank == 'BNI'" src="@/assets/img/bank/bank-bni.png"  width="40px" alt="">
        <img v-if="trx.bank.bank == 'BRI'" src="@/assets/img/bank/bank-bri.png"  width="40px" alt="">
        <img v-if="trx.bank.bank == 'BTN'" src="@/assets/img/bank/bank-btn.png"  width="40px" alt="">
        <img v-if="trx.bank.bank == 'Mandiri'" src="@/assets/img/bank/bank-mandiri.png"  width="40px" slt="">

        <img v-if="trx.bank.bank == 'OVO'" src="@/assets/img/bank/ovo.png"  width="40px" alt="">
        <img v-if="trx.bank.bank == 'Gopay'" src="@/assets/img/bank/gopay.png"  width="40px" alt="">
        <img v-if="trx.bank.bank == 'LinkAja'" src="@/assets/img/bank/linkaja.png"  width="40px" alt="">
        <img v-if="trx.bank.bank == 'Dana'" src="@/assets/img/bank/dana.png"  width="40px" alt="">
      </div>
      <div class="d-flex" style="justify-content:space-between; width:100%">
        <div v-if="trx.bank != null" class="text-left">
          <div class="font-weight-bold">{{trx.bank.atasnama}}</div>
          <div style="font-size:14px">{{trx.bank.norek}}</div>
          <div style="font-size:14px"><span class="font-weight-bold">Provider: </span>{{trx.provider}}</div>
          <div style="font-size:14px"><span class="font-weight-bold">Nominal: </span>{{currency(trx.nominal)}}</div>
          <div style="font-size:14px"><span class="font-weight-bold">Rate: </span>{{trx.rate}}</div>
          <div style="font-size:14px"><span class="font-weight-bold">Fee: </span>{{currency(trx.fee)}}</div>
        </div>

        <div>
          <div class="font-weight-bold">{{currency(trx.nominal * trx.rate - trx.fee)}}</div>
        </div>
      </div>
      <input style="position:fixed;top:-100px" type="text" :id="trx.id" :name="trx.id" :value="trx.bank.norek">
    </div>
  </div>
</template>

<style>
.copied {
  background:#009ddc;
}
</style>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import {db} from '@/main'

export default {
  data(){
    return {
      copiedRek: false,
      actId: '',
      user: firebase.auth().currentUser
    }
  },
  methods: {
    tickingCD(id){
      this.actId = id
      this.copiedRek = true
      setTimeout(() => {
        this.copiedRek = false;
        this.setUnCopyAble(id)
      }, 2000)
    },
    even: function(arr) {
      return arr.slice().sort(function(a, b) {
        return a.created - b.created;
      });
    },
    setUnCopyAble(id){
      db.collection('converts').doc(id).update({
        'copas': false
      });
    }
  },
  computed: {
    copasAble: function() {
      return this.transaksi.filter(trx => {
        return trx.id != null && trx.status == 'pending' && trx.copas == true
        ;
      })
    }
  },
  firestore () {
    return {
      transaksi: db.collection('converts').where('version', '==', 2).where('cs', '==', firebase.auth().currentUser.email).limit(500)
    }
  }
}
</script>