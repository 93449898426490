<template> 
    <div class="min-vw-100 min-vh-100 position-fixed" style="top:0;left:0;background:rgba(0,0,0,0.7);z-index:99999">
        <div class="w-100 h-100 position-fixed" style="top:0; left:0;z-index:-1" @click="$store.state.ccare.cs_log_chat = false"></div>
            <div class="col-md-6 p-3 m-3" style='border-radius:8px; background:white'>
                <div class="chat-box" style="background-color:rgba(0,0,0,.075);margin-bottom:64px">
                <div class="list-group" id="chatitems" style="list-style:none">
                    <transition-group name="swipeup">
                    <div class="chat-item _1RAno" v-for="(chat, idx) in chats" :key="chat.key">
                    <div class="chat-status text-center" style="width:100%" v-if="chat.type==='join'||chat.type==='exit'">
                        <span class="chat-content-center">{{chat.message}}</span>
                    </div>
                    <div v-else>
                        <div class="_1RAno message-out _1ij5F _2tbXF _1zinb" v-if="chat.user === $props.trx.cs">
                        <span data-testid="tail-out" data-icon="tail-out" class="_3N37N"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 13" width="8" height="13"><path opacity=".13" d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"></path><path fill="currentColor" d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"></path></svg></span>
                        <div class="_2XJpe _7M8i6">
                            <span aria-label="You:"></span>
                            <div class="_1dB-m" :id="'cs_chat_'+idx">
                            <div class="delete-chat" @click="deleteChat(chat)"><font-awesome-icon icon="trash" color="white" /></div>
                            <span v-if="chat.type == 'newmsg'" style="white-space: pre-line" v-html="chat.message" />
                            <img v-else-if="chat.type == 'newimg'" :src="chat.attachment" width="200px" alt="" @click="zoomImage(chat.attachment)">
                            <span class="_3ysB9 _1BcQo"></span>
                            </div>
                            <div class="_1nOME">
                            <div class="_185ho" role="button">
                                <span class="_2JNr-" dir="auto">{{fbDateToString(chat.sendDate, 'HH:mm')}}</span>
                                <div class="_2v8yt">
                                <span data-testid="msg-dblcheck" aria-label=" Delivered " data-icon="msg-dblcheck" class="">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 15" width="14" height="13"><path fill="currentColor" d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"></path></svg>
                                </span>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>

                        <div class="_1RAno message-in focusable-list-item" v-if="chat.user !== $props.trx.cs">
                        <span></span>
                        <div class="message-in _1ij5F _2tbXF _1zinb"><span data-testid="tail-in" data-icon="tail-in" class="_3N37N"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 13" width="8" height="13"><path opacity=".13" fill="#0000000" d="M1.533 3.568L8 12.193V1H2.812C1.042 1 .474 2.156 1.533 3.568z"></path><path fill="currentColor" d="M1.533 2.568L8 11.193V0H2.812C1.042 0 .474 1.156 1.533 2.568z"></path></svg></span>
                        <div class="_2XJpe _7M8i6">
                            <div class="_1dB-m">
                            <div class="CWVX1 color-3 dV60t" role="">
                                <span dir="auto" class="_19038 _3cwQ7 _1VzZY">{{chat.user}}</span>
                            </div>
                            <div class="cln10 copyable-text" data-pre-plain-text="[13:37, 11/26/2020] Venti byPulsa: ">
                                <div class="_1wlJG" dir="ltr">
                                <span dir="ltr" class="_1VzZY selectable-text invisible-space copyable-text">
                                    <span v-if="chat.type == 'newmsg'">{{chat.message}}</span>
                                    <img v-else-if="chat.type == 'newimg'" :src="chat.attachment" width="200px" @click="zoomImage(chat.attachment)">
                                </span><span class="_3ysB9"></span></div>
                            </div>
                            <div class="_1nOME"><div class="_185ho"><span class="_2JNr-" dir="auto">{{fbDateToString(chat.sendDate, 'HH:mm')}}</span></div></div>
                            </div><span></span></div>
                        </div>
                        </div>
                    </div>
                    </div>
                    </transition-group>
                </div>
                </div>
                
            </div>
    </div>
</template>

<script>
import firebase from 'firebase/app'

export default {
    name: 'faq',
    data: () => {
        return {
            chats: []
        }
    },
    props: {
        trx: Object
    },
    created(){
        this.getChats(this.$props.trx)
    },
    methods: {
        parseChat(chat){
            if(chat.includes('[provider]')){
                return chat.replace('[provider]', `${this.$store.state.activeTransaction.provider}`)
            }
            if(chat.includes('[calculator]')){
                return chat.replace('[calculator]', `${this.currency(this.$store.state.activeTransaction.nominal)} x ${this.$store.state.activeTransaction.rate} - ${this.currency(this.$store.state.activeTransaction.fee)} = Rp ${this.currency(this.$store.state.activeTransaction.nominal*this.$store.state.activeTransaction.rate - this.$store.state.activeTransaction.fee)}`)
            }

            return chat
        },
        zoomImage(img){
            this.$store.state.cs.imageViewer = img
        },
        getChats(trx){
        this.chats = []
        firebase.database().ref('chatrooms/'+trx.id).once('value').then((snapshot) => {
            if(snapshot.exists){
                this.chatListener = firebase.database().ref('chatrooms/'+trx.id+'/chats');
                this.chatListener.on('value', (snapshot) => {
                    this.chats = [];
                    snapshot.forEach((doc) => {
                    let item = doc.val()
                    item.key = doc.key
                    this.chats.push(item)
                    });
                });
            }
        })
        },
        unListen(){
        if(this.chatListener != null){
            this.chatListener.off()
        }
        },
        scrollToEnd(){
            var container = document.querySelector(".chat-box");
            var scrollHeight = container.scrollHeight;
            container.scrollTop = scrollHeight + 132;
        },
    }
}
</script>