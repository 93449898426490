<template>
  <div style="padding: 8px;">
    <vue-monthly-picker
 v-model="month">
</vue-monthly-picker>
    <table class="table table-responsive-sm table-hover table-outline mb-0">
      <thead>
        <tr>
          <th rowspan="2" class="align-middle">Name</th>
          <th rowspan="2" class="align-middle">Position</th>
          <th rowspan="2" class="align-middle" style="width: 48px">Days</th>
          <th rowspan="2" class="align-middle" style="width: 48px">In</th>
          <th rowspan="2" class="align-middle" style="width:72px">Libur</th>
          <th colspan="2">Salary</th>
        </tr>
        <tr>
          <th style="width:72px">Pokok</th>
          <th>Bonus</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in teams" :key="user.id">
          <td>{{user.name}}</td>
          <td>{{user.job}}</td>
          <td>{{daysInMonth(month)}}</td>
          <td>{{getUserPresenceCount(user).ins.length}}</td>
          <td>{{daysInMonth(month) - getUserPresenceCount(user).ins.length}}</td>
          <td>{{currency(getPrimarySalary(user.job))}}</td>
          <td>{{}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from 'moment'
import VueMonthlyPicker from 'vue-monthly-picker'
import {db} from '@/main'

export default {
  name: 'presence-daily',
  components: {
    VueMonthlyPicker
  },
  data(){
    return {
      initState:true,
      month: null,
      presences: []
    }
  },
  created(){
    this.getPresences()
  },
  methods: {
    getPrimarySalary(job){
      var salary = 0

      switch (job.toUpperCase()) {
        case 'CUSTOMER SERVICE':
          salary = 1921000
          break;
      
        default:
          break;
      }

      return salary
    },
    getUserPresenceCount(user){
      var singleIn = []
      var singleOut = []
      if(user.cid != null){
        this.sortPresence(this.presences).forEach((prs) => {
          if(prs.cid == user.cid){
            if(prs.status == 'in'){
              var isInExist = false
              var newInList = prs
              
              singleIn.forEach((list) => {
                if(list.dateId == newInList.dateId) isInExist = true
              })
              if(!isInExist) singleIn.push(newInList)
            }
          }
        })
        
        this.sortByOld(this.presences).forEach((prs) => {
          if(prs.cid == user.cid){
            if(prs.status == 'out'){
              var exist = false
              var newList = prs
              
              singleOut.forEach((list) => {
                if(list.dateId == newList.dateId) exist = true
              })
              if(!exist) singleOut.push(newList)
            }
          }
        })
      }

      return {
        'ins': singleIn,
        'outs': singleOut
      }
    },
    daysInMonth(date){
      return moment(date).daysInMonth()
    },
    getPresences(){
      let m1 = moment()
      let m2 = moment()
      m1.startOf('month');
      m2.endOf('month');

      db.collection("presences")
            .orderBy("created_at")
            .where("created_at", ">", m1.toDate())
            .where("created_at", "<=", m2.toDate())
            .onSnapshot((snaps) => {
              this.presences = []
              snaps.docs.forEach(element => {
                this.presences.push(element.data())
              });

              
            })
    },
    sortPresence(arr) {
      return arr.slice().sort(function(a, b) {
        return b.created_at - a.created_at;
      });
    },
    sortByOld(arr) {
      return arr.slice().sort(function(a, b) {
        return a.created_at - b.created_at;
      });
    }
  },
  computed: {
    presenceList: function(){
      var newList = []
      this.presences.filter((prs) => {
        var exist = false
        newList.forEach((item) => {
          if(item.cid == prs.cid) exist = true
        })
        if(!exist){
          newList.push(prs)
        } else{
          var index
          newList.forEach((already) => {
            if(already.cid == prs.cid) index = newList.findIndex( (element) => element.cid === prs.cid);
          })
          newList[index] = prs
        }
      })
      return newList
    }
  },
  firestore(){
    return {
      teams: db.collection('teams')
    }
  }
}
</script>

<style>

.v-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.presence-card {
  color:white;
  width: calc((100% - 32px) / 5);
  height: calc((100vh - 32px) / 5);
}
.status-out{
  background:red;
  }
  .status-in{
  background:green;}
.presence-card:not(:nth-child(5)){
  margin-right: 8px;
}
</style>