<template>
    <div class="sidebartrx">
      <div class="settings">
        <div class="setting-item" :class="{'active' : set.route == $store.state.navigation}" v-for="(set, index) in settingList" :key="index" @click="$store.state.navigation = set.route">{{set.title}}</div>
      </div>
    </div>
</template>

<script>
export default {
  data(){
    return {
      settingList: [
        {
          title: 'Dashboard',
          route: 'DASHBOARD'
        },
        {
          title: 'General',
          route: 'GENERAL'
        },
        {
          title: 'Rate',
          route: 'RATE'
        },
        {
          title: 'Special Rate',
          route: 'SPECIAL RATE'
        },
        {
          title: 'CS',
          route: 'CS'
        },
        {
          title: 'List Server Banks',
          route: 'SERVER BANKS'
        },
        {
          title: 'Financial',
          route: 'FINANCIAL LOGS'
        }
      ]
    }
  },
  methods: {
  },
  computed: {
  },
  firestore () {
    return {
      
    }
  }
}
</script>