<template>
    <div class="position-fixed d-flex justify-content-center" style="top:0;left:0;cursor:progress;z-index:999999;background:rgba(0,0,0,.75);width:100vw;height:100vh;flex-direction:column">
        <div class="p-3 d-inline-block text-center">
        <span class="bg-white px-5 py-2" style="border-radius:4px">Loading...</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'loading-overlay'
}
</script>