<template>
  <div class="d-flex flex-column justify-content-center vh-100 text-center">
    <button class="btn btn-primary mx-4" @click="generateListNumber()">Generate Numbers</button>
    <!-- <button @click="checkGoib()">check goib</button> -->
    <!-- <button class="btn btn-primary m-auto" style="width:200px" @click="fixCS5()">CS To CS5</button> -->
  </div>
</template>

<script>
import {db} from '@/main'
import moment from 'moment'
//import numbersChecker from './data/numbers.js'
//import numbersChecker from './data/numbers2526.js'
//import logs from './data/logsTrx.js'
//import logs from './data/log24.js'
//import logs from './data/log26.js'
//import logs from './data/logs25'
//import logs from './data/logsMar'
import codes from './data/codes.js'

var numbersChecker = [
{shortcut: 'TL1268', provider: 'TELKOMSEL', value: '082338620760', nominal: 0, order: 1301, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1269', provider: 'TELKOMSEL', value: '082338620514', nominal: 0, order: 1302, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1270', provider: 'TELKOMSEL', value: '082338620508', nominal: 0, order: 1303, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1271', provider: 'TELKOMSEL', value: '082338620507', nominal: 0, order: 1304, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1272', provider: 'TELKOMSEL', value: '082338620661', nominal: 0, order: 1305, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1273', provider: 'TELKOMSEL', value: '082338620730', nominal: 0, order: 1306, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1274', provider: 'TELKOMSEL', value: '082338620776', nominal: 0, order: 1307, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1275', provider: 'TELKOMSEL', value: '082338620751', nominal: 0, order: 1308, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1276', provider: 'TELKOMSEL', value: '082338620494', nominal: 0, order: 1309, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1277', provider: 'TELKOMSEL', value: '082338620688', nominal: 0, order: 1310, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1278', provider: 'TELKOMSEL', value: '082338620667', nominal: 0, order: 1311, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1279', provider: 'TELKOMSEL', value: '082338620622', nominal: 0, order: 1312, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1280', provider: 'TELKOMSEL', value: '082338620711', nominal: 0, order: 1313, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1281', provider: 'TELKOMSEL', value: '082338620535', nominal: 0, order: 1314, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1282', provider: 'TELKOMSEL', value: '082338620534', nominal: 0, order: 1315, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1283', provider: 'TELKOMSEL', value: '082338620585', nominal: 0, order: 1316, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1284', provider: 'TELKOMSEL', value: '082338620522', nominal: 0, order: 1317, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1285', provider: 'TELKOMSEL', value: '082338620529', nominal: 0, order: 1318, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1286', provider: 'TELKOMSEL', value: '082338620517', nominal: 0, order: 1319, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1287', provider: 'TELKOMSEL', value: '082338620512', nominal: 0, order: 1320, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1288', provider: 'TELKOMSEL', value: '082338620547', nominal: 0, order: 1321, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1289', provider: 'TELKOMSEL', value: '082338620545', nominal: 0, order: 1322, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1290', provider: 'TELKOMSEL', value: '082338620542', nominal: 0, order: 1323, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1291', provider: 'TELKOMSEL', value: '082338620536', nominal: 0, order: 1324, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1292', provider: 'TELKOMSEL', value: '082338620898', nominal: 0, order: 1325, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1293', provider: 'TELKOMSEL', value: '082338620587', nominal: 0, order: 1326, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1294', provider: 'TELKOMSEL', value: '082338620813', nominal: 0, order: 1327, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1295', provider: 'TELKOMSEL', value: '082338621101', nominal: 0, order: 1328, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1296', provider: 'TELKOMSEL', value: '082338620583', nominal: 0, order: 1329, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1297', provider: 'TELKOMSEL', value: '082338620982', nominal: 0, order: 1330, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1298', provider: 'TELKOMSEL', value: '082338620965', nominal: 0, order: 1331, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1299', provider: 'TELKOMSEL', value: '082338620920', nominal: 0, order: 1332, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1300', provider: 'TELKOMSEL', value: '082338620912', nominal: 0, order: 1333, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1301', provider: 'TELKOMSEL', value: '082338620575', nominal: 0, order: 1334, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1302', provider: 'TELKOMSEL', value: '082338620720', nominal: 0, order: 1335, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1303', provider: 'TELKOMSEL', value: '082338620560', nominal: 0, order: 1336, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1304', provider: 'TELKOMSEL', value: '082338620557', nominal: 0, order: 1337, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1305', provider: 'TELKOMSEL', value: '082338620806', nominal: 0, order: 1338, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1306', provider: 'TELKOMSEL', value: '082338620485', nominal: 0, order: 1339, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1307', provider: 'TELKOMSEL', value: '082338621161', nominal: 0, order: 1340, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1308', provider: 'TELKOMSEL', value: '082338620590', nominal: 0, order: 1341, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1309', provider: 'TELKOMSEL', value: '082338621158', nominal: 0, order: 1342, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1310', provider: 'TELKOMSEL', value: '082338620831', nominal: 0, order: 1343, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1311', provider: 'TELKOMSEL', value: '082338620874', nominal: 0, order: 1344, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1312', provider: 'TELKOMSEL', value: '082338620601', nominal: 0, order: 1345, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1313', provider: 'TELKOMSEL', value: '082338620593', nominal: 0, order: 1346, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1314', provider: 'TELKOMSEL', value: '082338620591', nominal: 0, order: 1347, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1315', provider: 'TELKOMSEL', value: '082338620600', nominal: 0, order: 1348, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1316', provider: 'TELKOMSEL', value: '082338620568', nominal: 0, order: 1349, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1317', provider: 'TELKOMSEL', value: '082338620562', nominal: 0, order: 1350, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1318', provider: 'TELKOMSEL', value: '082338620650', nominal: 0, order: 1351, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1319', provider: 'TELKOMSEL', value: '082338620880', nominal: 0, order: 1352, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1320', provider: 'TELKOMSEL', value: '082338620905', nominal: 0, order: 1353, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1321', provider: 'TELKOMSEL', value: '082338620925', nominal: 0, order: 1354, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1322', provider: 'TELKOMSEL', value: '082338621110', nominal: 0, order: 1355, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1323', provider: 'TELKOMSEL', value: '082338620986', nominal: 0, order: 1356, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1324', provider: 'TELKOMSEL', value: '082338621029', nominal: 0, order: 1357, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1325', provider: 'TELKOMSEL', value: '082338621035', nominal: 0, order: 1358, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1326', provider: 'TELKOMSEL', value: '082338621051', nominal: 0, order: 1359, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1327', provider: 'TELKOMSEL', value: '082338621010', nominal: 0, order: 1360, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1328', provider: 'TELKOMSEL', value: '082338621038', nominal: 0, order: 1361, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1329', provider: 'TELKOMSEL', value: '082338621057', nominal: 0, order: 1362, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1330', provider: 'TELKOMSEL', value: '082338621060', nominal: 0, order: 1363, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1331', provider: 'TELKOMSEL', value: '082338621074', nominal: 0, order: 1364, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1332', provider: 'TELKOMSEL', value: '082338621082', nominal: 0, order: 1365, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1333', provider: 'TELKOMSEL', value: '082338621091', nominal: 0, order: 1366, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1334', provider: 'TELKOMSEL', value: '082338620603', nominal: 0, order: 1367, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1335', provider: 'TELKOMSEL', value: '082338620725', nominal: 0, order: 1368, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1336', provider: 'TELKOMSEL', value: '082338620731', nominal: 0, order: 1369, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1337', provider: 'TELKOMSEL', value: '082338620750', nominal: 0, order: 1370, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1338', provider: 'TELKOMSEL', value: '082338620740', nominal: 0, order: 1371, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1339', provider: 'TELKOMSEL', value: '082338620753', nominal: 0, order: 1372, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1340', provider: 'TELKOMSEL', value: '082338620766', nominal: 0, order: 1373, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1341', provider: 'TELKOMSEL', value: '082338620773', nominal: 0, order: 1374, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1342', provider: 'TELKOMSEL', value: '082338620793', nominal: 0, order: 1375, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1343', provider: 'TELKOMSEL', value: '082338620797', nominal: 0, order: 1376, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1344', provider: 'TELKOMSEL', value: '082338620798', nominal: 0, order: 1377, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1345', provider: 'TELKOMSEL', value: '082338620876', nominal: 0, order: 1378, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1346', provider: 'TELKOMSEL', value: '082338620915', nominal: 0, order: 1379, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1347', provider: 'TELKOMSEL', value: '082338620927', nominal: 0, order: 1380, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1348', provider: 'TELKOMSEL', value: '082338620941', nominal: 0, order: 1381, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1349', provider: 'TELKOMSEL', value: '082338620932', nominal: 0, order: 1382, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1350', provider: 'TELKOMSEL', value: '082338620969', nominal: 0, order: 1383, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1351', provider: 'TELKOMSEL', value: '082338620726', nominal: 0, order: 1384, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1352', provider: 'TELKOMSEL', value: '082338620614', nominal: 0, order: 1385, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1353', provider: 'TELKOMSEL', value: '082338620713', nominal: 0, order: 1386, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1354', provider: 'TELKOMSEL', value: '082338620611', nominal: 0, order: 1387, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1355', provider: 'TELKOMSEL', value: '082338620715', nominal: 0, order: 1388, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1356', provider: 'TELKOMSEL', value: '082338620655', nominal: 0, order: 1389, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1357', provider: 'TELKOMSEL', value: '082338620659', nominal: 0, order: 1390, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1358', provider: 'TELKOMSEL', value: '082338620653', nominal: 0, order: 1391, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1359', provider: 'TELKOMSEL', value: '082338620646', nominal: 0, order: 1392, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1360', provider: 'TELKOMSEL', value: '082338620641', nominal: 0, order: 1393, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1361', provider: 'TELKOMSEL', value: '082338620625', nominal: 0, order: 1394, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1362', provider: 'TELKOMSEL', value: '082338620658', nominal: 0, order: 1395, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1363', provider: 'TELKOMSEL', value: '082338620675', nominal: 0, order: 1396, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1364', provider: 'TELKOMSEL', value: '082338620695', nominal: 0, order: 1397, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1365', provider: 'TELKOMSEL', value: '082338620662', nominal: 0, order: 1398, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1366', provider: 'TELKOMSEL', value: '082338620660', nominal: 0, order: 1399, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1367', provider: 'TELKOMSEL', value: '082338620702', nominal: 0, order: 1400, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1368', provider: 'TELKOMSEL', value: '082338621995', nominal: 0, order: 1401, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1369', provider: 'TELKOMSEL', value: '082338621783', nominal: 0, order: 1402, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1370', provider: 'TELKOMSEL', value: '082338621789', nominal: 0, order: 1403, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1371', provider: 'TELKOMSEL', value: '082338621781', nominal: 0, order: 1404, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1372', provider: 'TELKOMSEL', value: '082338621790', nominal: 0, order: 1405, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1373', provider: 'TELKOMSEL', value: '082338621808', nominal: 0, order: 1406, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1374', provider: 'TELKOMSEL', value: '082338621678', nominal: 0, order: 1407, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1375', provider: 'TELKOMSEL', value: '082338621675', nominal: 0, order: 1408, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1376', provider: 'TELKOMSEL', value: '082338621658', nominal: 0, order: 1409, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1377', provider: 'TELKOMSEL', value: '082338621664', nominal: 0, order: 1410, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1378', provider: 'TELKOMSEL', value: '082338621681', nominal: 0, order: 1411, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1379', provider: 'TELKOMSEL', value: '082338621694', nominal: 0, order: 1412, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1380', provider: 'TELKOMSEL', value: '082338621695', nominal: 0, order: 1413, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1381', provider: 'TELKOMSEL', value: '082338621707', nominal: 0, order: 1414, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1382', provider: 'TELKOMSEL', value: '082338621719', nominal: 0, order: 1415, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1383', provider: 'TELKOMSEL', value: '082338621773', nominal: 0, order: 1416, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1384', provider: 'TELKOMSEL', value: '082338621742', nominal: 0, order: 1417, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1385', provider: 'TELKOMSEL', value: '082338621749', nominal: 0, order: 1418, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1386', provider: 'TELKOMSEL', value: '082338621626', nominal: 0, order: 1419, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1387', provider: 'TELKOMSEL', value: '082338621764', nominal: 0, order: 1420, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1388', provider: 'TELKOMSEL', value: '082338621471', nominal: 0, order: 1421, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1389', provider: 'TELKOMSEL', value: '082338621381', nominal: 0, order: 1422, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1390', provider: 'TELKOMSEL', value: '082338621353', nominal: 0, order: 1423, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1391', provider: 'TELKOMSEL', value: '082338621380', nominal: 0, order: 1424, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1392', provider: 'TELKOMSEL', value: '082338621462', nominal: 0, order: 1425, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1393', provider: 'TELKOMSEL', value: '082338621420', nominal: 0, order: 1426, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1394', provider: 'TELKOMSEL', value: '082338621437', nominal: 0, order: 1427, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1395', provider: 'TELKOMSEL', value: '082338621926', nominal: 0, order: 1428, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1396', provider: 'TELKOMSEL', value: '082338621400', nominal: 0, order: 1429, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1397', provider: 'TELKOMSEL', value: '082338621925', nominal: 0, order: 1430, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1398', provider: 'TELKOMSEL', value: '082338621859', nominal: 0, order: 1431, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1399', provider: 'TELKOMSEL', value: '082338621649', nominal: 0, order: 1432, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1400', provider: 'TELKOMSEL', value: '082338621651', nominal: 0, order: 1433, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1401', provider: 'TELKOMSEL', value: '082338621766', nominal: 0, order: 1434, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1402', provider: 'TELKOMSEL', value: '082338621769', nominal: 0, order: 1435, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1403', provider: 'TELKOMSEL', value: '082338621309', nominal: 0, order: 1436, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1404', provider: 'TELKOMSEL', value: '082338621469', nominal: 0, order: 1437, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1405', provider: 'TELKOMSEL', value: '082338621207', nominal: 0, order: 1438, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1406', provider: 'TELKOMSEL', value: '082338621198', nominal: 0, order: 1439, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1407', provider: 'TELKOMSEL', value: '082338622019', nominal: 0, order: 1440, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1408', provider: 'TELKOMSEL', value: '082338621484', nominal: 0, order: 1441, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1409', provider: 'TELKOMSEL', value: '082338621502', nominal: 0, order: 1442, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1410', provider: 'TELKOMSEL', value: '082338621596', nominal: 0, order: 1443, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1411', provider: 'TELKOMSEL', value: '082338621282', nominal: 0, order: 1444, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1412', provider: 'TELKOMSEL', value: '082338621281', nominal: 0, order: 1445, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1413', provider: 'TELKOMSEL', value: '082338621506', nominal: 0, order: 1446, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1414', provider: 'TELKOMSEL', value: '082338621227', nominal: 0, order: 1447, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1415', provider: 'TELKOMSEL', value: '082338621510', nominal: 0, order: 1448, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1416', provider: 'TELKOMSEL', value: '082338621224', nominal: 0, order: 1449, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1417', provider: 'TELKOMSEL', value: '082338621191', nominal: 0, order: 1450, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1418', provider: 'TELKOMSEL', value: '082338621183', nominal: 0, order: 1451, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1419', provider: 'TELKOMSEL', value: '082338621492', nominal: 0, order: 1452, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1420', provider: 'TELKOMSEL', value: '082338621298', nominal: 0, order: 1453, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1421', provider: 'TELKOMSEL', value: '082338621241', nominal: 0, order: 1454, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1422', provider: 'TELKOMSEL', value: '082338621261', nominal: 0, order: 1455, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1423', provider: 'TELKOMSEL', value: '082338621294', nominal: 0, order: 1456, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1424', provider: 'TELKOMSEL', value: '082338621283', nominal: 0, order: 1457, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1425', provider: 'TELKOMSEL', value: '082338621391', nominal: 0, order: 1458, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1426', provider: 'TELKOMSEL', value: '082338621560', nominal: 0, order: 1459, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1427', provider: 'TELKOMSEL', value: '082338621538', nominal: 0, order: 1460, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1428', provider: 'TELKOMSEL', value: '082338621352', nominal: 0, order: 1461, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1429', provider: 'TELKOMSEL', value: '082338621348', nominal: 0, order: 1462, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1430', provider: 'TELKOMSEL', value: '082338621307', nominal: 0, order: 1463, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1431', provider: 'TELKOMSEL', value: '082338621493', nominal: 0, order: 1464, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1432', provider: 'TELKOMSEL', value: '082338621383', nominal: 0, order: 1465, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1433', provider: 'TELKOMSEL', value: '082338621619', nominal: 0, order: 1466, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1434', provider: 'TELKOMSEL', value: '082338621612', nominal: 0, order: 1467, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1435', provider: 'TELKOMSEL', value: '082338621517', nominal: 0, order: 1468, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1436', provider: 'TELKOMSEL', value: '082338622022', nominal: 0, order: 1469, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1437', provider: 'TELKOMSEL', value: '082338621515', nominal: 0, order: 1470, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1438', provider: 'TELKOMSEL', value: '082338621559', nominal: 0, order: 1471, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1439', provider: 'TELKOMSEL', value: '082338621992', nominal: 0, order: 1472, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1440', provider: 'TELKOMSEL', value: '082338621944', nominal: 0, order: 1473, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1441', provider: 'TELKOMSEL', value: '082338621940', nominal: 0, order: 1474, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1442', provider: 'TELKOMSEL', value: '082338621993', nominal: 0, order: 1475, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1443', provider: 'TELKOMSEL', value: '082338621960', nominal: 0, order: 1476, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1444', provider: 'TELKOMSEL', value: '082338621854', nominal: 0, order: 1477, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1445', provider: 'TELKOMSEL', value: '082338621857', nominal: 0, order: 1478, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1446', provider: 'TELKOMSEL', value: '082338622017', nominal: 0, order: 1479, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1447', provider: 'TELKOMSEL', value: '082338621583', nominal: 0, order: 1480, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1448', provider: 'TELKOMSEL', value: '082338621231', nominal: 0, order: 1481, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1449', provider: 'TELKOMSEL', value: '082338621980', nominal: 0, order: 1482, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1450', provider: 'TELKOMSEL', value: '082338621972', nominal: 0, order: 1483, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1451', provider: 'TELKOMSEL', value: '082338621961', nominal: 0, order: 1484, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1452', provider: 'TELKOMSEL', value: '082338622005', nominal: 0, order: 1485, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1453', provider: 'TELKOMSEL', value: '082338621954', nominal: 0, order: 1486, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1454', provider: 'TELKOMSEL', value: '082338621847', nominal: 0, order: 1487, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1455', provider: 'TELKOMSEL', value: '082338621852', nominal: 0, order: 1488, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1456', provider: 'TELKOMSEL', value: '082338621809', nominal: 0, order: 1489, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1457', provider: 'TELKOMSEL', value: '082338621824', nominal: 0, order: 1490, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1458', provider: 'TELKOMSEL', value: '082338621946', nominal: 0, order: 1491, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1459', provider: 'TELKOMSEL', value: '082338621914', nominal: 0, order: 1492, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1460', provider: 'TELKOMSEL', value: '082338621912', nominal: 0, order: 1493, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1461', provider: 'TELKOMSEL', value: '082338621870', nominal: 0, order: 1494, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1462', provider: 'TELKOMSEL', value: '082338621886', nominal: 0, order: 1495, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1463', provider: 'TELKOMSEL', value: '082338621872', nominal: 0, order: 1496, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1464', provider: 'TELKOMSEL', value: '082338621887', nominal: 0, order: 1497, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1465', provider: 'TELKOMSEL', value: '082338621863', nominal: 0, order: 1498, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1466', provider: 'TELKOMSEL', value: '082338621911', nominal: 0, order: 1499, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1467', provider: 'TELKOMSEL', value: '082338621384', nominal: 0, order: 1500, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1468', provider: 'TELKOMSEL', value: '085233757846', nominal: 0, order: 1501, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1469', provider: 'TELKOMSEL', value: '085233757557', nominal: 0, order: 1502, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1470', provider: 'TELKOMSEL', value: '085233757806', nominal: 0, order: 1503, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1471', provider: 'TELKOMSEL', value: '085233758328', nominal: 0, order: 1504, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1472', provider: 'TELKOMSEL', value: '085233758599', nominal: 0, order: 1505, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1473', provider: 'TELKOMSEL', value: '085233757845', nominal: 0, order: 1506, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1474', provider: 'TELKOMSEL', value: '085233757552', nominal: 0, order: 1507, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1475', provider: 'TELKOMSEL', value: '085233758532', nominal: 0, order: 1508, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1476', provider: 'TELKOMSEL', value: '085233757863', nominal: 0, order: 1509, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1477', provider: 'TELKOMSEL', value: '085233758324', nominal: 0, order: 1510, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1478', provider: 'TELKOMSEL', value: '085233757585', nominal: 0, order: 1511, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1479', provider: 'TELKOMSEL', value: '085233758565', nominal: 0, order: 1512, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1480', provider: 'TELKOMSEL', value: '085233758547', nominal: 0, order: 1513, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1481', provider: 'TELKOMSEL', value: '085233757629', nominal: 0, order: 1514, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1482', provider: 'TELKOMSEL', value: '085233757776', nominal: 0, order: 1515, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1483', provider: 'TELKOMSEL', value: '085233758536', nominal: 0, order: 1516, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1484', provider: 'TELKOMSEL', value: '085233758400', nominal: 0, order: 1517, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1485', provider: 'TELKOMSEL', value: '085233757746', nominal: 0, order: 1518, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1486', provider: 'TELKOMSEL', value: '085233757907', nominal: 0, order: 1519, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1487', provider: 'TELKOMSEL', value: '085233758163', nominal: 0, order: 1520, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1488', provider: 'TELKOMSEL', value: '085233758601', nominal: 0, order: 1521, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1489', provider: 'TELKOMSEL', value: '085233757989', nominal: 0, order: 1522, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1490', provider: 'TELKOMSEL', value: '085233757792', nominal: 0, order: 1523, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1491', provider: 'TELKOMSEL', value: '085233757579', nominal: 0, order: 1524, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1492', provider: 'TELKOMSEL', value: '085233757627', nominal: 0, order: 1525, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1493', provider: 'TELKOMSEL', value: '085233758349', nominal: 0, order: 1526, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1494', provider: 'TELKOMSEL', value: '085233757872', nominal: 0, order: 1527, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1495', provider: 'TELKOMSEL', value: '085233758026', nominal: 0, order: 1528, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1496', provider: 'TELKOMSEL', value: '085233758568', nominal: 0, order: 1529, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1497', provider: 'TELKOMSEL', value: '085233758505', nominal: 0, order: 1530, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1498', provider: 'TELKOMSEL', value: '085233757983', nominal: 0, order: 1531, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1499', provider: 'TELKOMSEL', value: '085233758414', nominal: 0, order: 1532, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1500', provider: 'TELKOMSEL', value: '085233757988', nominal: 0, order: 1533, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1501', provider: 'TELKOMSEL', value: '085233758520', nominal: 0, order: 1534, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1502', provider: 'TELKOMSEL', value: '085233757978', nominal: 0, order: 1535, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1503', provider: 'TELKOMSEL', value: '085233757982', nominal: 0, order: 1536, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1504', provider: 'TELKOMSEL', value: '085233758583', nominal: 0, order: 1537, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1505', provider: 'TELKOMSEL', value: '085233758353', nominal: 0, order: 1538, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1506', provider: 'TELKOMSEL', value: '085233757665', nominal: 0, order: 1539, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1507', provider: 'TELKOMSEL', value: '085233758480', nominal: 0, order: 1540, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1508', provider: 'TELKOMSEL', value: '085233757764', nominal: 0, order: 1541, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1509', provider: 'TELKOMSEL', value: '085233757841', nominal: 0, order: 1542, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1510', provider: 'TELKOMSEL', value: '085233758304', nominal: 0, order: 1543, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1511', provider: 'TELKOMSEL', value: '085233757839', nominal: 0, order: 1544, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1512', provider: 'TELKOMSEL', value: '085233758162', nominal: 0, order: 1545, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1513', provider: 'TELKOMSEL', value: '085233757904', nominal: 0, order: 1546, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1514', provider: 'TELKOMSEL', value: '085233757823', nominal: 0, order: 1547, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1515', provider: 'TELKOMSEL', value: '085233758528', nominal: 0, order: 1548, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1516', provider: 'TELKOMSEL', value: '085233757660', nominal: 0, order: 1549, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1517', provider: 'TELKOMSEL', value: '085233757549', nominal: 0, order: 1550, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1518', provider: 'TELKOMSEL', value: '082245363971', nominal: 0, order: 1551, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1519', provider: 'TELKOMSEL', value: '082337929455', nominal: 0, order: 1552, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1520', provider: 'TELKOMSEL', value: '082245363980', nominal: 0, order: 1553, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1521', provider: 'TELKOMSEL', value: '082245159885', nominal: 0, order: 1554, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1522', provider: 'TELKOMSEL', value: '082245159836', nominal: 0, order: 1555, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1523', provider: 'TELKOMSEL', value: '082245159884', nominal: 0, order: 1556, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1524', provider: 'TELKOMSEL', value: '082245359702', nominal: 0, order: 1557, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1525', provider: 'TELKOMSEL', value: '082245159882', nominal: 0, order: 1558, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1526', provider: 'TELKOMSEL', value: '082245159832', nominal: 0, order: 1559, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1527', provider: 'TELKOMSEL', value: '082245159827', nominal: 0, order: 1560, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1528', provider: 'TELKOMSEL', value: '082245159886', nominal: 0, order: 1561, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1529', provider: 'TELKOMSEL', value: '082245363970', nominal: 0, order: 1562, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1530', provider: 'TELKOMSEL', value: '082245159906', nominal: 0, order: 1563, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1531', provider: 'TELKOMSEL', value: '082245159848', nominal: 0, order: 1564, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1532', provider: 'TELKOMSEL', value: '082245363750', nominal: 0, order: 1565, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1533', provider: 'TELKOMSEL', value: '082245159897', nominal: 0, order: 1566, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1534', provider: 'TELKOMSEL', value: '082245159883', nominal: 0, order: 1567, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1535', provider: 'TELKOMSEL', value: '082245157403', nominal: 0, order: 1568, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1536', provider: 'TELKOMSEL', value: '082245158436', nominal: 0, order: 1569, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1537', provider: 'TELKOMSEL', value: '082245159895', nominal: 0, order: 1570, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1538', provider: 'TELKOMSEL', value: '082245159847', nominal: 0, order: 1571, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1539', provider: 'TELKOMSEL', value: '082245159894', nominal: 0, order: 1572, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1540', provider: 'TELKOMSEL', value: '082245158413', nominal: 0, order: 1573, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1541', provider: 'TELKOMSEL', value: '082245159838', nominal: 0, order: 1574, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1542', provider: 'TELKOMSEL', value: '082245159892', nominal: 0, order: 1575, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1543', provider: 'TELKOMSEL', value: '082245159835', nominal: 0, order: 1576, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1544', provider: 'TELKOMSEL', value: '082245159907', nominal: 0, order: 1577, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1545', provider: 'TELKOMSEL', value: '082245159833', nominal: 0, order: 1578, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1546', provider: 'TELKOMSEL', value: '082245159846', nominal: 0, order: 1579, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1547', provider: 'TELKOMSEL', value: '082245159840', nominal: 0, order: 1580, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1548', provider: 'TELKOMSEL', value: '082245159839', nominal: 0, order: 1581, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1549', provider: 'TELKOMSEL', value: '082245159828', nominal: 0, order: 1582, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1550', provider: 'TELKOMSEL', value: '082245159908', nominal: 0, order: 1583, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1551', provider: 'TELKOMSEL', value: '082245159910', nominal: 0, order: 1584, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1552', provider: 'TELKOMSEL', value: '082245159844', nominal: 0, order: 1585, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1553', provider: 'TELKOMSEL', value: '082245159881', nominal: 0, order: 1586, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1554', provider: 'TELKOMSEL', value: '082245159861', nominal: 0, order: 1587, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1555', provider: 'TELKOMSEL', value: '082245159905', nominal: 0, order: 1588, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1556', provider: 'TELKOMSEL', value: '082245159834', nominal: 0, order: 1589, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1557', provider: 'TELKOMSEL', value: '082245363724', nominal: 0, order: 1590, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1558', provider: 'TELKOMSEL', value: '082245159903', nominal: 0, order: 1591, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1559', provider: 'TELKOMSEL', value: '082245363968', nominal: 0, order: 1592, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1560', provider: 'TELKOMSEL', value: '082245159904', nominal: 0, order: 1593, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1561', provider: 'TELKOMSEL', value: '082245157408', nominal: 0, order: 1594, identifier: 'log_18_Jun_2024'},
{shortcut: 'TL1562', provider: 'TELKOMSEL', value: '082245157405', nominal: 0, order: 1595, identifier: 'log_18_Jun_2024'},
]
var logs = []



export default({
  data(){
    return {
      //deleting logs
      totalLogs: 0,
      deletedLogs: 0,
      skipped: 0,

      codes: codes,
      logss: logs,
      savedData: 0,
      redundantLogs: 0,
      deleteLogs: 0,
      redundantData: 0,
      savedLogs: 0,
      numberChecked: numbersChecker,
      monthyear: moment().format('MMM_yyyy'),
    }
  },
  created(){
    // db.collection('cs_logs').where('identifier', '==', 'log_22_Jul_2021').where('nominal', '==', 1030000).get().then((snaos)=> {
    //   snaos.docs.forEach((snp) => {
    //     console.log(snp.data())
    //   })
    // })
  },
  methods: {
    async fixCS5(){
      const ref = await db.collection('cs_logs').where('shift', '==', 'siang').get()
      ref.docs.forEach(async (doc) => {
        await db.collection('cs_logs').doc(doc.id).update({'shift':'limasiang'})
        console.log(`updated ${doc.id}`)
      })
    },
    updateOrders(){
      var data  = 0
      db.collection('numbers').where('identifier', '==', 'log_09_Jul_2021').get().then((snaps) => {
        snaps.docs.forEach(element => {
          if(element.data().shortcut.substring(0, 2) == 'TA'){
            data ++;
            console.log(element.data().order)
/*
            db.collection('numbers').doc(element.id).update({
              order: element.data().order - 97 + 200
            })*/
          }
        });
      }).then(() => {
        console.log(`done: ${data}`)
      })
    },
    formattgl(val){
      return moment(val).format('HH:mm:ss - DD MMMM yyyy')
    },
    deleteRedundant(){
      db.collection('numbers').where('identifier', '==', 'log_29_Mar_2021').get().then((snaps) => {
        snaps.docs.forEach((data) => {
          this.totalLogs++
          db.collection('numbers').doc(data.id).delete().then(() => { this.deletedLogs++ })
        })
      })
    },
    timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },
    generateLogs(){
      this.logss.forEach(element => {
        if(element.nominal != 0){

          var ref = db.collection('cs_logs').doc();
          ref.set({
            id: ref.id,
            month: 'Apr_2021',
            identifier: element.identifier,
            nominal: element.nominal,
            nomor: element.nomor,
            shift: element.shift,
            cs: element.cs
          }).then(() => { this.savedLogs ++ })
          
        } else {
          this.redundantLogs ++
        }
      });
    },
    generateListNumber(){
      this.numberChecked.forEach(el => {
        let iseXsit = false
        this.exists.forEach((ite) => {
          if(el.shortcut == ite.shortcut) iseXsit = true
        })
        console.log(iseXsit)
        if(!iseXsit){
          var refNum = db.collection('numbers').doc();
          refNum.set({
            id: refNum.id,
            shortcut: el.shortcut,
            provider: el.provider,
            value: el.value,
            nominal: el.nominal,
            order: el.order,
            identifier: el.identifier,
            created_at: new Date(),
            updated_at: new Date()
          }).then(() => {this.savedData++; console.log(this.savedData)})
        }
      })
    },
    checkGoib(){
      var identifier = 'log_31_Mar_2022'
      this.codes.forEach((code) => {
        db.collection('numbers').where('identifier', '==', identifier).where('shortcut', '==', code).get().then((snap) => {
          if(snap.docs.length > 1){
            snap.docs.slice(1).forEach(async (snap) => {
              await db.collection('numbers').doc(snap.id).delete()
              console.log(snap.id)
            })
          }
        })
      })
      // db.collection('converts').where('nominal', '==', 130000).where('provider', '==', 'XL/AXIS').get().then((snaps) => {
      //   snaps.docs.forEach((snap) => {
      //     if(snap.data().cs == 'cs3@bypulsa.com' && snap.data().admin == 'Ridho' && snap.data().media == 'APP'){
      //       console.log(snap.data())
      //     }
      //   })
      // })
    }
  },
  firestore(){
    return {
      exists: db.collection('numbers').where('identifier', '==', 'log_03_Mar_2024')
    }
  }
})
</script>