import Vue from "vue"
import csMixin from "./cs.bloc.js"
import firstMixin from './helper.bloc'
import sellerBloc from "./seller.bloc.js"
import authBloc from "./auth.bloc.js"
import Models from "../Models.js"

Vue.mixin(sellerBloc)
Vue.mixin(csMixin)
Vue.mixin(firstMixin)
Vue.mixin(authBloc)
Vue.mixin(Models)