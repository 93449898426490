<template>
  <div class="min-vw-100 position-fixed" style="top:56px;left:0;background:rgba(0,0,0,0.7);z-index:9999; height:calc(100vh - 56px); overflow:scroll">
    <div class="w-100 position-fixed" style="top:0; left:0;z-index:-1; height:calc(100vh - 56px)" @click="$store.state.settings.quickreplay = !$store.state.settings.quickreplay"></div>
    <div class="m-3 card">
      <div class="p-3 offset-md-2 col-md-8 col-xs-12">
        <table>
          <thead>
            <tr>
              <th style="width: 144px">Short</th>
              <th>Chat</th>
              <th style="width:120px"></th>
            </tr>
          </thead>
          <tbody>
        <tr v-for="(quick, index) in templates" :key="index">
          <td>
            <input type="text" style="max-width: 144px" class="form-control" v-model="quick.short">
          </td>
          <td>
            <textarea type="text" class="form-control" v-model="quick.text" />
          </td>
          <td v-if="!editing && !deleting">
            <button class="btn btn-outline-primary mr-2" @click="editQuick(quick)"><font-awesome-icon icon="save" /></button>
            <button class="btn btn-outline-danger" @click="deleteQuick(quick.id)"><font-awesome-icon icon="trash" /></button>
          </td>
          <td v-else-if="quick.id == changed_id">
            <orbit-spinner
              :animation-duration="1200"
              :size="36"
              color="#ff1d5e"
              style="margin:auto"
            />
          </td>
          <td v-else></td>
        </tr>
        <tr><td><div class="my-4"></div></td></tr>
        <tr><td colspan="3">
          <button class="collapsible" @click="shortcode = !shortcode">Lihat Shortcode</button>
          <div :class="shortcode ? '' : 'hiddenheight'" class="collapsiblecontent">
            <table class="mt-3">
              <thead>
                <tr>
                  <th style="width:144px">Shortcode</th>
                  <th>Contoh Hasil</th>
                </tr>
              </thead>
              <tr>
                <td>[provider]</td>
                <td>TELKOMSEL</td>
              </tr>
              <tr>
                <td>[calculator]</td>
                <td>30.000 x 0.7 - 0 = Rp 21.210</td>
              </tr>
            </table>
          </div>
        </td></tr>
        <tr v-if="!saving">
          <td>
            <input type="text" style="max-width: 144px" class="form-control" v-model="newQuick.short">
          </td>
          <td>
            <textarea type="text" class="form-control" v-model="newQuick.text" />
          </td>
          <td class="text-center">
            <button class="btn btn-success" @click="saveQuick()">Simpan</button>
          </td>
        </tr>
        <tr v-else>
          <td></td>
          <td class="text-center">
            <orbit-spinner
              :animation-duration="1200"
              :size="36"
              color="#ff1d5e"
              style="margin:auto"
            />
            <div class="mt-3 font-italic text-small">Menyimpan quick chat</div>
          </td>
          <td></td>
        </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import {db} from '@/main'
import { OrbitSpinner } from 'epic-spinners'

export default {
  name: 'quick-replay',
  components: {
    OrbitSpinner
  },
  data(){
    return {
      shortcode: false,
      changed_id: null,
      saving: false,
      editing: false,
      deleting: false,
      newQuick: {
        short: '',
        text: ''
      }
    }
  },
  methods:{
    saveQuick(){
      this.saving = true
      var addQuick = db.collection('users').doc(firebase.auth().currentUser.uid).collection('templates').doc()
      this.newQuick.id = addQuick.id
      this.newQuick.created_at = new Date()
      addQuick.set(this.newQuick).then(() => {
        this.saving = false
        this.newQuick = {short: '', text: ''}
      })
    },
    deleteQuick(id){
      this.changed_id = id
      this.deleting = true
      db.collection('users').doc(firebase.auth().currentUser.uid).collection('templates').doc(id).delete().then(() => {
        this.deleting = false
        this.changed_id = null
      })
    },
    editQuick(quick){
      this.changed_id = quick.id
      this.editing = true
      db.collection('users').doc(firebase.auth().currentUser.uid).collection('templates').doc(quick.id).update({
        short: quick.short,
        text: quick.text,
        updated_at: new Date()
      }).then(() => {
        this.editing = false
        this.changed_id = null
      })
    }
  },
  firestore(){
    return {
      templates: db.collection('users').doc(firebase.auth().currentUser.uid).collection('templates')
    }
  }
}
</script>