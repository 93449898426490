<template>
    <div class="p-2">
        <table>
            <tr>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Status</th>
                <th>Schedule</th>
                <th></th>
            </tr>
            <tr v-for="(t, i) in sortir(filterd)" :key="i">
                <td>{{t.name}}</td>
                <td>{{t.phone}}</td>
                <td>{{t.email}}</td>
                <td><span class="badge badge-primary">{{t.status}}</span></td>
                <td>{{t.schedule}}</td>
                <td><span class="btn btn-primary" @click="sendChat(t)">Chat</span></td>
            </tr>
        </table>
    </div>
</template>

<script>
var team = [{'name': 'Aulia Ade Prastiwi', 'phone': '6283845046810', 'email': 'auliaadeprastiwi@gmail.com', 'status': 'opt-out'},
{'name': 'Vianta Mandhalika', 'phone': '6281234237801', 'email': 'vmandhalika@gmail.com', 'status': 'scheduled', 'schedule': '09:00 WIB - 20 Dec 2021'},
{'name': 'Haifa’ Amatulloh', 'phone': '6285736698433', 'email': 'haiamatulloh@gmail.com', 'status': 'opt-out'},
{'name': 'Lailatul Munawaroh', 'phone': '6285331370742', 'email': 'lailatulmunawaroh0852@gmail.com', 'status': 'scheduled', 'schedule': '14:10 WIB - 20 Dec 2021'},
{'name': 'Bahtiyatul Aula', 'phone': '6281555986715', 'email': 'bahtiyatulaula22@gmail.com', 'status': 'opt-out'},
{'name': 'Septa Utami', 'phone': '6285730864258', 'email': 'septyautami12@gmail.com', 'status': 'scheduled', 'schedule': '20:15 WIB - 20 Dec 2021'},
{'name': 'Endrik Nuari Wijaya', 'phone': '6281286977311', 'email': 'endrik44@gmail.com', 'status': 'opt-out'},
{'name': 'Dysi Kurnia Sari', 'phone': '6285732134254', 'email': 'dysikurniasari@gmail.com', 'status': 'scheduled', 'schedule': '12:55 WIB - 20 Dec 2021'},
{'name': 'Erisa Apriliyani', 'phone': '6285607434985', 'email': 'erisaapriliyani@gmail.com', 'status': 'scheduled', 'schedule': '19:50 WIB - 20 Dec 2021'},
{'name': 'Nufikha Fadjrin', 'phone': '6289636160914', 'email': 'nufikha96@gmail.com', 'status': 'scheduled', 'schedule': '10:15 WIB - 20 Dec 2021.'},
{'name': 'Mutia Atha Muhacita', 'phone': '628816308792', 'email': 'mutiaatha66@gmail.com', 'status': 'scheduled', 'schedule': '09:25 WIB - 20 Dec 2021'},
{'name': 'Puput Melia', 'phone': '6282132716301', 'email': 'puputmelia.3@gmail.com'},
{'name': 'Azzahra Gerdha Putri', 'phone': '6281249704334', 'email': 'azzahragerdha04@gmail.com'},
{'name': 'Putri Arum', 'phone': '6285546121512', 'email': 'putriarumzalzabilla08@gmail.com', 'status': 'scheduled', 'schedule': '13:45 WIB - 20 Dec 2021'},
{'name': 'Weni Ragil Safitri', 'phone': '6282132258849', 'email': 'weniragils@gmail.com', 'status': 'opt-out'},
{'name': 'Putri Agustina', 'phone': '6282234136108', 'email': 'pagustina300@gmail.com', 'status': 'scheduled', 'schedule': '9:25 WIB - 20 Dec 2021.'},
{'name': 'Anik Nuraini', 'phone': '6287854626831', 'email': 'anikn989@gmail.com', 'status': 'scheduled', 'schedule': '13:20 WIB - 20 Dec 2021'},
{'name': 'Hendy Helvryda', 'phone': '6281555679226', 'email': 'hendyhelvryda.666@gmail.com', 'status': 'scheduled', 'schedule': '09:50 WIB - 20 Dec 2021'},
{'name': 'Dhita Ayu Anggraeny Purwanto', 'phone': '6282338437306', 'email': 'dhitaayupurwanto@gmail.com', 'status': 'scheduled', 'schedule': '11:05 WIB - 20 Dec 2021'},
{'name': 'Salwa Firdausy Kholvi', 'phone': '923491250221', 'email': 'salwawhavie@gmail.com', 'status': 'scheduled', 'schedule': '10:40 WIB - 20 Dec 2021'},
{'name': 'Nanda Gilang Yudha Pratama', 'phone': '6282222205440', 'email': 'nandagilangyudhapratama@gmail.com'},
{'name': 'Nadia Haya Mustika', 'phone': '6282143566838', 'email': 'nadiaahaya@gmail.com', 'status': 'scheduled', 'schedule': '12:30 WIB - 20 Dec 2021'},
{'name': 'Cinta Permata Hati', 'phone': '6282139632804', 'email': 'cintaphh@gmail.com', 'status': 'scheduled', 'schedule': ' 14:35 WIB - 20 Dec 2021'},
{'name': 'AULIA NURIL KHOIRULNISA', 'phone': '6285736400844', 'email': 'aulianuril.an@gmail.com', 'status': 'opt-out'},
];
export default {
    data(){
        return {
            team: team
        }
    },
    methods: {
        sendChat(data){
            var text = `Hallo,%0aApa benar ini dengan kak ${data.name} yang mengajukan lamaran ke byPulsa?`
            var uri = `https://wa.me/${data.phone}?text=${text}`
            window.open(uri)
        },

    sortir: function(arr) {
      return arr.sort((a, b) => {
          return b.schedule - a.schedule;
      });
    },
    },
    computed: {
        filterd(){
            return this.team.filter(t => {
                return t.status != 'opt-out'
            })
        }
    }
}
</script>