<template>
    <div class="creativeboard">
        <div v-if="rawReport.length > 0" class="report mb-2 pb-3">
            <div class="mb-4">
                <div class="report-header">
                    <div class="report-title">Contributions</div>
                    <div class="report-date">{{lastMonth}}</div>
                </div>
                <div @click="setDate(cidx+1, lastMonth)" class="d-inline-block position-relative cursor-pointer" v-for="(cntr, cidx) in daysInLastMonth" :key="'last_'+cidx" v-html="contributionCount(cidx+1, lastMonth)"></div>
            </div>
            <div class="mb-4">
                <div class="report-header">
                    <div class="report-date">{{currentMonth}}</div>
                </div>
                <div @click="setDate(cidx+1, currentMonth)" class="d-inline-block position-relative cursor-pointer" v-for="(cntr, cidx) in daysInMonth" :key="'current_'+cidx" v-html="contributionCount(cidx+1, currentMonth)"></div>
            </div>
            <div class="small text-secondary">*Klik tanggal untuk melihat/memperbarui report.</div>
        </div>
        <div class="report">
            <div class="report-header">
                <div class="report-title">Daily Report</div>
                <div class="report-date">{{activeDate}}</div>
            </div>
            <draggable class="list-group" tag="ul" v-model="reports" v-bind="dragOptions" :move="onMove" @start="isDragging=true" @end="isDragging=false">
                <transition-group type="transition" :name="'flip-list'">
                <li class="list-group-item" v-for="element in reports" :key="element.id" @mouseover="hoveringItem(element.id)" @mouseout="unHoveringItem(element.id)"  :id="'report-item-'+element.id">
                    <i :class="element.fixed? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'" @click=" element.fixed=! element.fixed" aria-hidden="true"></i>
                    {{element.value}}
                    <div class="report-delete" @click="deleteItem('report', element.id)"><font-awesome-icon icon="trash" class="small" /></div>
                    <div style="position:absolute; bottom: 8px; right: 8px" class="text-small">{{element.start}} - {{element.end}}</div>
                </li>
                </transition-group>
            </draggable>
            <div class="mt-3">
                <div class="d-flex justify-content-between">
                    <div class="form-group w-100 mr-1">
                        <label class="text-small" for="start">Mulai</label>
                        <input class="form-control" type="time" id="start" name="start" v-model="newReport.start">
                    </div><div class="form-group w-100 ml-1">
                        <label class="text-small" for="end">Selesai</label>
                        <input class="form-control" type="time" id="end" name="end" v-model="newReport.end">
                    </div>
                </div>
                <textarea class="report-input" type="text" v-model="newReport.value" />
                <button @click="addItem('report')" class="btn btn-primary w-100">Simpan</button>
            </div>
        </div>
        <!-- <div class="report">
            <div class="report-header">
                <div class="report-title">To Do List</div>
            </div>
            <draggable element="span" v-model="todolist" v-bind="dragOptions" :move="onMove" @change="changeHandler">
                <transition-group name="no" class="list-group" tag="ul">
                <li class="list-group-item" v-for="element in todolist" :key="element.id">
                    <i :class="element.fixed? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'" @click=" element.fixed=! element.fixed" aria-hidden="true"></i>
                    {{element.value}}
                </li>
                </transition-group>
            </draggable>
            <div class="mt-3">
                <textarea class="report-input" type="text" v-model="newTodo.value" />
                <button @click="addItem('todo')" class="btn btn-primary w-100">Simpan</button>
            </div>
        </div> -->
    </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import JQuery from 'jquery'
let $ = JQuery
import {db} from '@/main'
import moment from 'moment'
import draggable from 'vuedraggable'

export default {
    components: {
        draggable
    },
    computed: {
        dragOptions() {
            return {
                animation: 0,
                group: "description",
                disabled: !this.editable,
                ghostClass: "ghost"
            };
        },
        reports(){
            var dateOnMoment = moment(moment(this.date).startOf('day').format())
            var endofDay = moment(moment(this.date).startOf('day').add(1, 'days').format())
            
            return this.rawReport.filter(el => {
                return dateOnMoment.unix() < el.created_at.seconds && endofDay.unix() > el.created_at.seconds
            })
        },
        activeDate(){
            return moment(this.date).format('dddd - MMMM DD, YYYY')
        }
    },
    watch: {
        isDragging(newValue) {
            if (newValue) {
                this.delayedDragging = true;
                return;
            }
            this.$nextTick(() => {
                this.delayedDragging = false;
            });
        }
    },
    data(){
        return {
            lastMonth: moment().subtract(1,'months').format('MMMM'),
            currentMonth: moment().format('MMMM YYYY'),
            daysInLastMonth: (new Date((new Date()).getFullYear(), (new Date()).getMonth(), 0).getDate()),
            daysInMonth: (new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1, 0).getDate()),
            date: new Date(),
            newReport: {
                id: '',
                created_at: null,
                value: '',
                start: '',
                end: '',
                fixed: true
            },
            newTodo: {
                id: '',
                created_at: null,
                value: '',
                start: '',
                end: '',
                fixed: true
            },
            editable: true,
            isDragging: false,
            delayedDragging: false
        }
    },
    methods: {
        formatDuration(data){
            var resp = '';
            if(data.start != null && data.end != null){
                var start = parseInt(data.start.substr(0, 2)) * 60 + parseInt(data.start.substr(4, 5));
                var end = parseInt(data.end.substr(0, 2)) * 60 + parseInt(data.end.substr(4, 5));
                var duration = end-start;
                resp =  `${duration} menit`;
            }
            return resp;
        },
        setDate(day, month){
            var monthNum = moment().month(month).format("M") - 1
            var date = new Date()
            date.setMonth(monthNum)
            date.setDate(day)
            this.date = date
        },
        orderList() {
            this.list = this.list.sort((one, two) => {
                return one.order - two.order;
            });
        },
        onMove({ relatedContext, draggedContext }) {
            const relatedElement = relatedContext.element;
            const draggedElement = draggedContext.element;
            return (
                (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
            );
        },
        async changeHandler(change){
            if(change.added != null){
                var data = {
                    created_at: change.added.element.created_at,
                    value: change.added.element.value,
                    fixed: false,
                    id: change.added.element.id
                }
                var refTodo = await db.collection('creative-teams').doc(firebase.auth().currentUser.uid).collection('to-do-list').doc(change.added.element.id)
                data.updated_at = new Date()
                refTodo.set(data)
            }
            if(change.removed != null){
                await db.collection('creative-teams').doc(firebase.auth().currentUser.uid).collection('to-do-list').doc(change.removed.element.id).delete()
            }
        },
        async addItem(type){
            switch (type) {
                case 'report':
                    if(this.newReport.value != '' && this.newReport.start != '' && this.newReport.end != ''){
                        var ref = await db.collection('creative-teams').doc(firebase.auth().currentUser.uid).collection('daily-reports').doc()


                        var start = parseInt(this.newReport.start.substr(0, 2)) * 60 + parseInt(this.newReport.start.substr(4, 5));
                        var end = parseInt(this.newReport.end.substr(0, 2)) * 60 + parseInt(this.newReport.end.substr(4, 5));
                        
                        this.newReport.duration = end-start;
                        this.newReport.id = ref.id
                        this.newReport.created_at = this.date
                        ref.set(this.newReport)
                        this.newReport = {
                            id: '',
                            created_at: null,
                            value: '',
                            start: '',
                            end: '',
                            fixed: true
                        }
                    } else {
                        alert('Mulai dan selesai harus diisi!')
                    }
                    break;
                case 'todo':
                    if(this.newTodo.value != ''){
                        var refTodo = await db.collection('creative-teams').doc(firebase.auth().currentUser.uid).collection('to-do-list').doc()
                        this.newTodo.id = refTodo.id
                        this.newTodo.created_at = this.date
                        refTodo.set(this.newTodo)
                        this.newTodo = {
                            id: '',
                            created_at: null,
                            value: '',
                            start: '',
                            end: '',
                            fixed: true
                        }
                    }
                    break;
            }
        },
        async deleteItem(type, id){
            var coll;
            switch (type) {
                case 'report':
                    coll = 'daily-reports';
                    break;
                case 'todo':
                    coll = 'to-do-list';
                    break;
            }
            await db.collection('creative-teams').doc(firebase.auth().currentUser.uid).collection(coll).doc(id).delete()
        },
        hoveringItem(idx){
            $("#report-item-"+idx+" .report-delete").addClass('opacity-5')
        },
        unHoveringItem(idx){
            $("#report-item-"+idx+" .report-delete").removeClass('opacity-5')
        },
        contributionCount(date, month){
            var timeStart = moment(date+' '+month+' '+this.date.getFullYear()).unix()
            var timeEnd = moment(date+' '+month+' '+this.date.getFullYear()).endOf('day').unix()
            var count = 0
            this.rawReport.forEach(el => {
                if(timeStart < el.created_at.seconds && timeEnd >= el.created_at.seconds){
                    count++
                }
            });
            if(count > 10){
                return `<div class="contribution-block bg-superb tooltipe"><span class="tooltiptext">${count} kontribusi</span></div><div class="position-absolute" style="top:2px;right:4px; font-size:0.4rem">${date}</div>`
            } else if(count > 5){
                return `<div class="contribution-block bg-high tooltipe"><span class="tooltiptext">${count} kontribusi</span></div><div class="position-absolute" style="top:2px;right:4px; font-size:0.4rem">${date}</div>`
            } else if(count > 2){
                return `<div class="contribution-block bg-moderate tooltipe"><span class="tooltiptext">${count} kontribusi</span></div><div class="position-absolute" style="top:2px;right:4px; font-size:0.4rem">${date}</div>`
            } else if(count > 0){
                return `<div class="contribution-block bg-low tooltipe"><span class="tooltiptext">${count} kontribusi</span></div><div class="position-absolute" style="top:2px;right:4px; font-size:0.4rem">${date}</div>`
            } else {
                return `<div class="contribution-block tooltipe"><span class="tooltiptext">${count} kontribusi</span></div><div class="position-absolute" style="top:2px;right:4px; font-size:0.4rem">${date}</div>`
            }
        }
    },
    firestore(){
        return {
            rawReport: db.collection('creative-teams').doc(firebase.auth().currentUser.uid).collection('daily-reports').orderBy('created_at', 'asc'),
            todolist: db.collection('creative-teams').doc(firebase.auth().currentUser.uid).collection('to-do-list').orderBy('created_at', 'asc')
        }
    }
}
</script>