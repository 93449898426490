import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import moment from 'moment'
//import {db} from '@/main'

export default new Vuex.Store({
  state: {
    timestamps: {
      yesterdayMidnight: moment().subtract(1,'days').startOf('day'),
      todayMidnight: moment().startOf('day'),
    },
    holder: {
      
    },
    seller: {
      tab: 'TELKOMSEL', //default value
      detailsTrx: [],
      editComplete: null,
      savingEdit: false,
      loading: false,
      listChipsDate: new Date(),
      logTime: null,
      logYesterday: null,
      numberLists: [],
      shift: '',
    },
    providers: [],
    banks: [],
    teams: [],
    cs: {
      newMessage: [],
      newTrfMode: 'TEXTAREA',
      editComplete: null,
      savingEdit: false,
      imageViewer: null,
      listNumbers: false
    },
    ccare: {
      leftbar: 'open',
      cs_log_chat: false
    },
    finance: {
      inputBankStatement: false
    },
    listNumbers: [],
    numberLists: [],
    chips: false,
    chipsfast: false,
    generatereport: false,
    navigation: 'DASHBOARD',
    csMenuDisplay: 'otw',
    settings: {
      rate: false,
      general: false,
      newtrx: false,
      quickreplay: false,
      hashtags: false,
      schedule: false,
      schedulev2: false,
      kalkulator: false,
      faq: false,
      blacklist:false
    },
    activeTransaction: {
      uid: null,
      id: null,
      trx_id: null,
      provider: null,
      nomor: null,
      nominal: null,
      fee: 0,
      rate: null,
      created:{
        seconds:null
      },
      bank: {
        norek: null,
        atasnama: null
      },
      hashtags: []
    },
    popMenu: false,
    hashtag: false,
    badge: {
      status: false,
      trx: {}
    },
    mousePosition: {
      x: 0,
      y: 0
    }
  },
  mutations: {
    changeChipsDate(){
      //state.seller.listChipsDate = payload.date
    }
  },
  modules: {
  }
})
