<template>
    <div>
        <div>
            <div class="d-inline-block btn mr-1" :class="typ == activeType ? 'btn-primary':'btn-outline-primary'" v-for="typ in types" :key="typ.id" @click="activeType = typ">{{typ}}</div>
        </div>

        <table class="table table-striped">
            <thead>
                <tr>
                <th scope="col">#</th>
                <th scope="col">value</th>
                <th scope="col">updated_at</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="usr in usernames" :key="usr.value">
                    <th scope="row"></th>
                    <td>{{usr.value}}</td>
                    <td v-if="usr.updated_at != null">{{fbDateToString(usr.updated_at.seconds*1000, 'LLL')}}</td>
                </tr>
                <tr v-if="activeType == 'blacklist'">
                    <td colspan="2"><input v-model="newBlacklist" class="form-control" type="text"></td>
                    <td><button class="btn btn-primary" @click="storeBlacklist(newBlacklist)">Blacklist</button></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import {db, fv} from '@/main'

export default {
    name:'usernames',
    data(){
        return {
            newBlacklist: '',
            activeType: 'blacklist',
            types: ['blacklist', 'cooldown']
        }
    },
    methods: {
        async storeBlacklist(value){
            var ref = db.collection('usernames').doc();
            await ref.set({
                'id': ref.id,
                'value': value,
                'type': 'blacklist',
                'updated_at': fv.serverTimestamp()
            })
            this.newBlacklist = ''
        }
    },
    computed: {
        usernames(){
            return this.lists.filter(user =>{
                return user.type == this.activeType;
            })
        }
    },
    firestore(){
        return {
            lists:db.collection('usernames')
        }
    }
}
</script>