<template>
  <div>
    <div class="d-flex justify-content-center p-2 align-items-center">
      <div class="font-weight-bold mr-3">Bulan: </div>
      <MonthPickerInput @change="selectDate"></MonthPickerInput>
    </div>
    <div v-if="loading" class="text-center mt-5 font-weight-bold">loading data...</div>
    <table v-else>
      <thead>
        <tr>
          <th>Date</th>
          <th v-for="(cs, ics) in csAble" :key="'cs_'+ics">{{cs.name}}</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(data, index) in getDaysInMonth(dataTanggal)" :key="index">
          <td>{{data}}</td>
          <td v-for="(cs, ics) in csAble" :key="'cssingle_'+ics" :class="{'bg-danger text-white': getDailySum(data, cs.name) == 0}">
            {{currency(getDailySum(data, cs.name))}}
          <td>{{currency(getDailySum(data, 'all'))}}</td>
        </tr>
        <tr>
          <td>Total</td>
          <td v-for="(cs, ics) in csAble" :key="'cssum'+ics">{{currency(getSingleCS(cs.name))}}</td>
          <td>{{currency(getSingleCS('all'))}}</td>
        </tr>
        <tr>
          <td>Average</td>
          <td v-for="(cs, ics) in csAble" :key="'csavg_'+ics">{{currency(getSingleCS(cs.name)/(getDaysInMonth(dataTanggal)-4))}}</td>
          <td>{{currency(getSingleCS('all')/currentDate)}}</td>
        </tr>
        <tr>
          <td>Bonus</td>
          <td v-for="(cs, ics) in csAble" :key="'csbonus'+ics">{{currency(getSingleCS(cs.name)/(getDaysInMonth(dataTanggal)-4)/15000000*500000)}}</td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <div class="mb-5"></div>
  </div>
</template>

<script>
import moment from 'moment'
import {db} from '@/main'
import { MonthPickerInput } from 'vue-month-picker'

export default {
  components: {
    MonthPickerInput
  },
  data(){
    return {
      loading: false,
      selectedDate: '',
      monthlyFormat: '',
      dataTanggal: {month: 1, year: 2021},
      monthlyLogs: [],
      currentDate: new Date().getDate()
    }
  },
  methods: {
    selectDate(date){
      this.selectedDate = date
      if(this.selectedDate){
        this.parseID()
      }
    },
    parseID(){
      this.loading = true
      var idf = moment(this.selectedDate.from).format('MMM_yyyy');
      this.monthlyFormat = idf;
      this.dataTanggal = {
        month: this.selectedDate.monthIndex, year: this.selectedDate.year
      }
      this.getData(idf)
    },
    async getData(month){
      this.monthlyLogs = []
      await db.collection('cs_logs').where('month', '==', month).get().then((snaps) => {
        snaps.docs.forEach(el => {
          this.monthlyLogs.push(el.data())
        });
      })

      this.loading = false
    },
    getDailySum(day, cs){
      var sum = 0
      var newDay

      if(String(day).length == 1){
        newDay = '0'+String(day)
      } else {
        newDay = day
      }

      this.monthlyLogs.forEach((data) => {
        if(cs == 'all' && data.cs != 'SELLER' && data.identifier == `log_${newDay}_${this.monthlyFormat}`){
          sum += data.nominal
        }
        if(data.cs == cs && data.identifier == `log_${newDay}_${this.monthlyFormat}`){
          sum += data.nominal
        }
      })

      return sum
    },
    getSingleCS(cs){
      var sum = 0
      this.monthlyLogs.forEach((data) => {
        if(cs == 'all' && data.cs != 'SELLER'){
          sum += data.nominal
        }
        if(data.cs == cs){
          sum += data.nominal
        }
      })

      return sum
    },
    getDaysInMonth(dataTanggal) {
      return new Date(dataTanggal.year, dataTanggal.month, 0).getDate();
    },
  },
  computed: {
    csAble: function(){
      return this.css.filter(cs => {
        return cs.job == 'Customer Service' || cs.job == 'Seller'
      })
    },
  },
  firestore(){
    return {
      css: db.collection('teams')
    }
  }
}
</script>