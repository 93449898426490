<template>
  <div class="container position-fixed w-75 card p-3" style="z-index:9999; top:0; left:0; font-size:0.85rem; margin-left:12.5%; margin-top:5%;font-size:1rem">
    <div v-if="!$store.state.cs.savingEdit" class="row">
      <div v-if="saved" class="col-md-12">
        <div class="bg-success text-white text-center py-3 card mb-3">Data berhasil dirubah</div>
      </div>
      <div class="d-flex col-md-6">
        <div class="mr-3">
          <img v-if="$store.state.cs.editComplete.provider.toUpperCase() == 'TELKOMSEL'" src="@/assets/img/provider/telkomsel-logo.png" alt="">
          <img v-else-if="$store.state.cs.editComplete.provider.toUpperCase() == 'THREE'" src="@/assets/img/provider/three-logo.png" alt="">
          <img v-else-if="$store.state.cs.editComplete.provider.toUpperCase() == 'XL'" src="@/assets/img/provider/xl-logo.png" alt="">
          <img v-else-if="$store.state.cs.editComplete.provider.toUpperCase() == 'AXIS'" src="@/assets/img/provider/axis-logo.png" alt="">
        </div>
        <div>
          <table>
            <tr>
              <td>Nomor</td>
              <td class="font-weight-bold"><input class="form-control" type="text" v-model="$store.state.cs.editComplete.nomor" :disabled="true"></td>
            </tr>
            <tr>
              <td>Nominal</td>
              <td class="font-weight-bold"><input class="form-control" type="number" v-model.number="$store.state.cs.editComplete.nominal" :disabled="$store.state.cs.savingEdit"></td>
            </tr>
            <tr>
              <td>Rate</td>
              <td class="font-weight-bold">
                <transition name="colorize"><span :key="$store.state.cs.editComplete.rate">{{$store.state.cs.editComplete.rate}}</span></transition>
              </td>
            </tr>
            <tr>
              <td>Biaya</td>
              <td class="font-weight-bold">{{currency($store.state.cs.editComplete.fee)}}</td>
            </tr>
            <tr>
              <td>Di terima</td>
              <td class="font-weight-bold">{{currency(($store.state.cs.editComplete.nominal * $store.state.cs.editComplete.rate - $store.state.cs.editComplete.fee).toFixed(0))}}</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="col-md-6">
        <div class="p-3 mb-2">
          <input v-if="$store.state.cs.editComplete.nomor_server.value.length <= 9" class="pick-server-numb" contenteditable="true" data-tab="6"
            v-model="$store.state.cs.editComplete.nomor_server.value"
            @keypress.enter="$store.state.cs.editComplete.nomor_server = predefNumber[activeNumber]"
            @keydown.up="numberDown()" 
            @keydown.down="numberUp()" 
          />
          
          <div class="d-flex justify-content-between">
            <div><span class="font-weight-bold">({{$store.state.cs.editComplete.nomor_server.shortcut}})</span> {{$store.state.cs.editComplete.nomor_server.value}}</div>
            <button class="btn btn-outline-primary" @click="$store.state.cs.editComplete.nomor_server.value = ''"><font-awesome-icon icon="edit" /></button>
          </div>
          <div v-if="$store.state.cs.editComplete.nomor_server.value.length <= 9 && $store.state.cs.editComplete.nomor_server.value.length >= 3">
            <div class="predefined-template _3SvgF" v-for="tmp in predefNumber" :key="tmp.key">
              <div class="DuUXI" :class="{'active-template' : predefNumber[activeNumber] == tmp}"><span class="font-weight-bold">({{tmp.shortcut}})</span> {{tmp.value}} <span class="font-weight-bold">({{currency(tmp.nominal)}})</span></div>
            </div>
          </div>
        </div>

        <div class="d-flex">
          <div class="mr-3">
            <img :src="bankIconURL($store.state.cs.editComplete.bank.bank)" width="120px" />
          </div>
          <div>
            <table>
              <tr>
                <td style="width:100px;">Bank</td>
                <td>
                  <select class="form-control" name="banks" id="banks" v-model="$store.state.cs.editComplete.bank.bank" @change="identifyBank()" disabled>
                    <option v-for="(bank, index) in banks" :key="index" :value="bank.name">{{bank.name}}</option>
                    <option :value="$store.state.cs.editComplete.bank.bank">{{$store.state.cs.editComplete.bank.bank}}</option>
                    <option value="lainnya">Lainnya</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>Nomor Rekening</td>
                <td class="font-weight-bold"><input class="form-control" type="text" v-model="$store.state.cs.editComplete.bank.norek" :disabled="$store.state.cs.savingEdit"></td>
              </tr>
              <tr>
                <td>Nama</td>
                <td class="font-weight-bold"><input class="form-control" type="text" v-model="$store.state.cs.editComplete.bank.atasnama" :disabled="true"></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="col-12 text-right mt-4 d-flex justify-content-between">
          <button class="btn btn-outline-danger" @click="hapusData()">Hapus</button>
        <div>
          <button class="btn btn-success mr-4" @click="simpanData()">Simpan</button>
          <button class="btn btn-outline-secondary" @click="$store.state.cs.editComplete = null">Batal</button>
        </div>
      </div>

      <div class="position-absolute text-small" style="bottom:4px; left: 25%">{{$store.state.cs.editComplete.id}}</div>
    </div>

    <div v-else class="my-5">
      <orbit-spinner
        :animation-duration="1200"
        :size="36"
        color="#ff1d5e"
        style="margin:auto"
      />
      <div class="text-center font-italic mt-4" style="font-size:12px">Menyimpan...</div>
    </div>
    
  </div>
</template>

<script>
import axios from 'axios'
import {db} from '@/main'
import {OrbitSpinner} from 'epic-spinners'

export default {
  name: 'edit-complete',
  components: {
    OrbitSpinner
  },
  data(){
    return {
      saved: false,
      activeNumber: 0
    }
  },
  methods: {
    async simpanData(){
      this.$store.state.cs.savingEdit = true

      var shift = await this.getShift()
      if(this.$store.state.cs.editComplete.shift == null){
        this.$store.state.cs.editComplete.shift = shift
      }

      var data = {
        'id': this.$store.state.cs.editComplete.id,
        'bank': this.$store.state.cs.editComplete.bank.bank,
        'atasnama': this.$store.state.cs.editComplete.bank.atasnama,
        'norek': this.$store.state.cs.editComplete.bank.norek,
        'fee': this.$store.state.cs.editComplete.fee,
        'nominal': this.$store.state.cs.editComplete.nominal,
        'nomor': this.$store.state.cs.editComplete.nomor,

        'server_shortcut': this.$store.state.cs.editComplete.nomor_server.shortcut,
        'server_value': this.$store.state.cs.editComplete.nomor_server.value,
        'server_identifier': this.$store.state.cs.editComplete.nomor_server.identifier,

        'identifier': this.$store.state.cs.editComplete.identifier,
        'provider': this.$store.state.cs.editComplete.provider,
        'rate': this.$store.state.cs.editComplete.rate,
        'shift': this.$store.state.cs.editComplete.shift,
        'admin': this.$store.state.cs.editComplete.cs
      };
      var config = {
        method: 'post',
        url: 'https://us-central1-bypulsa-convert-pulsa.cloudfunctions.net/updateCSTrx',
        headers: {
          'Content-Type': 'application/json'
        },
        data : data,
      };

      axios(config).then(() => {
        this.$store.state.cs.savingEdit = false
        this.saved = true
        setTimeout(() => {
          this.saved = false
        }, 5000)
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    async hapusData(){
      this.$store.state.cs.savingEdit = true

      var shift = await this.getShift(this.$store.state.cs.editComplete.created, this.$store.state.cs.editComplete.cs)

      var data = {
        'id': this.$store.state.cs.editComplete.id,
        'bank': this.$store.state.cs.editComplete.bank.bank,
        'atasnama': this.$store.state.cs.editComplete.bank.atasnama,
        'norek': this.$store.state.cs.editComplete.bank.norek,
        'fee': this.$store.state.cs.editComplete.fee,
        'nominal': this.$store.state.cs.editComplete.nominal,
        'nomor': this.$store.state.cs.editComplete.nomor,

        'server_shortcut': this.$store.state.cs.editComplete.nomor_server.shortcut,
        'server_value': this.$store.state.cs.editComplete.nomor_server.value,
        'server_identifier': this.$store.state.cs.editComplete.nomor_server.identifier,

        'identifier': this.$store.state.cs.editComplete.identifier,
        'provider': this.$store.state.cs.editComplete.provider,
        'rate': this.$store.state.cs.editComplete.rate,
        'shift': shift,
        'admin': this.$store.state.cs.editComplete.cs
      };
      var config = {
        method: 'post',
        url: 'https://us-central1-bypulsa-convert-pulsa.cloudfunctions.net/deleteCSTrx',
        headers: {
          'Content-Type': 'application/json'
        },
        data : data,
      };

      axios(config).then(() => {
        this.$store.state.cs.editComplete = null
        this.$store.state.cs.savingEdit = false
        this.saved = true
        setTimeout(() => {
          this.saved = false
        }, 5000)
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  },
  computed: {
    predefNumber: function(){
      return this.$store.state.numberLists.filter(tmp => {
        return this.$store.state.cs.editComplete.nomor_server.value.length >= 3 && tmp.shortcut.includes(this.$store.state.cs.editComplete.nomor_server.value.toUpperCase()) ||
               this.$store.state.cs.editComplete.nomor_server.value.length >= 3 && tmp.value.includes(this.$store.state.cs.editComplete.nomor_server.value.toUpperCase())
      })
    },
  },
  firestore () {
    return {
      banks: db.collection('const_banks'),
    }
  }
}
</script>