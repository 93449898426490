<template>
  <div class="min-vw-100 position-fixed" style="top:56px;left:0;background:rgba(0,0,0,0.7);z-index:9999; height:calc(100vh - 56px); overflow:scroll">
    <div class="w-100 position-fixed" style="top:0; left:0;z-index:-1; height:calc(100vh - 56px)" @click="$store.state.settings.hashtags = !$store.state.settings.hashtags"></div>
    <div class="m-3 card">
      <div class="p-3 offset-md-2 col-md-8 col-xs-12">
        <table>
          <thead>
            <tr>
              <th style="width: 144px">Label</th>
              <th>Background</th>
              <th>Preview</th>
              <th style="width:120px"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(tag, index) in hashtags" :key="index">
              <td>
                <input type="text" style="max-width: 144px" class="form-control" v-model="tag.label">
              </td>
              <td>
                <v-swatches
                v-model="tag.background"
                popover-x="left"
                :swatch-size="36"
                :spacing-size="0"
                shapes="circles"
                swatches="background" inline></v-swatches>
              </td>
              <td><span class="badge" :style="'background:'+tag.background+';color:'+tag.color">{{tag.label}}</span></td>
              <td v-if="!editing && !deleting">
                <button class="btn btn-outline-primary mr-2" @click="editQuick(tag)"><font-awesome-icon icon="save" /></button>
                <button class="btn btn-outline-danger" @click="deleteQuick(tag.id)"><font-awesome-icon icon="trash" /></button>
              </td>
              <td v-else-if="tag.id == changed_id">
                <orbit-spinner
                  :animation-duration="1200"
                  :size="36"
                  color="#ff1d5e"
                  style="margin:auto"
                />
              </td>
              <td v-else></td>
            </tr>
            <tr><td><div class="my-4"></div></td></tr>
            <tr v-if="!saving">
              <td>
                <input type="text" style="max-width: 144px" class="form-control" v-model="newHashtag.label">
              </td>
              <td>
                <v-swatches
                v-model="newHashtag.background"
                popover-x="left"
                swatch-size=36
                :spacing-size="0"
                shapes="circles"
                swatches="background" inline></v-swatches>
              </td>
              <td><span class="badge" :style="'background:'+newHashtag.background+';color:'+newHashtag.color">{{newHashtag.label}}</span></td>
              <td class="text-center">
                <button class="btn btn-success" @click="saveHashtag()">Simpan</button>
              </td>
            </tr>
            <tr v-else>
              <td></td>
              <td class="text-center">
                <orbit-spinner
                  :animation-duration="1200"
                  :size="36"
                  color="#ff1d5e"
                  style="margin:auto"
                />
                <div class="mt-3 font-italic text-small">Menyimpan quick chat</div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import {db} from '@/main'
import { OrbitSpinner } from 'epic-spinners'
import VSwatches from 'vue-swatches'

export default {
  name: 'hashtag-setup',
  components: {
    OrbitSpinner, VSwatches
  },
  data(){
    return {
      newHashtag: {
        background: '', color: 'white', label: ''
      },
      changed_id: null,
      saving: false,
      editing: false,
      deleting: false
    }
  },
  methods:{
    saveHashtag(){
      this.saving = true
      var addHash = db.collection('users').doc(firebase.auth().currentUser.uid).collection('hashtags').doc()
      this.newHashtag.id = addHash.id
      this.newHashtag.created_at = new Date()
      addHash.set(this.newHashtag).then(() => {
        this.saving = false
        this.newHashtag = {background: '', color: 'white', label: ''}
      })
    },
    deleteQuick(id){
      this.changed_id = id
      this.deleting = true
      db.collection('users').doc(firebase.auth().currentUser.uid).collection('hashtags').doc(id).delete().then(() => {
        this.deleting = false
        this.changed_id = null
      })
    },
    editQuick(tag){
      this.changed_id = tag.id
      this.editing = true
      db.collection('users').doc(firebase.auth().currentUser.uid).collection('hashtags').doc(tag.id).update({
        background: tag.background, color: tag.color, label: tag.label,
        updated_at: new Date()
      }).then(() => {
        this.editing = false
        this.changed_id = null
      })
    }
  },
  firestore(){
    return {
      hashtags: db.collection('users').doc(firebase.auth().currentUser.uid).collection('hashtags')
    }
  }
}
</script>