<template>
  <div>    
    <transition name="swipeup">
      <div :style="'left:'+$store.state.mousePosition.x+'px;'+'top:'+$store.state.mousePosition.y+'px;'" class="popup-label p-0 m-2 show shadow">
        <div class="dropdown-header dropdown-menu-lg text-center text-white" style="background:rgba(0,0,0,0.5)"><strong>Hashtags:</strong></div>
        <div v-for="(tag, idx) in hashtags" :key="'tag_'+idx"  :style="'background:'+tag.background+';color:'+tag.color" role="menuitem" class="cursor-pointer dropdown-item py-1 hashtag-picker d-flex" @click="addHashtag(tag)">
          <div class="mr-3">
            <font-awesome-icon size="lg" icon="check" color="white" v-if="isTagExist(trx, tag)" />
          </div>
          <div>{{tag.label}}</div>
        </div>
      </div>
    </transition>

    <transition name='fade'>
      <div @click="$store.state.hashtag = false" id="blackscreen" class="min-vw-100 min-vh-100 position-fixed" style="top:0;left:0;background:rgba(0,0,0,0.7);z-index:999"></div>
    </transition>
  </div>
</template>

<script>
import {db} from '../main'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

export default {
  name: 'Hashtag',
  props: {
    trans: Object
  },
  watch: {
    trx(val) {
      console.log(val);
    },
  },
  methods: {
    isTagExist(trx, tag){
      var i;
      if(trx.hashtags != null){
        for (i = 0; i < trx.hashtags.length; i++) {
          if (trx.hashtags[i].id == tag.id) {
              return true;
          }
        }
      }
      return false;
    },
    isHashtagExist(hashtags, tag){
      var i;
      if(hashtags != null){
        for (i = 0; i < hashtags.length; i++) {
          if (hashtags[i].id == tag.id) {
              return true;
          }
        }
      }
      return false;
    },
    async addHashtag(tag){
      var reTag = {
        color: tag.color,
        background: tag.background,
        label: tag.label,
        id: tag.id
      }
      var hashtags = []
      if(this.trx.hashtags != null){
        hashtags = this.trx.hashtags
      }
      if(this.isHashtagExist(hashtags, reTag)){
        hashtags.splice(hashtags.findIndex(v => v.label === reTag.label), 1);
      } else {
        hashtags.push(reTag)
      }
      await firebase.firestore().collection('converts').doc(this.trx.id).update({
        'hashtags': hashtags
      })
    }
  },
  firestore(){
    return {
      hashtags: db.collection('users').doc(firebase.auth().currentUser.uid).collection('hashtags'),
      trx: db.collection('converts').doc(this.$props.trans.id),
      user: db.collection('users').doc(firebase.auth().currentUser.uid),
    }
  }
}
</script>