<template>
    <div class="min-vw-100 min-vh-100 position-fixed" style="top:0;left:0;background:rgba(0,0,0,0.7);z-index:99999">
        <div class="w-100 h-100 position-fixed" style="top:0; left:0;z-index:-1" @click="$store.state.settings.schedule = !$store.state.settings.schedule"></div>
        <div class="bg-white mx-auto mt-3 p-3 row" style='border-radius: 8px; width: 95%; height: calc(100vh - 2rem); overflow: scroll;'>
            
            <div class="mb-4">
                <div class="report-header">
                    <Datepicker input-class="form-control" default-panel="month" format="MMMM yyyy" minimum-view="month" v-model="date" />
                </div>
                <div class="d-inline-block position-relative mb-3" v-for="(day, cidx) in daysName" :key="'dayName_'+cidx">
                    <div class="day-name-block" style="margin:4px">{{day}}</div>
                </div>
                <div class="d-inline-block position-relative" v-for="(cntr, cidx) in skippedDay()" :key="'skip_'+cidx">
                    <div class="skip-schedule-block" style="margin:4px"></div>
                </div>
                <div @click="checkSchedule(cntr)" class="d-inline-block position-relative cursor-pointer" v-for="(cntr, cidx) in daysInMonth" :key="'current_'+cidx">
                    <div class="position-absolute" style="top:8px;right:12px; font-size:0.75rem">{{cntr}}</div>
                    <div class="position-absolute" style="top: 24px; left: 12px">
                        <transition-group name="fade">
                            <div :class="usr.user == user.cs ? 'bg-primary text-white font-weight-bold px-3' : ''" v-for="(usr, idx) in findSchedule(cntr)" :key="'user_'+idx" style="font-size: 0.85rem">
                            {{usr.user}}
                        </div>
                        </transition-group>
                    </div>
                    <div class="schedule-block" :class="findSchedule(cntr).length >= 3 ? 'bg-full' : ''" style="margin:4px"></div>
                </div>
                <div class="text-small">* Klik pada tanggal untuk memilih libur/membatalkannya</div>
            </div>
        </div>
    </div>
</template>

<script>
import {db} from '@/main'
import moment from 'moment'
import firebase from 'firebase/app'
import 'firebase/auth'
import Datepicker from 'vuejs-datepicker';

export default {
    components: {
        Datepicker
    },
    data(){
        return {
            date: new Date(),
            currentMonth: moment().format('MMMM YYYY'),
            daysInMonth: (new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1, 0).getDate()),
            daysName: ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu']
        }
    },
    created(){
        this.startingDay()
    },
    methods:{
        skippedDay(){
            var date = this.date
            date.setDate(1)
            var day = date.getDay()
            var length = []
            for(var i = 0; i < day ; i++){
                length.push(i)
            }
            return length
        },
        startingDay(){
            var days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
            var date = this.date
            var day = days[date.getDay()]
            return day
        },
        async checkSchedule(day){
            var isExist = false
            var count = 0
            var singleDayCount = 0
            this.schedules.forEach(async (sch) => {
                if(sch.month == this.date.getMonth() && day == sch.day && sch.user == this.user.cs){
                    isExist = true
                    await this.unsetSchedule(sch.id)
                }
                if(sch.day == day && sch.month == this.date.getMonth()){
                    singleDayCount++
                }
                if(sch.user == this.user.cs && sch.month == this.date.getMonth()){
                    count++
                }
            })

            if(isExist) return;
            if(count >= 4){
                alert('maksimal libur 4x sebulan')
                return
            }
            if(singleDayCount >= 3){
                alert('maksimal sehari 3 orang aja guys')
                return
            }
            await this.setJadwal(day)
        },
        async unsetSchedule(id){
            await db.collection('schedules').doc(id).delete()
        },
        async setJadwal(day){
            var ref = db.collection('schedules').doc();
            var schedule = {
                'id': ref.id,
                'user': this.user.cs,
                'uid': this.user.uid,
                'day': day,
                'month': this.date.getMonth(),
                'year': this.date.getFullYear(),
                'created_at': new Date()
            }
            await ref.set(schedule)
        },
        findSchedule(day){
            var libur = []
            this.schedules.forEach((sch) => {
                if(day == sch.day && sch.month == this.date.getMonth() && sch.year == this.date.getFullYear()){
                    libur.push(sch)
                }
            })
            return libur
        }
    },
    firestore(){
        return {
            schedules: db.collection('schedules'),
            user: db.collection('users').doc(firebase.auth().currentUser.uid)
        }
    }
}
</script>