<template>
<div>
    <table class="table table-striped">
        <thead>
            <tr>
            <th scope="col">#</th>
            <th scope="col">Type</th>
            <th scope="col">Value</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="list in blacklists" :key="list.id">
                <th scope="row"></th>
                <td>{{list.type}}</td>
                <td>{{list.value}}</td>
            </tr>
        </tbody>
    </table>

    <div class="mt-5">
        <div v-if="newBlacklist">
            <div class="form-row">
                <div class="form-group col-md-4">
                    <label for="type">Type</label>
                    <select v-model="blacklistData.type" id="type" class="form-control">
                        <option selected disabled>Choose...</option>
                        <option value="accountNumber">Nomor Rekening/eWallet</option>
                        <option value="phoneNumber">Nomor HP</option>
                        <option value="email">Email</option>
                    </select>
                </div>
                <div class="form-group col-md-8">
                    <label for="value">Value</label>
                    <input type="text" v-model="blacklistData.value" class="form-control" id="value" placeholder="085xxxxxxx">
                </div>
            </div>
            <div class="d-flex">
                <button v-if="newBlacklist" class="btn btn-outline-secondary mt-4 py-2 w-100 mr-1" @click="newBlacklist = false">
                    <font-awesome-icon icon="arrow-left" class="mr-3" />
                    <span>Batal</span>
                </button>
                <button v-if="newBlacklist" class="btn btn-primary mt-4 py-2 w-100 ml-1" @click="storeBlacklist(blacklistData)">
                    <font-awesome-icon icon="plus-circle" class="mr-3" />
                    <span>Simpan</span>
                </button>
            </div>
        </div>
        <div v-else>
            <button class="btn btn-primary mt-4 py-2 w-100" @click="newBlacklist = true">
                <font-awesome-icon icon="plus-circle" class="mr-3" />
                <span>Blacklist</span>
            </button>
        </div>
    </div>
</div>
</template>

<script>
import {db} from '@/main'

export default {
    data(){
        return {
            newBlacklist: false,
            blacklistData:{
                'type':'',
                'value': ''
            },
        }
    },
    methods: {
        async storeBlacklist(value){
            let snap = db.collection('blacklists').doc()
            let data = value
            data.id = snap.id
            await snap.set(data)
            this.newBlacklist = false
            return
        },
    },
    firestore(){
        return {
            blacklists: db.collection('blacklists'),
        }
    }
}
</script>