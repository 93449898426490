<template>
    <div>
        <transition name='fade'>
            <div class="rightbar text-center" v-if="$store.state.cs.listNumbers">
                <div @click="$store.state.cs.listNumbers = false" class="p-1 font-weight-bold bg-white p-2 cursor-pointer">HIDE <font-awesome-icon icon="arrow-right" style="color: 'white';margin-left:16px" /></div>
                <div class="bg-primary w-100 mb-2">
                    <input v-if="nomor_server == ''" class="pick-server-numb" contenteditable="true" data-tab="6" v-model="serverNumber"/>
                    <div v-if="nomor_server == '' && serverNumber.length > 2">
                        <div class="predefined-template _3SvgF" v-for="tmp in predefNumber" :key="tmp.key">
                            <div @mouseover="setIndexOnHover(tmp)" style="cursor:pointer" class="DuUXI" :class="{'active-template' : predefNumber[activeNumber] == tmp}" @click="addToList(predefNumber[activeNumber])" >
                            <span class="font-weight-bold">({{tmp.shortcut}})</span> {{tmp.value}} <span class="font-weight-bold">({{currency(tmp.nominal)}})</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="text-small text-left py-2 px-3 bg-white d-flex justify-content-between" v-for="num in user.list_numbers" :key="num.value">
                        <div style="font-size:.65rem"><span class="mr-1 font-weight-bold">({{num.shortcut}})</span>{{num.value}} [{{currency(num.sum)}}]</div>
                        <div class="badge badge-danger px-2 cursor-pointer" @click="removeFromList(num)" style="line-height:14px">x</div>
                    </div>
                </div>
            </div>
        </transition>
        <transition name='fade'>
            <div v-if="!$store.state.cs.listNumbers" class="position-fixed bg-white p-2 cursor-pointer" @click="$store.state.cs.listNumbers = true" style="right:0; top:57px;z-index:999;box-shadow: #dadada 2px 1px 8px 0px;border-top-left-radius:8px;border-bottom-left-radius:8px">
                <div v-if="!$store.state.cs.listNumbers" class="p-1 font-weight-bold"><font-awesome-icon icon="arrow-left" style="color: 'white';margin-right:8px" /> SHOW</div>
            </div>
        </transition>
    </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import {db} from '@/main'

export default {
    data(){
        return{
            nomor_server: '',
            serverNumber: '',
            activeNumber: 0
        }
    },
    methods:{
        setIndexOnHover(data){
            this.predefNumber.forEach((def, index) => {
                if(def == data){
                    this.activeNumber = index
                }
            });
        },
        async addToList(number){
            var listNumbers = []
            var isExist = false
            if(this.user.list_numbers != null) if(this.user.list_numbers.length > 0) listNumbers = this.user.list_numbers
            listNumbers.forEach((nm) => {
                if(nm.id == number.id) isExist = true
            })
            if(!isExist){
                var addNum = number
                addNum.sum = 0
                listNumbers.push(addNum)
                var update = {
                    'list_numbers': listNumbers
                }
                db.collection('cs').doc(firebase.auth().currentUser.email.match(/^([^@]*)@/)[1]).update(update)
            } else {alert('Nomor sudah ada di List.')}
            this.nomor_server = '',
            this.serverNumber = '',
            this.activeNumber = 0
        },
        async removeFromList(number){
            var listNumbers = this.user.list_numbers
            listNumbers.forEach((nm) => {
                if(nm.id == number.id) listNumbers.splice(listNumbers.indexOf(nm), 1)
            })
            var update = {
                'list_numbers': listNumbers
            }
            db.collection('cs').doc(firebase.auth().currentUser.email.match(/^([^@]*)@/)[1]).update(update)
            
        }
    },
    computed: {
        listNumbers: function(){
            return this.user.auto_numbers
        },
        predefNumber: function(){
            return this.$store.state.numberLists.filter(tmp => {
                return this.serverNumber.length >= 3 && tmp.shortcut.includes(this.serverNumber.toUpperCase()) || this.serverNumber.length >= 3 && tmp.value.includes(this.serverNumber.toUpperCase())
            })
        },
    },
    firestore(){
        return {
            user: db.collection('cs').doc(firebase.auth().currentUser.email.match(/^([^@]*)@/)[1])
        }
    }
}
</script>